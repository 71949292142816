export const REGISTER_CUSTOMER = "REGISTER_CUSTOMER";
export const REGISTER_CUSTOMER_SUCCESS = "REGISTER_CUSTOMER_SUCCESS";
export const REGISTER_CUSTOMER_FAILURE = "REGISTER_CUSTOMER_FAILURE";
export const GET_PLANS = "GET_PLANS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_PLANS_FAILURE = "GET_PLANS_FAILURE";

export const GET_ALL_PLANS = "GET_ALL_PLANS";
export const GET_ALL_PLANS_SUCCESS = "GET_ALL_PLANS_SUCCESS";
export const GET_ALL_PLANS_FAILURE = "GET_ALL_PLANS_FAILURE";
export const GET_OPERATING_COMPANIES = "GET_OPERATING_COMPANIES";
export const GET_OPERATING_COMPANIES_SUCCESS =
  "GET_OPERATING_COMPANIES_SUCCESS";
export const GET_OPERATING_COMPANIES_FAILURE =
  "GET_OPERATING_COMPANIES_FAILURE";
export const UPDATE_SELECTED_OPERATING_COMPANY =
  "UPDATE_SELECTED_OPERATING_COMPANY";
export const UPDATE_SELECTED_OPERATING_COMPANY_SUCCESS =
  "UPDATE_SELECTED_OPERATING_COMPANY_SUCCESS";
export const UPDATE_SELECTED_OPERATING_COMPANY_FAILURE =
  "UPDATE_SELECTED_OPERATING_COMPANY_FAILURE";
export const UPDATE_SELECTED_PLAN_TYPE = "UPDATE_SELECTED_PLAN_TYPE";
export const UPDATE_SELECTED_PLAN_TYPE_SUCCESS =
  "UPDATE_SELECTED_PLAN_TYPE_SUCCESS";
export const UPDATE_SELECTED_PLAN = "UPDATE_SELECTED_PLAN";
export const JOIN_WAITING_LIST = "JOIN_WAITING_LIST";
export const JOIN_WAITING_LIST_SUCCESS = "JOIN_WAITING_LIST_SUCCESS";
export const JOIN_WAITING_LIST_FAILURE = "JOIN_WAITING_LIST_FAILURE";
export const GET_SELECTED_OPERATING_COMPANY = "GET_SELECTED_OPERATING_COMPANY";
export const CHECKED_FOR_SELECTED_OPERATING_COMPANY =
  "CHECKED_FOR_SELECTED_OPERATING_COMPANY";
export const UPDATE_LOW_INCOME = "UPDATE_LOW_INCOME";

export const CONTACT_US = "CONTACT_US";
export const CONTACT_US_SUCCESS = "CONTACT_US_SUCCESS";
export const CONTACT_US_FAILURE = "CONTACT_US_FAILURE";

export const GET_TOPICS = "GET_TOPICS";
export const GET_TOPICS_SUCCESS = "GET_TOPICS_SUCCESS";
export const GET_TOPICS_FAILURE = "GET_TOPICS_FAILURE";

export const QUICK_REGISTER = "QUICK_REGISTER";
export const QUICK_REGISTER_SUCCESS = "QUICK_REGISTER_SUCCESS";
export const QUICK_REGISTER_FAILURE = "QUICK_REGISTER_FAILURE";

export const UPDATE_START_PATH = "UPDATE_START_PATH";
export const TRACK_FORM_SUBMITTED = "TRACK_FORM_SUBMITTED";

export const GET_SOLAR_FARM_LOCATIONS_SUCCESS =
  "GET_SOLAR_FARM_LOCATIONS_SUCCESS";

export const GET_FAQ = "GET_FAQ";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const GET_OPERATING_COMPANY_BY_ZIPCODE =
  "GET_OPERATING_COMPANY_BY_ZIPCODE";
export const GET_OPERATING_COMPANY_BY_ZIPCODE_SUCCESS =
  "GET_OPERATING_COMPANY_BY_ZIPCODE_SUCCESS";
export const GET_OPERATING_COMPANY_BY_ZIPCODE_FAILURE =
  "GET_OPERATING_COMPANY_BY_ZIPCODE_FAILURE";

export const DISMISS_OTHER_REGIONS_ALERT = "DISMISS_OTHER_REGIONS_ALERT";

export const UPDATE_ACTIVE_STEP = "UPDATE_ACTIVE_STEP";

export const UPDATE_ACCOUNT_INFORMATION = "UPDATE_ACCOUNT_INFORMATION";
export const UPDATE_ACCOUNT_INFORMATION_SUCCESS =
  "UPDATE_ACCOUNT_INFORMATION_SUCCESS";
export const UPDATE_ACCOUNT_INFORMATION_FAILURE =
  "UPDATE_ACCOUNT_INFORMATION_FAILURE";

export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const UPDATE_FILE1_STATE = "UPDATE_FILE1_STATE";
export const UPDATE_FILE2_STATE = "UPDATE_FILE2_STATE";

export const REGISTER_SEPOB_CUSTOMER = "REGISTER_SEPOB_CUSTOMER";
export const REGISTER_SEPOB_CUSTOMER_SUCCESS =
  "REGISTER_SEPOB_CUSTOMER_SUCCESS";
export const REGISTER_SEPOB_CUSTOMER_FAILURE =
  "REGISTER_SEPOB_CUSTOMER_FAILURE";

export const ADD_ADDITIONAL_TAX_ID = "ADD_ADDITIONAL_TAX_ID";
