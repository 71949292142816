import "./styles.scss";

import * as React from "react";

import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component<*> {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    const { children } = this.props;
    return <div className="wrapper">{children && children}</div>;
  }
}

export default withRouter(ScrollToTop);
