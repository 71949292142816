import {
  CHECKED_FOR_SELECTED_OPERATING_COMPANY,
  GET_ALL_PLANS,
  GET_FAQ,
  GET_OPERATING_COMPANIES,
  GET_OPERATING_COMPANIES_FAILURE,
  GET_OPERATING_COMPANIES_SUCCESS,
  GET_OPERATING_COMPANY_BY_ZIPCODE,
  GET_OPERATING_COMPANY_BY_ZIPCODE_FAILURE,
  GET_PLANS,
  GET_SELECTED_OPERATING_COMPANY,
  GET_SOLAR_FARM_LOCATIONS_SUCCESS,
  UPDATE_SELECTED_OPERATING_COMPANY,
  UPDATE_SELECTED_OPERATING_COMPANY_FAILURE,
  UPDATE_SELECTED_OPERATING_COMPANY_SUCCESS
} from "./../actions/types";
import { call, put, select, takeEvery } from "redux-saga/effects";

import Cookies from "universal-cookie";
import _ from "lodash";
import { getValidationErrors } from "./../utils/formValidation";
import httpService from "./../services/http.service";

function getSolarFarmLocations(operatingCompanyId) {
  return new httpService().sendGet(
    "operating-companies/" + operatingCompanyId + "/locations"
  );
}

function getOperatingCompanies() {
  return new httpService().sendGet("operating-companies");
}

function getOperatingCompany(id) {
  return new httpService().sendGet("operating-companies/" + id);
}

function getOperatingCompanyByZipcode(zipcode) {
  return new httpService().sendGet("operating-companies/location/" + zipcode);
}

function getOperatingCompanyId() {
  const cookies = new Cookies();

  const cookie = cookies.get("operatingCompanyId");

  return Promise.resolve(cookie);
}

function* getOperatingCompaniesAsync(action) {
  try {
    const response = yield call(getOperatingCompanies);
    const operatingCompanies = _.sortBy(
      response.data,
      operatingCompany => operatingCompany.name
    );

    yield put({
      type: GET_OPERATING_COMPANIES_SUCCESS,
      payload: operatingCompanies
    });
  } catch (error) {
    yield put({
      type: GET_OPERATING_COMPANIES_FAILURE,
      payload: error
    });
  }
}

function* getSelectedOperatingCompany(action) {
  const operatingCompanyId = yield call(getOperatingCompanyId);

  try {
    if (operatingCompanyId !== undefined) {
      const response = yield call(getOperatingCompany, operatingCompanyId);
      const locationsResponse = yield call(
        getSolarFarmLocations,
        operatingCompanyId
      );

      const solarFarmLocations = locationsResponse.data;

      yield put({
        type: UPDATE_SELECTED_OPERATING_COMPANY_SUCCESS,
        payload: response.data
      });

      yield put({
        type: GET_FAQ,
        payload: operatingCompanyId
      });

      yield put({
        type: CHECKED_FOR_SELECTED_OPERATING_COMPANY,
        payload: true
      });

      yield put({
        type: GET_PLANS,
        payload: operatingCompanyId
      });

      yield put({
        type: GET_SOLAR_FARM_LOCATIONS_SUCCESS,
        payload: solarFarmLocations
      });
    } else {
      yield put({
        type: GET_OPERATING_COMPANIES
      });

      yield put({
        type: CHECKED_FOR_SELECTED_OPERATING_COMPANY,
        payload: true
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_SELECTED_OPERATING_COMPANY_FAILURE,
      payload: error
    });
  }
}

function* getOperatingCompanyByZipcodeAsync(action) {
  const { zipcode } = action.payload;

  try {
    const response = yield call(getOperatingCompanyByZipcode, zipcode);

    yield put({
      type: UPDATE_SELECTED_OPERATING_COMPANY,
      payload: {
        operatingCompany: response.data,
        persistSelection: true
      }
    });
  } catch (error) {
    const errors = error.data.code === 422 ? getValidationErrors(error) : error;

    yield put({
      type: GET_OPERATING_COMPANY_BY_ZIPCODE_FAILURE,
      payload: errors
    });
  }
}

function* updateSelectedOperatingCompany(action) {
  try {
    const state = yield select();

    const { persistSelection, operatingCompany } = action.payload;

    const checkedForSelectedOperatingCompany =
      state.operatingCompaniesReducer.checkedForSelectedOperatingCompany;

    if (persistSelection) {
      const cookies = new Cookies();

      if (operatingCompany) {
        cookies.set("operatingCompanyId", operatingCompany.id, {
          path: "/",
          maxAge: 31536000
        });
      } else {
        cookies.remove("operatingCompanyId", { path: "/" });
      }
    }

    if (!checkedForSelectedOperatingCompany) {
      yield put({
        type: CHECKED_FOR_SELECTED_OPERATING_COMPANY,
        payload: true
      });
    }

    yield put({
      type: UPDATE_SELECTED_OPERATING_COMPANY_SUCCESS,
      payload: operatingCompany
    });

    yield put({
      type: GET_PLANS,
      payload: operatingCompany.id
    });

    yield put({
      type: GET_ALL_PLANS,
      payload: operatingCompany.id
    });

    yield put({
      type: GET_FAQ,
      payload: operatingCompany.id
    });

    if (operatingCompany) {
      const locationsResponse = yield call(
        getSolarFarmLocations,
        operatingCompany.id
      );

      const solarFarmLocations = locationsResponse.data;

      yield put({
        type: GET_SOLAR_FARM_LOCATIONS_SUCCESS,
        payload: solarFarmLocations
      });
    }
  } catch (error) {
    yield put({
      type: UPDATE_SELECTED_OPERATING_COMPANY_FAILURE,
      payload: error
    });
  }
}

export function* watchGetOperatingCompaniesAsync() {
  yield takeEvery(GET_OPERATING_COMPANIES, getOperatingCompaniesAsync);
}

export function* watchUpdateSelectedOperatingCompany() {
  yield takeEvery(
    UPDATE_SELECTED_OPERATING_COMPANY,
    updateSelectedOperatingCompany
  );
}

export function* watchGetSelectedOperatingCompany() {
  yield takeEvery(GET_SELECTED_OPERATING_COMPANY, getSelectedOperatingCompany);
}

export function* watchGetOperatingCompanyByZipcodeAsync() {
  yield takeEvery(
    GET_OPERATING_COMPANY_BY_ZIPCODE,
    getOperatingCompanyByZipcodeAsync
  );
}
