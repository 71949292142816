import {
  GET_ALL_PLANS_SUCCESS,
  GET_PLANS_SUCCESS,
  UPDATE_SELECTED_PLAN,
  UPDATE_SELECTED_PLAN_TYPE_SUCCESS
} from "./../actions/types";

const initialState = {
  plans: undefined,
  allPlans: undefined,
  selectedPlanType: "residential",
  selectedPlan: undefined
};

const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS_SUCCESS: {
      return {
        ...state,
        plans: [].concat.apply([], action.payload)
      };
    }
    case GET_ALL_PLANS_SUCCESS: {
      return {
        ...state,
        allPlans: [].concat.apply([], action.payload)
      };
    }
    case UPDATE_SELECTED_PLAN_TYPE_SUCCESS: {
      return {
        ...state,
        selectedPlanType: action.payload
      };
    }
    case UPDATE_SELECTED_PLAN: {
      return {
        ...state,
        selectedPlan: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default plansReducer;
