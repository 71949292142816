function isEmailValid(email) {
  const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

  return emailRegEx.test(email);
}

function isPhoneNumberValid(phoneNumber) {
  const phoneNumberRegEx = /^\d{10}$/;

  return phoneNumberRegEx.test(phoneNumber);
}

function isValidZip(value) {
  const inputPattern = /^(?!0{3})[0-9]{3,5}$/;

  return inputPattern.test(value);
}

function isValidName(name) {
  const nameRegex = /^[a-zA-Z0-9'.\- ]*$/;

  return nameRegex.test(name);
}

const isNumeric = value => {
  const numericRegex = /^\d+$/;

  return numericRegex.test(value);
};

export { isEmailValid, isPhoneNumberValid, isValidZip, isValidName, isNumeric };
