import "./styles.scss";

import * as React from "react";
import * as segmentActions from "./../../actions/segmentActions";
import ContactUsForm from "./components/ContactUsForm";
import { Content } from "./../../components/Content/Content";
import SuccessMessage from "./components/SuccessMessage";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

class ContactUs extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    this.state = {
      isContactUsComplete: false
    };
  }

  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed("contact-us");
    }
  }

  handleSuccess = () => {
    window.scrollTo(0, 0);

    this.setState({ isContactUsComplete: true });
  };

  closeSuccessScreen = () => {
    this.setState({ isContactUsComplete: false });
  };

  render() {
    const { isContactUsComplete } = this.state;

    return (
      <Content>
        <Helmet>
          <title>Contact The Solarity Team Today | Solarity</title>
          <description>
            Our mission is to help the community run with solar power. Please
            reach out to our team today. We’re ready to answer your questions or
            take your feedback.
          </description>
        </Helmet>
        {isContactUsComplete ? (
          <SuccessMessage
            returnToForm={() => {
              this.closeSuccessScreen();
            }}
          />
        ) : (
          <div className="contact-us">
            <h2>Contact us</h2>

            <div className="contact-us__wrap">
              <div className="img">
                <img
                  src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/ContactUs-Hero-1x.jpg"
                  srcSet="https://d1oeu1h51wn2cw.cloudfront.net/desktop/ContactUs-Hero-2x.jpg 2x"
                  alt=""
                />
              </div>

              <ContactUsForm onSuccess={() => this.handleSuccess()} />
            </div>
          </div>
        )}
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

export default connect(undefined, mapDispatchToProps)(ContactUs);
