import "./App.scss";

import * as React from "react";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AboutUs from "./containers/AboutUs/AboutUs";
import ContactUs from "./containers/ContactUs/ContactUs";
import EbcSignUp from "./containers/EbcSignUp/EbcSignUp";
import FAQ from "./containers/FAQ/FAQ";
import { Helmet } from "react-helmet";
import Home from "./containers/Home/Home";
import JoinWaitingList from "./containers/JoinWaitingList/JoinWaitingList";
import NotFound from "./containers/NotFound";
import OurFarms from "./containers/OurFarms/OurFarms";
import PlanSelector from "./containers/PlanSelector/PlanSelector";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { Provider } from "react-redux";
import Registration from "./containers/Registration/Registration";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import SepoBGetStarted from "./containers/SepoB-GetStarted/SepoBGetStarted";
import TermsAndPolicy from "./containers/TermsAndPolicy/TermsAndPolicy";
import WhyGreenSky from "./containers/WhyGreenSky/WhyGreenSky";
import store from "./store";

class App extends React.PureComponent {
  componentWillMount() {
    var ua = window.navigator.userAgent;

    var trident = ua.indexOf("Trident/");
    if (trident > 0) {
      document.querySelector("body").className += " ie11";
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router basename={process.env.PUBLIC_URL}>
          <ScrollToTop>
            <div id="App">
              <Helmet>
                <title>Community Solar by Entergy | Solarity</title>
                <description>
                  Entergy is on a mission to help you run with solar power
                  through community solar.{" "}
                </description>
              </Helmet>
              <Switch>
                <Route exact path="/ebc-sign-up" component={EbcSignUp} />
                <Route exact path="/get-started" component={SepoBGetStarted} />
                <Route
                  exact
                  path="/about-us"
                  component={PrivateRoute(AboutUs)}
                />
                <Route exact path="/" component={PrivateRoute(Home)} />
                <Route exact path="/sepo-b" component={PrivateRoute(Home)} />
                <Route
                  exact
                  path="/signup"
                  component={PrivateRoute(Registration)}
                />
                <Route
                  exact
                  path="/plans/:opCo?"
                  component={PrivateRoute(PlanSelector)}
                />

                <Route
                  exact
                  path="/join-waitlist"
                  component={PrivateRoute(JoinWaitingList)}
                />
                <Route exact path="/faq" component={PrivateRoute(FAQ)} />
                <Route
                  exact
                  path="/our-farms"
                  component={PrivateRoute(OurFarms)}
                />
                <Route
                  exact
                  path="/why-solarity"
                  component={PrivateRoute(WhyGreenSky)}
                />
                <Route
                  exact
                  path="/contact-us"
                  component={PrivateRoute(ContactUs)}
                />
                <Route
                  exact
                  path="/terms-and-privacy"
                  component={PrivateRoute(TermsAndPolicy)}
                />
                <Route component={NotFound} />
              </Switch>
            </div>
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}

export default App;
