import "./styles.scss";

import * as React from "react";
import * as operatingCompaniesActions from "./../../actions/operatingCompaniesActions";
import * as segmentActions from "./../../actions/segmentActions";

import EbcSignUpForm from "./components/EbcSignUpForm";
import { NavHashLink as Link } from "react-router-hash-link";
import { connect } from "react-redux";
import genericLogoSmall from "./../../img/logos/generic-white-small.svg";

class EbcSignUp extends React.PureComponent<*> {
  componentDidMount() {
    const {
      trackPageViewed,
      getOperatingCompanies,
      operatingCompanies
    } = this.props;

    if (!operatingCompanies || operatingCompanies.length === 0) {
      getOperatingCompanies();
    }

    if (trackPageViewed) {
      trackPageViewed("ebc-sign-up");
    }
  }

  render() {
    const initialValues = {
      lowIncomeAgreement: false,
      isWaitlisted: false
    };

    return (
      <div className="ebc-signup">
        <header className="ebc-signup__header">
          <div className="ebc-signup__header-title">
            <div>Add Customer</div>
          </div>
          <div className="ebc-signup__header-logo">
            <Link to={`${process.env.PUBLIC_URL}`}>
              <img src={genericLogoSmall} alt="" />
            </Link>
          </div>
        </header>

        <div className="ebc-signup__content">
          <EbcSignUpForm initialValues={initialValues} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName)),
  getOperatingCompanies: () =>
    dispatch(operatingCompaniesActions.getOperatingCompanies())
});

const mapStateToProps = state => ({
  operatingCompanies: state.operatingCompaniesReducer.operatingCompanies
});

export default connect(mapStateToProps, mapDispatchToProps)(EbcSignUp);
