import * as React from "react";

import { connect } from "react-redux";

class LowIncomeCheckboxes extends React.PureComponent<*> {
  render() {
    const { changeCheckboxValue, selectedOperatingCompany } = this.props;
    return (
      <div>
        <h3>Certification of Eligibility for Low-Income Benefits Adder</h3>
        <p className="paragraph">
          To qualify for the Low-Income Benefits Adder, I state under oath and
          under penalty of perjury that the following is true and accurate:
        </p>

        <div className="gs-form-checkbox-wrap">
          <input
            id="incomeLessThanMinimum"
            name="incomeLessThanMinimum"
            className="gs-form-checkbox"
            type="checkbox"
            onChange={e => changeCheckboxValue(e, "isIncomeLessThanMinimum")}
          />
          <label htmlFor="incomeLessThanMinimum">
            My total annual combined gross household income does not exceed
            $23,760.30.
          </label>
        </div>

        <div className="gs-form-checkbox-wrap">
          <input
            id="isPrimaryAccountHolder"
            className="gs-form-checkbox"
            type="checkbox"
            onChange={e => changeCheckboxValue(e, "isPrimaryAccountHolder")}
          />
          <label htmlFor="isPrimaryAccountHolder">
            The electric service account at my primary residence is in my name.
          </label>
        </div>

        <div className="gs-form-checkbox-wrap">
          <input
            id="lowIncomeBenefitsAddrForPrimaryResidenceOnly"
            className="gs-form-checkbox"
            type="checkbox"
            onChange={e =>
              changeCheckboxValue(
                e,
                "lowIncomeBenefitsAddrForPrimaryResidenceOnly"
              )
            }
          />
          <label htmlFor="lowIncomeBenefitsAddrForPrimaryResidenceOnly">
            I have not and will not request the Low-Income Benefits Adder for
            any electric service account other than my primary residence.
          </label>
        </div>

        <div className="gs-form-checkbox-wrap">
          <input
            id="confirm"
            className="gs-form-checkbox"
            type="checkbox"
            onChange={e => changeCheckboxValue(e, "confirm")}
          />
          <label htmlFor="confirm">
            I understand that the information provided in this certification is
            subject to audit and verification as deemed necessary by{" "}
            {selectedOperatingCompany.name}, and failure to comply with any
            request for additional information in conjunction with such audit
            and verification can result in disqualification of eligibility for
            the Low-Income Benefits Adder.
          </label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany
});

export default connect(mapStateToProps, undefined)(LowIncomeCheckboxes);
