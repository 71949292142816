import {
  REGISTER_SEPOB_CUSTOMER,
  REGISTER_SEPOB_CUSTOMER_FAILURE,
  REGISTER_SEPOB_CUSTOMER_SUCCESS,
  UPDATE_FILE1_STATE,
  UPDATE_FILE2_STATE,
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS
} from "./../actions/types";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { getValidationErrors } from "./../utils/formValidation";
import httpService from "./../services/http.service";

// function uploadFile(file, emailAddress) {
//   const formData = new FormData();
//   formData.append("file", file);

//   return new httpService().uploadFile("upload", file, emailAddress);
// }

function registerSepoBCustomer(payload) {
  return new httpService().sendPost("customers-sepob", payload);
}

function* uploadFileAsync(action) {
  try {
    const payload = action.payload;

    const { index } = payload;

    // const state = yield select();

    // const customer = state.sepoBReducer.customer;

    if (index === 0) {
      yield put({
        type: UPDATE_FILE1_STATE,
        payload: true
      });
    } else if (index === 1) {
      yield put({
        type: UPDATE_FILE2_STATE,
        payload: true
      });
    }

    // yield call(uploadFile, file, customer.emailAddress);

    yield put({
      type: UPLOAD_FILE_SUCCESS,
      payload
    });
  } catch (error) {
    const errors = error.data.code === 422 ? getValidationErrors(error) : error;

    yield put({
      type: UPLOAD_FILE_FAILURE,
      payload: errors
    });
  }
}

function* registerSepoBCustomerAsync(action) {
  try {
    const state = yield select();
    const customerPayload = action.payload;
    const selectedOperatingCompany =
      state.operatingCompaniesReducer.selectedOperatingCompany;

    const operatingCompanyId = selectedOperatingCompany.id;

    let customer = {};

    Object.keys(customerPayload).forEach(key => {
      if (key.indexOf("taxIdNumber") < 0) {
        customer[key] = customerPayload[key];
      } else {
        if (!customer.taxIdNumbers) {
          customer.taxIdNumbers = [];
        }

        customer.taxIdNumbers.push(customerPayload[key]);
      }
    });

    const payload = {
      ...customer,
      operatingCompanyId
    };

    const response = yield call(registerSepoBCustomer, payload);

    yield put({
      type: REGISTER_SEPOB_CUSTOMER_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    const errors = error.data.code === 422 ? getValidationErrors(error) : error;

    yield put({
      type: REGISTER_SEPOB_CUSTOMER_FAILURE,
      payload: errors
    });
  }
}

export function* watchRegisterSepoBCustomerAsync() {
  yield takeEvery(REGISTER_SEPOB_CUSTOMER, registerSepoBCustomerAsync);
}

export function* watchUploadFileAsync() {
  yield takeEvery(UPLOAD_FILE, uploadFileAsync);
}
