import * as React from "react";
import * as registrationActions from "./../../../actions/registrationActions";
import LowIncomeCheckboxes from "./../../../components/LowIncomeCheckboxes/LowIncomeCheckboxes";

import { connect } from "react-redux";

class LowIncomeForm extends React.PureComponent<*> {
  constructor(props) {
    super(props);

    this.state = {
      isIncomeLessThanMinimum: false,
      isPrimaryAccountHolder: false,
      lowIncomeBenefitsAddrForPrimaryResidenceOnly: false,
      confirm: false
    };
  }

  handleOnClose = () => {
    const { updateLowIncome } = this.props;
    const {
      isIncomeLessThanMinimum,
      isPrimaryAccountHolder,
      lowIncomeBenefitsAddrForPrimaryResidenceOnly,
      confirm
    } = this.state;

    const isLowIncome =
      isIncomeLessThanMinimum &&
      isPrimaryAccountHolder &&
      lowIncomeBenefitsAddrForPrimaryResidenceOnly &&
      confirm;

    updateLowIncome(isLowIncome);
  };

  confirmCondition = (e, type) => {
    const curState = e.nativeEvent.target.checked;

    this.setState(state => {
      return {
        [type]: (state[type] = curState)
      };
    });

    setTimeout(() => {
      this.checkAgreement();
    }, 50);
  };
  checkAgreement = () => {
    const {
      isIncomeLessThanMinimum,
      isPrimaryAccountHolder,
      lowIncomeBenefitsAddrForPrimaryResidenceOnly,
      confirm
    } = this.state;

    const buttonEnabled =
      isIncomeLessThanMinimum &&
      isPrimaryAccountHolder &&
      lowIncomeBenefitsAddrForPrimaryResidenceOnly &&
      confirm;

    this.props.onSuccess(buttonEnabled);
  };

  render() {
    return (
      <div className="low-income-chooser">
        <LowIncomeCheckboxes changeCheckboxValue={this.confirmCondition} />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateLowIncome: isLowIncome =>
    dispatch(registrationActions.updateLowIncome(isLowIncome))
});

export default connect(undefined, mapDispatchToProps)(LowIncomeForm);
