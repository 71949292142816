import * as React from "react";

class SuccessMessage extends React.PureComponent<*> {
  render() {
    const { returnToForm } = this.props;

    return (
      <div className="contact-us-success">
        <div className="contact-us-success__text">
          <h2>Thank you for submitting your question</h2>
          <p>We will reach out to you as soon as we can.</p>
        </div>

        <button className="gs-form-btn" type="button" onClick={returnToForm}>
          Return to Contact Us
        </button>
      </div>
    );
  }
}

export default SuccessMessage;
