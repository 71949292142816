/* eslint-disable react/prop-types */
import * as React from "react";
import Select from "react-select";

const renderTextField = field => (
  <fieldset className="gs-form-fieldset">
    <label className="gs-form-label">{field.label}</label>
    {field.meta.touched && field.meta.error && (
      <span className="gs-form-error">{field.meta.error}</span>
    )}
    <input
      id={field.input.name}
      className="gs-form-input"
      {...field.input}
      placeholder={field.placeholder}
      type={field.type}
      maxLength={field.maxLength}
    />
  </fieldset>
);

const renderRadioButton = field => (
  <div className="custom-radio">
    <input
      {...field.input}
      id={field.label}
      disabled={field.disabled}
      type={field.type}
    />
    <label htmlFor={field.label}>{field.label}</label>
  </div>
);

const renderSelectField = (
  label,
  meta = {},
  options,
  currentValue,
  autosize,
  onChange,
  isOptionDisabled
) => {
  return (
    <fieldset className="gs-form-fieldset">
      <label>{label}</label>

      {meta.touched && meta.error && (
        <span className="main-form__error gs-form-error">{meta.error}</span>
      )}
      <Select
        className="custom-select"
        classNamePrefix="select"
        options={options}
        defaultValue={currentValue}
        autosize={autosize}
        onChange={onChange}
        isOptionDisabled={isOptionDisabled}
      />
    </fieldset>
  );
};

const renderTextAreaField = ({
  meta: { touched, error } = { touched: false },
  maxLength,
  label,
  placeholder,
  input
}) => (
  <fieldset className="gs-form-fieldset">
    <label>{label}</label>
    {touched && error && (
      <span className="main-form__error gs-form-error">{error}</span>
    )}

    <textarea
      className="main-form__textarea"
      maxLength={maxLength}
      placeholder={placeholder}
      {...input}
    />
  </fieldset>
);

export {
  renderTextField,
  renderRadioButton,
  renderTextAreaField,
  renderSelectField
};
