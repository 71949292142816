import { UPDATE_START_PATH } from "./../actions/types";

const initialState = {
  startPath: undefined
};

const segmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_START_PATH: {
      return {
        ...state,
        startPath: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default segmentReducer;
