import {
  GET_SELECTED_OPERATING_COMPANY,
  JOIN_WAITING_LIST,
  JOIN_WAITING_LIST_FAILURE,
  JOIN_WAITING_LIST_SUCCESS,
  QUICK_REGISTER,
  QUICK_REGISTER_FAILURE,
  QUICK_REGISTER_SUCCESS,
  REGISTER_CUSTOMER,
  REGISTER_CUSTOMER_FAILURE,
  REGISTER_CUSTOMER_SUCCESS
} from "./../actions/types";
import { call, put, select, takeEvery } from "redux-saga/effects";

import { getValidationErrors } from "./../utils/formValidation";
import httpService from "./../services/http.service";

function registerCustomer(payload) {
  return new httpService().sendPost("customers", payload);
}

function* registerCustomerAsync(action) {
  try {
    const state = yield select();
    const selectedOperatingCompany =
      state.operatingCompaniesReducer.selectedOperatingCompany;

    const isLowIncome = state.registrationReducer.isLowIncome;

    const selectedPlan = state.plansReducer.selectedPlan;
    const selectedPlanType = state.plansReducer.selectedPlanType;
    const solarPlanId = selectedPlan.id;
    const operatingCompanyId = selectedOperatingCompany.id;
    const isCommercial = selectedPlanType && selectedPlanType === "commercial";

    const payload = {
      ...action.payload,
      solarPlanId,
      isWaitlisted: false,
      operatingCompanyId,
      isLowIncome,
      isCommercial
    };

    const response = yield call(registerCustomer, payload);

    yield put({
      type: REGISTER_CUSTOMER_SUCCESS,
      payload: response.data
    });

    yield put({
      type: GET_SELECTED_OPERATING_COMPANY
    });
  } catch (error) {
    const errors = error.data.code === 422 ? getValidationErrors(error) : error;

    yield put({
      type: REGISTER_CUSTOMER_FAILURE,
      payload: errors
    });
  }
}

function* joinWaitingAsync(action) {
  try {
    const state = yield select();
    const selectedOperatingCompany =
      state.operatingCompaniesReducer.selectedOperatingCompany || {};
    const operatingCompanyId = selectedOperatingCompany.id;
    const isCommercial = action.payload.customerType === "commercial";

    const payload = {
      ...action.payload,
      isWaitlisted: true,
      operatingCompanyId,
      isCommercial
    };

    const response = yield call(registerCustomer, payload);

    yield put({
      type: JOIN_WAITING_LIST_SUCCESS,
      payload: response.data
    });

    yield put({
      type: GET_SELECTED_OPERATING_COMPANY
    });
  } catch (error) {
    const errors = error.data.code === 422 ? getValidationErrors(error) : error;

    yield put({
      type: JOIN_WAITING_LIST_FAILURE,
      payload: errors
    });
  }
}

function* quickRegisterAsync(action) {
  try {
    const {
      firstName,
      lastName,
      address,
      emailAddress,
      isWaitlisted,
      zipcode,
      phoneNumber,
      operatingCompanyId,
      selectedPlanType,
      solarPlanId,
      entergyAccountNumber,
      companyName,
      notes
    } = action.payload;

    let payload = {
      firstName,
      lastName,
      address,
      emailAddress,
      isWaitlisted,
      zipcode,
      phoneNumber,
      operatingCompanyId
    };

    if (isWaitlisted === false) {
      payload.solarPlanId = solarPlanId;

      if (selectedPlanType === "commercial") {
        payload.isCommercial = true;
        payload.isLowIncome = false;
        payload.companyName = companyName;
      } else if (selectedPlanType === "lowIncome") {
        payload.isLowIncome = true;
      } else {
        payload.isLowIncome = false;
      }
    }

    if (entergyAccountNumber) {
      payload.entergyAccountNumber = entergyAccountNumber;
    }

    if (notes) {
      payload.notes = notes;
    }

    yield call(registerCustomer, payload);

    yield put({
      type: QUICK_REGISTER_SUCCESS
    });
  } catch (error) {
    const errors = error.data.code === 422 ? getValidationErrors(error) : error;

    yield put({
      type: QUICK_REGISTER_FAILURE,
      payload: errors
    });
  }
}

export function* watchRegisterCustomerAsync() {
  yield takeEvery(REGISTER_CUSTOMER, registerCustomerAsync);
}

export function* watchQuickRegisterAsync() {
  yield takeEvery(QUICK_REGISTER, quickRegisterAsync);
}

export function* watchJoinWaitingListAsync() {
  yield takeEvery(JOIN_WAITING_LIST, joinWaitingAsync);
}
