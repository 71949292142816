export function getInitialState() {
  return {
    alertsReducer: {
      otherRegionsAlertDismissed: localStorage.getItem(
        "otherRegionsAlertDismissed"
      )
    }
  };
}

export function createLocalStorageListener(store) {
  return () => {
    localStorage.setItem(
      "otherRegionsAlertDismissed",
      store.getState().alertsReducer.otherRegionsAlertDismissed
    );
  };
}
