import * as React from "react";
import * as operatingCompaniesActions from "../../../actions/operatingCompaniesActions";

import { Field, formValueSelector, reduxForm, reset } from "redux-form";
import {
  QUICK_REGISTER,
  QUICK_REGISTER_FAILURE,
  QUICK_REGISTER_SUCCESS
} from "./../../../actions/types";
import {
  addressHasError,
  emailHasError,
  maxLength,
  minLength,
  nameHasError,
  phoneNumberHasError,
  required,
  zipcodeHasError
} from "../../../utils/formValidation";
import {
  renderSelectField,
  renderTextAreaField,
  renderTextField
} from "../../../services/form.service";

import LowIncomeForm from "./LowIncomeForm";
import MakeAsyncFunction from "react-redux-promise-listener";
import ModalDialog from "../../../components/ModalDialog/ModalDialog";
import { connect } from "react-redux";
import { isNumeric } from "../../../utils/regex";
import { promiseListener } from "./../../../store";

const maxLength25 = maxLength(25);
const minLength1 = minLength(1);
const minLength7 = minLength(7);
const maxLength100 = maxLength(100);

const accountNumberHasError = value => {
  if (value !== undefined) {
    if (isNumeric(value) === false) {
      return "Please enter numbers only";
    } else if (value.length > 12) {
      return "12 digit max";
    } else if (value.length < 8) {
      return "8 digit min";
    }
  }
};

const validateSignUp = values => {
  let errors = {};

  if (values.isWaitlisted === false) {
    const isSolarPlanIdInvalid = required(values.solarPlanId);
    if (isSolarPlanIdInvalid) {
      errors.solarPlanId = isSolarPlanIdInvalid;
    }
  }

  if (
    values.selectedPlanType !== undefined &&
    values.selectedPlanType === "lowIncome" &&
    values.lowIncomeAgreement !== undefined &&
    values.lowIncomeAgreement === false
  ) {
    const isSelectedPlanTypeInvalid = required(values.selectedPlanType);
    if (isSelectedPlanTypeInvalid) {
      errors.selectedPlanType = isSelectedPlanTypeInvalid;
    }
  }

  return errors;
};

class EbcSignUpForm extends React.PureComponent<*> {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    };
  }

  getOptions = (items, valueField, displayField, disabledSelector) => {
    const options = [];

    if (items && items.length > 0) {
      return items.map(item => {
        return {
          value: item[valueField],
          label: item[displayField],
          disabled: disabledSelector ? disabledSelector(item) : false
        };
      });
    }

    return options;
  };

  handleOperatingCompanySelectionChanged = (props, operatingCompanyId) => {
    const { input, updateSelectedOperatingCompany, operatingCompanies } = props;
    const operatingCompany = operatingCompanies.find(
      operatingCompany => operatingCompany.id === operatingCompanyId
    );

    input.onChange(operatingCompanyId);

    updateSelectedOperatingCompany(operatingCompany, false);
  };

  renderOperatingCompanyDropdown = props => {
    const { options, currentValue, label, meta } = props;

    return renderSelectField(label, meta, options, currentValue, true, value =>
      this.handleOperatingCompanySelectionChanged(props, value.value)
    );
  };

  renderAgreementCheckbox = field => (
    <div className="gs-form-checkbox-wrap">
      {field.meta.touched && field.meta.error && (
        <span className="gs-form-error gs-form-checkbox-error-label">
          {field.meta.error}
        </span>
      )}
      <input
        id="agree"
        className="gs-form-checkbox-agree"
        {...field.input}
        placeholder={field.label}
        type={field.type}
      />
      <label htmlFor="agree" className="tc-agreement-label">
        Customer requests service and agrees to be bound by the terms and
        conditions. Customer will review terms and conditions in confirmation
        email and understands opt-out procedures.
      </label>
    </div>
  );
  renderPlanDropdown = props => {
    const { options, currentValue, input, label, meta } = props;

    if (
      options === undefined ||
      (options.length === 0 &&
        currentValue !== undefined &&
        currentValue !== null)
    ) {
      input.onChange(null);
    } else if (
      options &&
      options.length > 0 &&
      currentValue !== undefined &&
      currentValue !== null
    ) {
      if (
        options.filter(
          option => option.value === currentValue && option.disabled === true
        ).length > 0 ||
        options.filter(option => option.value === currentValue).length === 0
      ) {
        input.onChange(null);
      }
    }

    const currentOption = !currentValue
      ? null
      : currentValue && options && options.length > 0
      ? options.find(option => option.value === currentValue)
      : {
          value: undefined,
          label: undefined
        };

    return renderSelectField(
      label,
      meta,
      options,
      currentOption,
      true,
      value => input.onChange(value.value),
      option => option.disabled
    );
  };

  renderPlanTypeDropdown = props => {
    const { options, currentValue, label, input, meta } = props;

    if (
      options === undefined ||
      (options.length === 0 &&
        currentValue !== undefined &&
        currentValue !== null)
    ) {
      input.onChange(null);
    }

    const currentOption = !currentValue
      ? null
      : currentValue && options && options.length > 0
      ? options.find(option => option.value === currentValue)
      : {
          value: undefined,
          label: undefined
        };

    return renderSelectField(label, meta, options, currentOption, true, value =>
      input.onChange(value.value)
    );
  };

  showSuccessModal = () => {
    this.setState({ modalIsOpen: true });
  };

  hideSuccessModal = () => {
    const { reset, resetSelectedOperatingCompany, change } = this.props;

    reset("EbcSignUp");

    this.setState({ modalIsOpen: false });

    resetSelectedOperatingCompany(false);

    change("operatingCompanyId", null);

    //scroll to top
    window.scrollTo(0, 0);
  };

  getModalContent = () => {
    return (
      <div className="gs-modal-success">
        <h2>Your submission has been received</h2>
      </div>
    );
  };

  isPlanAvailable = plan => {
    const { selectedPlanType, selectedOperatingCompany } = this.props;

    return (
      plan.capacityValue >
      (selectedPlanType === "residential"
        ? selectedOperatingCompany.availableResidentialCapacity
        : selectedPlanType === "lowIncome"
        ? selectedOperatingCompany.availableLowIncomeCapacity
        : selectedPlanType === "commercial"
        ? selectedOperatingCompany.availableCommercialCapacity
        : 0)
    );
  };

  render() {
    const {
      handleSubmit,
      invalid,
      // eslint-disable-next-line no-unused-vars
      submitSucceeded,
      pristine,
      submitting,
      operatingCompanies,
      operatingCompanyId,
      plans,
      selectedPlanType,
      solarPlanId,
      change,
      updateSelectedOperatingCompany,
      // eslint-disable-next-line no-unused-vars
      lowIncomeAgreement,
      isWaitlisted
    } = this.props;

    let modalContent;
    let plansOptions = [];
    let planTypeOptions = [];

    const operatingCompanyOptions =
      operatingCompanies && operatingCompanies.length > 0
        ? this.getOptions(
            operatingCompanies.filter(x => x.name !== "Other"),
            "id",
            "stateFullName"
          )
        : [];

    const planTypes = [];

    const selectedOperatingCompanyOption = operatingCompanyOptions.find(obj => {
      return obj.value === operatingCompanyId;
    })
      ? operatingCompanyOptions.find(obj => {
          return obj.value === operatingCompanyId;
        })
      : {
          value: undefined,
          label: undefined
        };

    const selectedOperatingCompany =
      operatingCompanyId && operatingCompanies && operatingCompanies.length > 0
        ? operatingCompanies.find(
            operatingCompany => operatingCompany.id === operatingCompanyId
          )
        : undefined;

    if (selectedOperatingCompany) {
      if (selectedOperatingCompany.hasResidentialCapacity) {
        planTypes.push({
          label: "Residential",
          value: "residential"
        });
      }

      if (selectedOperatingCompany.hasLowIncomeCapacity) {
        planTypes.push({
          label: "Residential Low Income",
          value: "lowIncome"
        });
      }

      if (selectedOperatingCompany.hasCommercialCapacity) {
        planTypes.push({
          label: "Business",
          value: "commercial"
        });
      }
    }

    const selectedPlan =
      plans && plans.length > 0 && solarPlanId
        ? plans.find(plan => plan.id === solarPlanId)
        : undefined;

    if (plans && plans.length > 0) {
      planTypeOptions =
        selectedOperatingCompany && selectedOperatingCompany.isActive === true
          ? this.getOptions(planTypes, "value", "label")
          : [];

      if (selectedPlanType) {
        const planType =
          selectedPlanType === "lowIncome" ? "residential" : selectedPlanType;

        const selectedPlanTypePlans = plans
          .filter(plan => plan.type === planType)
          .map(plan => {
            return {
              ...plan,
              display: plan.name
                ? `${plan.name} - ${plan.capacityValue} ${plan.capacityUnit}`
                : `${plan.capacityValue} ${plan.capacityUnit}`
            };
          });

        plansOptions = this.getOptions(
          selectedPlanTypePlans,
          "id",
          "display",
          this.isPlanAvailable
        );
      }
    }

    if (submitSucceeded) {
      this.showSuccessModal();
      modalContent = this.getModalContent();
    }

    if (isWaitlisted === false) {
      if (
        (selectedOperatingCompany && !selectedOperatingCompany.isActive) ||
        (selectedOperatingCompany &&
          selectedPlanType &&
          ((selectedPlanType === "residential" &&
            !selectedOperatingCompany.hasResidentialCapacity) ||
            (selectedPlanType === "lowIncome" &&
              !selectedOperatingCompany.hasLowIncomeCapacity) ||
            (selectedPlanType === "commercial" &&
              !selectedOperatingCompany.hasCommercialCapacity)))
      ) {
        change("isWaitlisted", true);
      }
    } else if (isWaitlisted === true) {
      if (
        selectedOperatingCompany &&
        selectedOperatingCompany.isActive === true &&
        ((selectedOperatingCompany && !selectedPlanType) ||
          (selectedPlanType === "residential" &&
            selectedOperatingCompany.hasResidentialCapacity) ||
          (selectedPlanType === "lowIncome" &&
            selectedOperatingCompany.hasLowIncomeCapacity) ||
          (selectedPlanType === "commercial" &&
            selectedOperatingCompany.hasCommercialCapacity))
      ) {
        change("isWaitlisted", false);
      }
    }

    return (
      <MakeAsyncFunction
        listener={promiseListener}
        start={QUICK_REGISTER}
        resolve={QUICK_REGISTER_SUCCESS}
        reject={QUICK_REGISTER_FAILURE}
      >
        {onSubmit => (
          <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="main-form__group">
                <h2>Select OpCo</h2>

                <Field
                  label="OpCo Type"
                  name="operatingCompanyId"
                  operatingCompanies={operatingCompanies}
                  updateSelectedOperatingCompany={
                    updateSelectedOperatingCompany
                  }
                  component={this.renderOperatingCompanyDropdown}
                  options={operatingCompanyOptions}
                  currentValue={selectedOperatingCompanyOption}
                />

                {isWaitlisted === undefined ||
                  (isWaitlisted === false && (
                    <div>
                      <h2>Select plan</h2>

                      <div className="main-form__double">
                        <Field
                          name="selectedPlanType"
                          label="Plan type"
                          component={this.renderPlanTypeDropdown}
                          options={planTypeOptions}
                          currentValue={selectedPlanType}
                        />

                        <Field
                          name="solarPlanId"
                          label="Plan size"
                          component={this.renderPlanDropdown}
                          currentValue={solarPlanId}
                          options={plansOptions}
                        />
                      </div>
                    </div>
                  ))}

                <div className="separator" />

                <h2>Add Customer Information</h2>

                <div className="main-form__double">
                  <Field
                    name="firstName"
                    type="text"
                    validate={[nameHasError, maxLength25, minLength1]}
                    maxLength="25"
                    component={renderTextField}
                    placeholder="First Name"
                    label="First Name"
                  />

                  <Field
                    name="lastName"
                    type="text"
                    label="Last Name"
                    placeholder="Last Name"
                    validate={[nameHasError, maxLength25, minLength1]}
                    maxLength="25"
                    component={renderTextField}
                  />
                </div>
                {selectedPlanType === "commercial" && (
                  <Field
                    name="companyName"
                    type="text"
                    placeholder="Business Name"
                    validate={[required, maxLength100, minLength1]}
                    component={renderTextField}
                    maxLength="100"
                    label="Business Name"
                  />
                )}

                <div className="main-form__double main-form__double_first-long">
                  <Field
                    name="address"
                    type="text"
                    maxLength="100"
                    validate={[required, addressHasError]}
                    component={renderTextField}
                    label={
                      selectedPlanType === "commercial"
                        ? "Address of Business"
                        : "Residence"
                    }
                    placeholder="Street Address, Unit #"
                  />

                  <Field
                    name="zipcode"
                    type="text"
                    placeholder="5 digits"
                    maxLength="5"
                    validate={[required, zipcodeHasError]}
                    component={renderTextField}
                    label="Zip Code"
                  />
                </div>

                <Field
                  name="emailAddress"
                  type="text"
                  placeholder="example@example.com"
                  validate={[required, emailHasError, minLength7, maxLength100]}
                  component={renderTextField}
                  maxLength="100"
                  label="Email Address"
                />

                <div className="main-form__double">
                  <Field
                    name="phoneNumber"
                    type="text"
                    placeholder="10 digits"
                    maxLength="10"
                    validate={[required, phoneNumberHasError]}
                    component={renderTextField}
                    label="Phone Number"
                  />

                  <Field
                    name="entergyAccountNumber"
                    type="text"
                    component={renderTextField}
                    maxLength="12"
                    label="Entergy Account Number (Optional)"
                    validate={[accountNumberHasError]}
                    placeholder="8-12 digits"
                  />
                </div>

                <Field
                  name="notes"
                  maxLength="1000"
                  label="Notes"
                  component={renderTextAreaField}
                />

                {selectedPlanType !== undefined &&
                  selectedPlan !== undefined &&
                  selectedPlanType === "lowIncome" && (
                    <div>
                      <div className="separator" />
                      <LowIncomeForm
                        onSuccess={agreed =>
                          change("lowIncomeAgreement", agreed)
                        }
                      />
                    </div>
                  )}

                <h2>Review Selection</h2>
                <div className="review-selection-header">
                  Expected average monthly charge, based on solar output
                </div>
                <div
                  className={`review-selection ${
                    selectedPlan === undefined ? "unselected" : "selected"
                  }`}
                >
                  {selectedPlan === undefined && (
                    <div className="review-selection-labels">
                      <div>Plan Type</div>
                      <div>Plan Size</div>
                    </div>
                  )}

                  {selectedPlan !== undefined && (
                    <div className="review-selection-labels">
                      <div>
                        {selectedPlanType === "commercial" && (
                          <span>Business</span>
                        )}

                        {selectedPlanType === "residential" && (
                          <span>Residential</span>
                        )}
                      </div>
                      <div>
                        {selectedPlan && selectedPlan.name && (
                          <span>
                            {selectedPlan.name} {" - "}{" "}
                            {selectedPlan.capacityValue}{" "}
                            {selectedPlan.capacityUnit}
                          </span>
                        )}
                        {selectedPlan && !selectedPlan.name && (
                          <span>
                            {selectedPlan.capacityValue}{" "}
                            {selectedPlan.capacityUnit}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {selectedPlan === undefined && (
                    <div className="review-selection-values">
                      <div className="review-select-price">$—</div>
                      <div className="review-select-price-label">
                        Average per month
                      </div>
                    </div>
                  )}
                  {selectedPlan !== undefined && (
                    <div className="review-selection-values">
                      <div className="review-select-price">
                        {selectedPlan.averagePrice !== null && (
                          <span>${selectedPlan.averagePrice}</span>
                        )}
                        {selectedPlan.averagePrice === null && (
                          <span>${selectedPlan.price}</span>
                        )}
                      </div>
                      <div className="review-select-price-label">
                        Average per month
                      </div>
                    </div>
                  )}
                </div>

                <div className="terms-conditions-header">
                  Terms and Conditions
                </div>
                <div className="terms-conditions-container">
                  <Field
                    name="agree"
                    type="checkbox"
                    validate={required}
                    component={this.renderAgreementCheckbox}
                  />
                </div>
              </div>
            </div>

            <button
              className="main-form__btn gs-form-btn block"
              type="submit"
              disabled={invalid || submitting || pristine || submitSucceeded}
            >
              {isWaitlisted !== undefined && isWaitlisted === false && (
                <span>Sign Up Customer</span>
              )}

              {isWaitlisted !== undefined && isWaitlisted === true && (
                <span>JOIN WAITLIST</span>
              )}
            </button>

            <ModalDialog
              key="modal-dialog"
              isOpen={this.state.modalIsOpen}
              closeHandler={() => this.hideSuccessModal(reset)}
              contentElement={modalContent}
            />
          </form>
        )}
      </MakeAsyncFunction>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  updateSelectedOperatingCompany: (operatingCompany, persistSelection) =>
    dispatch(
      operatingCompaniesActions.updateSelectedOperatingCompany(
        operatingCompany,
        persistSelection
      )
    ),
  resetSelectedOperatingCompany: () =>
    dispatch(operatingCompaniesActions.resetSelectedOperatingCompany())
});

// eslint-disable-next-line no-class-assign
EbcSignUpForm = reduxForm({
  form: "EbcSignUp",
  validate: validateSignUp,
  enableReinitialize: true
})(EbcSignUpForm);

// eslint-disable-next-line no-class-assign
EbcSignUpForm = connect(state => {
  const operatingCompanyId = formValueSelector("EbcSignUp")(
    state,
    "operatingCompanyId"
  );

  const selectedPlanType = formValueSelector("EbcSignUp")(
    state,
    "selectedPlanType"
  );

  const solarPlanId = formValueSelector("EbcSignUp")(state, "solarPlanId");

  const lowIncomeAgreement = formValueSelector("EbcSignUp")(
    state,
    "lowIncomeAgreement"
  );

  const isWaitlisted = formValueSelector("EbcSignUp")(state, "isWaitlisted");

  return {
    operatingCompanyId,
    selectedPlanType,
    solarPlanId,
    lowIncomeAgreement,
    isWaitlisted,
    operatingCompanies: state.operatingCompaniesReducer.operatingCompanies,
    plans: state.plansReducer.allPlans,
    availableResidentialCapacity:
      state.operatingCompaniesReducer.availableResidentialCapacity,
    availableCommercialCapacity:
      state.operatingCompaniesReducer.availableCommercialCapacity,
    availableLowIncomeCapacity:
      state.operatingCompaniesReducer.availableLowIncomeCapacity,
    selectedOperatingCompany:
      state.operatingCompaniesReducer.selectedOperatingCompany
  };
}, mapDispatchToProps)(EbcSignUpForm);

export default EbcSignUpForm;
