import "./styles.scss";

import * as React from "react";

import { NavHashLink as Link } from "react-router-hash-link";
import { connect } from "react-redux";

class SepoBEnrollment extends React.PureComponent<*> {
  render() {
    return (
      <div>
        <div id="SepoBProcess" />
        <div className="centered-content">
          <div className="hero-block home-no-farms-card gs-grid">
            <div className="row">
              <div className="col home-no-farms-card-image">
                <img
                  src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/Texas_Illustration.jpg"
                  alt="Solar Farm Illustration"
                />
              </div>
              <div className="col home-no-farms-card-description">
                <h1>Ready to sign up?</h1>
                <div className="sub-header">
                  Reminder to submit your sign up only once.
                </div>
                <Link className="btn" to="get-started">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(undefined, undefined)(SepoBEnrollment);
