import "./styles.scss";

import * as React from "react";

import Modal from "react-modal";

class ModalDialog extends React.PureComponent<*> {
  render() {
    const { isOpen, closeHandler, contentElement } = this.props;

    return (
      <Modal
        className="gs-modal-aligner"
        overlayClassName="gs-overlay"
        isOpen={isOpen}
      >
        <div onClick={() => closeHandler()} className="overlay-close" />
        <div className="gs-modal">
          <div className="gs-modal-wrapper">
            <span onClick={() => closeHandler()} className="gs-modal-close">
              &times;
            </span>
            <div>{contentElement}</div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default ModalDialog;
