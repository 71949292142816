import alertsReducer from "./alertsReducer";
import { combineReducers } from "redux";
import faqReducer from "./faqReducer";
import { reducer as formReducer } from "redux-form";
import operatingCompaniesReducer from "./operatingCompaniesReducer";
import plansReducer from "./plansReducer";
import registrationReducer from "./registrationReducer";
import segmentReducer from "./segmentReducer";
import sepoBReducer from "./sepobReducer";
import topicsReducer from "./topicsReducer";

export default combineReducers({
  alertsReducer,
  registrationReducer,
  operatingCompaniesReducer,
  plansReducer,
  topicsReducer,
  segmentReducer,
  faqReducer,
  sepoBReducer,
  form: formReducer
});
