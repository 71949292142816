import "./styles.scss";

import * as React from "react";

import { connect } from "react-redux";

class SepoBSuccess extends React.PureComponent<*> {
  render() {
    return (
      <div>
        <div id="SepoBSuccess" />
        <div className="centered-container">
          <div className="hero-block home-no-farms-card gs-grid">
            <div className="row">
              <div className="col home-no-farms-card-description">
                <div className="success-heading">
                  Thank you for signing up for SEPO Option B!
                </div>
                <div className="success-sub-header">
                  <p>
                    We are excited about the opportunity to offer solar options
                    in Arkansas, and happy that you are part of the solar
                    journey.
                  </p>
                  <p>
                    An Entergy Arkansas representative will contact you within 7
                    business days to discuss the status of your application. Be
                    advised, there is{" "}
                    <b>
                      <i>limited solar capacity</i>
                    </b>{" "}
                    at this time and subscriptions will be granted based on a
                    first come, first served basis.
                  </p>
                  <p>
                    Should you receive a portion of the available capacity, our
                    representative will:
                  </p>
                  <p>
                    <div>1) Review the requirements of the offering</div>
                    <div>2) Discuss and confirm your subscription size</div>
                    <div>3) Program all eligible accounts for billing</div>
                    <div>
                      4) Request that you complete and submit a signed Agreement
                      for SEPO B to{" "}
                      <a href="mailto:SolarAR@Entergy.com">
                        SolarAR@Entergy.com
                      </a>
                    </div>
                  </p>
                  <p>
                    If we are unable to fulfill your application, due to
                    capacity constraints, you will be placed on a wait list for
                    future offerings.
                  </p>
                </div>
              </div>
              <div className="col home-no-farms-card-image">
                <img
                  src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/Texas_Illustration.jpg"
                  alt="Solar Farm Illustration"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(undefined, undefined)(SepoBSuccess);
