import * as React from "react";

import { Content } from "./../../components/Content/Content";
import FarmsHomeCTA from "./components/FarmsHomeCTA/FarmsHomeCTA";
import HomeCommonInfo from "./components/HomeCommonInfo/HomeCommonInfo";
import HowMakeImpact from "./components/HowMakeImpact/HowMakeImpact";
import Locations from "./../Locations/Locations";
import SepoBEnrollment from "./components/SepoB-Enrollment/SepoBEnrollment";
import { connect } from "react-redux";

export class Home extends React.PureComponent<*> {
  render() {
    const {
      selectedOperatingCompany,
      selectedOperatingCompany: { visibility } = { visibility: {} },
      checkedForSelectedOperatingCompany
    } = this.props;

    const isSepoB =
      selectedOperatingCompany &&
      selectedOperatingCompany.abbreviation === "EAL-B";

    if (checkedForSelectedOperatingCompany && !selectedOperatingCompany) {
      return <Locations />;
    } else if (checkedForSelectedOperatingCompany && selectedOperatingCompany) {
      return (
        <Content>
          <HomeCommonInfo />
          {visibility.plans && <HowMakeImpact />}
          {visibility.solarFarms && <FarmsHomeCTA />}
          {isSepoB && <SepoBEnrollment />}
        </Content>
      );
    } else {
      return <div />;
    }
  }
}

const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  checkedForSelectedOperatingCompany:
    state.operatingCompaniesReducer.checkedForSelectedOperatingCompany
});

export default connect(mapStateToProps)(Home);
