import {
  CHECKED_FOR_SELECTED_OPERATING_COMPANY,
  GET_ALL_PLANS,
  GET_OPERATING_COMPANIES,
  GET_SELECTED_OPERATING_COMPANY,
  UPDATE_SELECTED_OPERATING_COMPANY
} from "./types";

import { EventTypes } from "redux-segment";

export const getOperatingCompanies = () => ({
  type: GET_OPERATING_COMPANIES
});

export function resetSelectedOperatingCompany(persistSelection) {
  return {
    type: UPDATE_SELECTED_OPERATING_COMPANY,
    payload: {
      operatingCompany: undefined,
      persistSelection
    }
  };
}

export function updateSelectedOperatingCompany(
  operatingCompany,
  persistSelection = true
) {
  return {
    type: UPDATE_SELECTED_OPERATING_COMPANY,
    payload: {
      operatingCompany,
      persistSelection
    },
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Choose Location",
          properties: {
            OpCo: operatingCompany.name,
            Status: operatingCompany.isActive ? "Active" : "Inactive"
          }
        }
      }
    }
  };
}

export const getSelectedOperatingCompany = () => ({
  type: GET_SELECTED_OPERATING_COMPANY
});

export const updateCheckForSelectedOperatingCompany = checkForSelectedOperatingCompany => ({
  type: CHECKED_FOR_SELECTED_OPERATING_COMPANY,
  payload: checkForSelectedOperatingCompany
});

export const getAllPlans = operatingCompanyOd => ({
  type: GET_ALL_PLANS,
  payload: operatingCompanyOd
});
