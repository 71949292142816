import { GET_TOPICS_SUCCESS } from "./../actions/types";

const initialState = {
  topics: undefined
};

const topicsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TOPICS_SUCCESS: {
      return {
        ...state,
        topics: [].concat.apply([], action.payload)
      };
    }
    default: {
      return state;
    }
  }
};

export default topicsReducer;
