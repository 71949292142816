import "./styles.scss";

import * as React from "react";

import { Content } from "../../components/Content/Content";

class NotFound extends React.PureComponent {
  render() {
    return (
      <Content isFooterVisible={false}>
        <div className="centered-content not-found">
          OOPS! PAGE NOT FOUND
          <strong>404</strong>
          <p>We are sorry, but the page you requested was not found.</p>
          <div className="cloud-big" />
          <div className="cloud-big right" />
          <div className="cloud-small left" />
          <div className="cloud-small" />
          <div className="cloud-small right" />
        </div>
      </Content>
    );
  }
}

export default NotFound;
