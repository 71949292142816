import "./styles.scss";

import * as React from "react";
import * as segmentActions from "./../../actions/segmentActions";

import { Content } from "../../components/Content/Content";
import Helmet from "react-helmet";
import { NavHashLink as Link } from "react-router-hash-link";
import connect from "react-redux/es/connect/connect";

class OurFarms extends React.PureComponent<*> {
  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed("our-farms");
    }
  }

  renderCTAButton = () => {
    const { selectedOperatingCompany } = this.props;

    if (
      selectedOperatingCompany !== undefined &&
      selectedOperatingCompany.name !== "Other" &&
      selectedOperatingCompany.hasCapacity
    ) {
      return (
        <Link className="btn" to={`${process.env.PUBLIC_URL}/plans`}>
          See Plans
        </Link>
      );
    } else if (
      selectedOperatingCompany !== undefined &&
      selectedOperatingCompany.name !== "Other" &&
      !selectedOperatingCompany.hasCapacity
    ) {
      return (
        <Link className="btn" to={`${process.env.PUBLIC_URL}/join-waitlist`}>
          Join Waitlist
        </Link>
      );
    }
  };

  render() {
    const { locations, selectedOperatingCompany } = this.props;
    const operatingCompanyState = selectedOperatingCompany
      ? selectedOperatingCompany.stateFullName
      : "";

    const helmet = (
      <Helmet>
        <title>
          Learn About Our Community Solar Farms in {operatingCompanyState} |
          Solarity
        </title>
        <description>
          Our utility-grade solar farms power hundreds of homes and businesses.
          You can share solar power with your community, but the impact will go
          far beyond your home state.
        </description>
      </Helmet>
    );

    if (!selectedOperatingCompany || !locations) {
      return <div>{helmet}</div>;
    }

    const getOpCoBackground = () => {
      const pixelRatio = window.devicePixelRatio > 1.5 ? "2x" : "1x";
      const prefix = selectedOperatingCompany
        ? selectedOperatingCompany.stateFullName + "-"
        : "";
      return (
        "url(https://d1oeu1h51wn2cw.cloudfront.net/desktop/SolarFarms-Hero-Desktop-" +
        prefix +
        pixelRatio +
        ".jpg)"
      );
    };

    return (
      <Content className="our-farms-wrap">
        {helmet}
        <header
          className="inner-header dark"
          style={{ backgroundImage: getOpCoBackground() }}
        >
          <div className="inner-header__content">
            <h1>Our Solar Farms</h1>
            <div className="descr">
              Making a difference in{" "}
              {selectedOperatingCompany &&
                selectedOperatingCompany.stateFullName}
              .
            </div>
          </div>
        </header>

        <div className="our-farms">
          <div className="imaged-block">
            <div className="img">
              <img
                src={`https://d1oeu1h51wn2cw.cloudfront.net/desktop/SolarFarms-Map-Desktop-${selectedOperatingCompany.state}-1x.jpg`}
                srcSet={`https://d1oeu1h51wn2cw.cloudfront.net/desktop/SolarFarms-Map-Desktop-${selectedOperatingCompany.state}-2x.jpg 2x`}
                alt=""
              />
            </div>
            <div className="text">
              <h2>Local solar, global impact</h2>
              <p>
                Our utility-grade solar farms power hundreds of homes and
                businesses across {selectedOperatingCompany.stateFullName}. You
                can share solar power with your community, but the impact will
                go far beyond your home state.
              </p>
              {this.renderCTAButton()}
            </div>
          </div>
        </div>
        <div className="where-work">
          <div className="where-work-aligner">
            <h2>Where we work</h2>

            <ul className="where-work-list">
              {locations.map((location, index) => {
                const imageIndex = index + 1;

                return (
                  <li
                    className="where-work-list__item"
                    key={`location-${index}`}
                  >
                    <img
                      src={`https://d1oeu1h51wn2cw.cloudfront.net/desktop/SolarFarms-SiteImage${imageIndex}-Desktop-${selectedOperatingCompany.state}-1x.jpg`}
                      srcSet={`https://d1oeu1h51wn2cw.cloudfront.net/desktop/SolarFarms-SiteImage${imageIndex}-Desktop-${selectedOperatingCompany.state}-2x.jpg 2x`}
                      alt=""
                    />
                    <strong>{location.city}</strong>
                    {location.isActive && (
                      <div className="location">{location.locationDisplay}</div>
                    )}

                    {location.isActive && (
                      <p>
                        {location.totalPanels} panels
                        <br />
                        {location.totalCapacity} kilowatts (kW)
                      </p>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="have-questions">
          <div className="have-questions__text">
            <h3>Still have questions?</h3>
            <p>Learn more about how our solar farms power you in our FAQ.</p>
          </div>
          <Link className="btn black" to={`${process.env.PUBLIC_URL}/faq`}>
            See FAQ
          </Link>
        </div>
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});
const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  locations: state.operatingCompaniesReducer.solarFarmLocations
});

export default connect(mapStateToProps, mapDispatchToProps)(OurFarms);
