import "./styles.scss";

import * as React from "react";
import * as operatingCompaniesActions from "./../../actions/operatingCompaniesActions";
import * as plansActions from "./../../actions/plansActions";
import * as segmentActions from "./../../actions/segmentActions";

import { Content } from "../../components/Content/Content";
import { Helmet } from "react-helmet";
import { NavHashLink as Link } from "react-router-hash-link";
import carIcon from "./../../img/svg/plan-car-icon.svg";
import { connect } from "react-redux";
import iIcon from "./../../img/svg/plans-info.svg";
import impactSaveBags from "./../../img/svg/impact-save-bags.svg";
import impactSaveTrees from "./../../img/svg/impact-save-trees.svg";
import message from "./../../img/svg/message-white.svg";
import operatingCompanyIds from "../../constants/operatingCompanyIds";
import qMark from "./../../img/svg/question-mark.svg";

//TODO: remove after CSS is corrected
const selectPlanButtonStyle = { width: "240px" };

class PlanSelector extends React.PureComponent<*> {
  componentDidMount() {
    const {
      plans,
      getPlans,
      trackPageViewed,
      selectedOperatingCompany,
      getSelectedOperatingCompany
    } = this.props;

    if (plans === undefined) {
      getPlans();
    }

    if (!selectedOperatingCompany) {
      getSelectedOperatingCompany();
    }

    if (trackPageViewed) {
      trackPageViewed("plans");
    }
  }

  handleChoosePlan(plan) {
    const {
      history,
      updateSelectedPlan,
      selectPlan,
      selectedOperatingCompany,
      selectedPlanType,
      startPath
    } = this.props;
    const subscriptionSize = plan.capacityValue + " " + plan.capacityUnit;
    const operatingCompanyName = selectedOperatingCompany.name;
    const operatingCompanyStatus =
      selectedOperatingCompany &&
      selectedOperatingCompany.isActive &&
      selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : selectedOperatingCompany && !selectedOperatingCompany.isActive
        ? "Inactive"
        : selectedOperatingCompany &&
          selectedOperatingCompany.isActive &&
          !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    const customerType =
      selectedPlanType === "residential" ? "Residential" : "Commercial";

    updateSelectedPlan(plan);

    selectPlan(
      subscriptionSize,
      operatingCompanyStatus,
      operatingCompanyName,
      plan.name,
      customerType,
      startPath
    );

    history.push({ pathname: "/signup" });
  }

  handleCustomerTypeChanged(customerType) {
    const {
      updateSelectedPlanType,
      toggleCustomerType,
      selectedOperatingCompany
    } = this.props;
    const selectedPlanType =
      customerType === "residential" ? "Residential" : "Commercial";

    if (
      selectedOperatingCompany &&
      selectedOperatingCompany.id !== operatingCompanyIds.Texas
    ) {
      updateSelectedPlanType(customerType);

      toggleCustomerType(selectedPlanType);
    }
  }

  renderBottomCard({ isTexas }) {
    const { selectedPlanType, hasLowIncomeAvailableCapacity } = this.props;

    if (isTexas) {
      return (
        <div>
          <strong>Still have questions about solar?</strong>
          Please contact us if you are interested in learning more about solar
          in Texas.
        </div>
      );
    } else {
      if (selectedPlanType === "residential" && hasLowIncomeAvailableCapacity) {
        return (
          <div>
            <strong>Interested in Low Income Benefits?</strong>
            Please contact us if you are interested in learning more about our
            low income options.
          </div>
        );
      } else {
        return (
          <div>
            <strong>Need more options?</strong>
            If you’re interested in seeing different sizes for your Solarity
            subscription, please contact us.
          </div>
        );
      }
    }
  }

  renderSelectPlanButton(plan, text, disabled) {
    return (
      <button
        className="btn centered"
        disabled={disabled}
        onClick={() => this.handleChoosePlan(plan)}
        style={selectPlanButtonStyle}
      >
        {text}
      </button>
    );
  }

  render() {
    const { selectedPlanType, selectedOperatingCompany } = this.props;

    if (!selectedOperatingCompany) {
      return <div />;
    }

    const plans = this.props.plans || [];
    const chosenTypePlans =
      plans.filter(plan => plan.type === selectedPlanType) || [];

    const customerType =
      selectedPlanType === "residential" ? "Residential" : "Business";

    const isTexas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Texas
    );

    const isArkansas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Arkansas
    );

    const commercialPlansDisabled = isTexas;
    const selectPlanButtonText = isTexas
      ? "Join Waitlist"
      : isArkansas
      ? "Select"
      : "Reserve";

    const noCapacity = !!(
      (selectedPlanType === "residential" &&
        !selectedOperatingCompany.hasLowIncomeCapacity &&
        !selectedOperatingCompany.hasResidentialCapacity) ||
      (selectedPlanType === "commercial" &&
        !selectedOperatingCompany.hasCommercialCapacity)
    );

    return (
      <Content>
        <Helmet>
          <title>
            Choose The Impact You Want To Make - Subscribe Today | Solarity
          </title>
          <description>
            We’ll save some sunshine for you, then your contribution will be
            included on your monthly Entergy bill. You can cancel your
            subscription at any time.
          </description>
        </Helmet>
        <div className="plan-chooser-standalone">
          <h2 className="content-heading">Choose your impact</h2>

          <div className="type-switcher-inputs">
            <div className="type-switcher-inputs__item custom-radio">
              <input
                type="radio"
                id="investment-type-residential"
                name="investment-type"
                value="residential"
                checked={selectedPlanType === "residential"}
                onChange={() => this.handleCustomerTypeChanged("residential")}
              />
              <label htmlFor="investment-type-residential">Residential</label>
            </div>

            <div className="type-switcher-inputs__item custom-radio">
              <input
                type="radio"
                id="investment-type-commercial"
                name="investment-type"
                value="commercial"
                checked={selectedPlanType === "commercial"}
                onChange={() => this.handleCustomerTypeChanged("commercial")}
                disabled={commercialPlansDisabled}
              />
              <label htmlFor="investment-type-commercial">Business</label>
            </div>
          </div>

          <div className="plan-chooser-standalone__content">
            <div className="plan-chooser-standalone__aligner">
              <div className="plan-chooser-standalone__banner">
                <img src={message} alt="" />
                {noCapacity ? (
                  <span>
                    0 spots remaining for {selectedPlanType} Customers.
                    <Link to="/join-waitlist">Join our waitlist</Link>
                    to hear about future capacity.
                  </span>
                ) : isTexas ? (
                  <span>
                    Select one of the plans to join the waitlist and bring
                    community solar to Texas.
                  </span>
                ) : selectedPlanType === "commercial" ? (
                  <span>
                    Reserve your spot using one of the plans. Want to talk to
                    someone before signing up?{" "}
                    <Link to="/contact-us">Contact us</Link>
                  </span>
                ) : !selectedOperatingCompany.hasResidentialCapacity &&
                  selectedOperatingCompany.hasLowIncomeCapacity ? (
                  <span>
                    Limited Residential capacity at this time; only Low Income
                    capacity available. Please{" "}
                    <Link to="/contact-us">Contact us</Link>
                    for more information
                  </span>
                ) : (
                  <span>Reserve your spot using one of the plans</span>
                )}
              </div>

              <div className="plan-chooser-grid">
                {chosenTypePlans.map(plan => {
                  return (
                    <div className="plan-chooser-grid__item" key={plan.id}>
                      <div>
                        <div className="plan-chooser-grid__header">
                          <strong>{plan.name}</strong>
                          <span className="amm">
                            {plan.capacityValue} {plan.capacityUnit}
                          </span>
                          <span className="tooltip">
                            <img src={iIcon} alt="" />
                            <div>
                              {plan.capacityValue} {plan.capacityUnit} =
                              kilowatt <br />
                              This plan is equivalent to{" "}
                              {plan.averageEnergyUseEquivalent}% of an average
                              3-bedroom household’s electricity usage.
                            </div>
                          </span>
                        </div>
                        <div className="plan-chooser-grid__avg">
                          <div className="block">
                            <strong>
                              ${plan.averagePrice}
                              <span className="light"> {"/"}mo.</span>
                            </strong>
                            Averaged over a<div> 12-month period </div>
                          </div>
                        </div>

                        <p>
                          The price is not expected to exceed ${plan.maxPrice}{" "}
                          per month, on your Entergy bill.
                        </p>
                        <div className="choose-container">
                          {selectedPlanType === "commercial"
                            ? this.renderSelectPlanButton(
                                plan,
                                selectPlanButtonText,
                                !selectedOperatingCompany.hasCommercialCapacity ||
                                  selectedOperatingCompany.availableCommercialCapacity <
                                    plan.capacityValue
                              )
                            : this.renderSelectPlanButton(
                                plan,
                                selectPlanButtonText,
                                !selectedOperatingCompany.hasResidentialCapacity ||
                                  selectedOperatingCompany.availableResidentialCapacity <
                                    plan.capacityValue
                              )}

                          <div className="disabled-overlay">
                            <span>At Capacity</span>
                          </div>
                        </div>
                        <div className="plan-chooser-grid__impact">
                          <div className="plan-chooser-grid__impact-title">
                            Your Estimated Yearly Impact
                          </div>

                          <div className="plan-chooser-grid__impact-row">
                            <div className="icon">
                              <img className="car" src={carIcon} alt="" />
                            </div>
                            <div className="text">
                              <span>{plan.gallonsOfGasUsed}</span> Gallons of
                              gas unused
                            </div>
                          </div>

                          <div className="plan-chooser-grid__impact-row">
                            <div className="icon">
                              <img src={impactSaveTrees} alt="" />
                            </div>
                            <div className="text">
                              <span>{plan.equivalentTreesPlanted}</span> Trees
                              planted
                            </div>
                          </div>

                          <div className="plan-chooser-grid__impact-row">
                            <div className="icon">
                              <img src={impactSaveBags} alt="" />
                            </div>
                            <div className="text">
                              <span>{plan.bagsOfTrashRecycled}</span> Bags of
                              trash recycled
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="centered-content" id="how-we-calculate-cost">
            <div className="monthly-cost">
              <h2>How we calculate your monthly cost</h2>
              <p className="paragraph">What you’ll see on your itemized bill</p>
              <div className="monthly-cost-graphic">
                <div className="monthly-cost-graphic__item text bill">
                  <strong>
                    Net impact on
                    <br /> your bill
                  </strong>
                  Your monthly cost
                </div>
                <div className="monthly-cost-graphic__item eq" />
                <div className="monthly-cost-graphic__item text solar">
                  <strong>
                    Community Solar
                    <br /> subscription
                  </strong>

                  {selectedOperatingCompany &&
                    selectedOperatingCompany.id ===
                      operatingCompanyIds.Arkansas && (
                      <span>Variable monthly cost for Solarity</span>
                    )}

                  {selectedOperatingCompany &&
                    selectedOperatingCompany.id !==
                      operatingCompanyIds.Arkansas && (
                      <span>Fixed monthly cost for Solarity</span>
                    )}
                </div>
                <div className="monthly-cost-graphic__item minus" />
                <div className="monthly-cost-graphic__item text output">
                  <strong>
                    Solar farm
                    <br /> output
                  </strong>
                  {selectedOperatingCompany &&
                    selectedOperatingCompany.id !==
                      operatingCompanyIds.Arkansas && (
                      <span>
                        Fluctuating cost, based on the amount of solar power
                        generated
                      </span>
                    )}

                  {selectedOperatingCompany &&
                    selectedOperatingCompany.id ===
                      operatingCompanyIds.Arkansas && (
                      <span>
                        Fluctuating benefit, based on the amount of solar power
                        generated
                      </span>
                    )}
                </div>
              </div>

              <table>
                <thead>
                  <tr>
                    <th className="row-name">
                      <div className="tablet-visible">
                        What you’ll see on your itemized bill
                      </div>
                    </th>
                    {chosenTypePlans.map(plan => {
                      return (
                        <th key={plan.id}>
                          {plan.name} - {plan.capacityValue} {plan.capacityUnit}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {selectedOperatingCompany &&
                      selectedOperatingCompany.id !==
                        operatingCompanyIds.Arkansas && (
                        <td className="row-name">
                          Community Solar subscription
                        </td>
                      )}

                    {selectedOperatingCompany &&
                      selectedOperatingCompany.id ===
                        operatingCompanyIds.Arkansas && (
                        <td className="row-name">SEPO Subscription Rate</td>
                      )}

                    {chosenTypePlans.map(plan => {
                      return <td key={plan.id}>${plan.price}</td>;
                    })}
                  </tr>

                  <tr>
                    <td className="row-name">
                      Solar farm output
                      <span>
                        Average monthly charge based off of 12-month period
                      </span>
                    </td>
                    {chosenTypePlans.map(plan => {
                      return (
                        <td key={plan.id}>${plan.price - plan.averagePrice}</td>
                      );
                    })}
                  </tr>

                  <tr>
                    <td className="row-name">Net impact on your bill</td>
                    {chosenTypePlans.map(plan => {
                      return (
                        <td className="green" key={plan.id}>
                          ${plan.averagePrice}
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>

              <div className="mobile-table">
                <h2>{customerType}</h2>
                <div className="table">
                  {chosenTypePlans.map(plan => {
                    return (
                      <div key={plan.id}>
                        <div className="th">
                          {plan.name} - {plan.capacityValue} {plan.capacityUnit}
                        </div>
                        <div className="td">
                          {selectedOperatingCompany &&
                            selectedOperatingCompany.id !==
                              operatingCompanyIds.Arkansas && (
                              <span>Community Solar subscription</span>
                            )}
                          {selectedOperatingCompany &&
                            selectedOperatingCompany.id ===
                              operatingCompanyIds.Arkansas && (
                              <span>SEPO Subscription Rate</span>
                            )}
                          <strong>${plan.price}</strong>
                        </div>
                        <div className="td">
                          <span>
                            Solar farm output
                            <span>
                              Average monthly charge based off of 12-month
                              period
                            </span>
                          </span>
                          <strong>${plan.price - plan.averagePrice}</strong>
                        </div>
                        <div className="td">
                          <span>Net impact on your bill</span>
                          <strong className="green">
                            ${plan.averagePrice}
                          </strong>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className="plan-chooser-standalone__notes">
              <img className="notes-icon" src={qMark} alt="" />
              <h2 className="content-heading">A few things to know</h2>

              <ul>
                {selectedOperatingCompany &&
                !selectedOperatingCompany.isEnrollmentEnabled ? (
                  <li>
                    <strong>Your spot, saved</strong>
                    We’ll save some sunshine for you.
                  </li>
                ) : (
                  <li>
                    <strong>Get started anytime</strong>
                    Your subscription will begin on your next billing cycle.
                  </li>
                )}
                <li>
                  <strong>Same bill, new section</strong>
                  Don’t worry, we won’t send you another bill. Your contribution
                  will be included on your monthly Entergy bill.
                </li>
                <li>
                  <strong>Need to cancel?</strong>
                  You can cancel your subscription at any time, you’ll just have
                  to wait one year if you want to rejoin.
                </li>
              </ul>

              <div className="button-link">
                <Link
                  className="btn centered outlined"
                  to={`${process.env.PUBLIC_URL}/faq`}
                >
                  Read our FAQ
                </Link>
              </div>
            </div>
          </div>

          <div className="plan-chooser-standalone__more-options">
            <div className="plan-chooser-standalone__more-options-aligner">
              <div className="text">{this.renderBottomCard({ isTexas })}</div>
              <Link
                className="gs-green-btn"
                to={`${process.env.PUBLIC_URL}/contact-us`}
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPlans: () => dispatch(plansActions.getPlans()),
  updateSelectedPlanType: planType =>
    dispatch(plansActions.updateSelectedPlanType(planType)),
  updateSelectedPlan: plan => dispatch(plansActions.updateSelectedPlan(plan)),
  toggleCustomerType: customerType =>
    dispatch(segmentActions.toggleCustomerType(customerType)),
  selectPlan: (
    subscriptionSize,
    operatingCompanyStatus,
    operatingCompanyName,
    planName,
    customerType,
    startPath
  ) =>
    dispatch(
      segmentActions.selectPlan(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        planName,
        customerType,
        startPath
      )
    ),
  getSelectedOperatingCompany: () =>
    dispatch(operatingCompaniesActions.getSelectedOperatingCompany()),
  updateStartPath: startPath =>
    dispatch(segmentActions.updateStartPath(startPath)),
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

const mapStateToProps = state => ({
  plans: state.plansReducer.plans,
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  selectedPlanType: state.plansReducer.selectedPlanType,
  selectedPlan: state.plansReducer.selectedPlan,
  startPath: state.segmentReducer.startPath
});

export default connect(mapStateToProps, mapDispatchToProps)(PlanSelector);
