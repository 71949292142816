import * as React from "react";
import * as segmentActions from "../../../actions/segmentActions";
import * as topicsActions from "../../../actions/topicsActions";

import {
  CONTACT_US,
  CONTACT_US_FAILURE,
  CONTACT_US_SUCCESS
} from "./../../../actions/types";
import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  emailHasError,
  maxLength,
  minLength,
  nameHasError,
  required
} from "../../../utils/formValidation";
import {
  renderSelectField,
  renderTextAreaField,
  renderTextField
} from "./../../../services/form.service";

import MakeAsyncFunction from "react-redux-promise-listener";
import { connect } from "react-redux";
import { promiseListener } from "./../../../store";

const minLength1 = minLength(1);
const minLength7 = minLength(7);
const minLength10 = minLength(10);
const maxLength25 = maxLength(25);
const maxLength100 = maxLength(100);

const renderSelectTopic = props => {
  const { input, options, currentValue, meta, label } = props;

  if (!input.value) {
    input.onChange(currentValue.value);
  }

  return renderSelectField(label, meta, options, currentValue, true, value =>
    input.onChange(value.value)
  );
};

class ContactUsForm extends React.PureComponent<*> {
  componentDidMount() {
    const { topics, getTopics } = this.props;

    if (!topics) {
      getTopics();
    }
  }

  handleOnSuccess = trackFormSubmitted => {
    const {
      onSuccess,
      selectedOperatingCompany: {
        name: operatingCompanyName,
        isActive: operatingCompanyIsActive
      } = {},
      selectedOperatingCompany,
      topics = [],
      topicId,
      notes
    } = this.props;

    const operatingCompanyStatus =
      operatingCompanyIsActive && selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : !operatingCompanyIsActive
        ? "Inactive"
        : operatingCompanyIsActive && !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    const topic = topics.find(x => x.id === topicId);

    trackFormSubmitted(
      operatingCompanyStatus,
      operatingCompanyName,
      topic ? topic.display : "",
      notes
    );

    onSuccess();
  };

  render() {
    const {
      handleSubmit,
      invalid,
      submitSucceeded,
      pristine,
      submitting,
      topics = [],
      trackFormSubmitted
    } = this.props;

    const topicsOptions = topics.map(t => ({
      value: t.id,
      label: t.display
    }));

    const selectedTopic = topicsOptions.length ? topicsOptions[0] : undefined;

    return (
      <MakeAsyncFunction
        listener={promiseListener}
        start={CONTACT_US}
        resolve={CONTACT_US_SUCCESS}
        reject={CONTACT_US_FAILURE}
      >
        {onSubmit => (
          <form className="main-form" onSubmit={handleSubmit(onSubmit)}>
            <h2>Contact us</h2>

            <div className="main-form__double">
              <Field
                name="firstName"
                type="text"
                maxLength="25"
                component={renderTextField}
                validate={[required, nameHasError, maxLength25, minLength1]}
                label="First Name"
                placeholder="First Name"
              />

              <Field
                name="lastName"
                type="text"
                label="Last Name"
                maxLength="25"
                validate={[required, nameHasError, maxLength25, minLength1]}
                component={renderTextField}
                placeholder="Last Name"
              />
            </div>

            <Field
              name="emailAddress"
              type="text"
              validate={[required, emailHasError, minLength7, maxLength100]}
              component={renderTextField}
              maxLength="100"
              placeholder="example@example.com"
              label="Email Address"
            />

            {selectedTopic ? (
              <Field
                name="topicId"
                label="Topic"
                component={renderSelectTopic}
                options={topicsOptions}
                currentValue={selectedTopic}
              />
            ) : null}

            <Field
              name="notes"
              maxLength="1000"
              label="Notes"
              validate={[required, minLength10]}
              placeholder="Include more information about your request here. 10 character minimum."
              component={renderTextAreaField}
            />

            <button
              className="main-form__btn gs-form-btn block"
              type="submit"
              disabled={invalid || submitting || pristine || submitSucceeded}
            >
              {submitSucceeded ? "Submitted" : "Submit"}
            </button>
            {submitSucceeded && this.handleOnSuccess(trackFormSubmitted)}
          </form>
        )}
      </MakeAsyncFunction>
    );
  }
}

// eslint-disable-next-line no-class-assign
ContactUsForm = reduxForm({
  form: "ContactUs",
  enableReinitialize: true
})(ContactUsForm);

const mapDispatchToProps = dispatch => ({
  getTopics: () => dispatch(topicsActions.getTopics()),

  trackFormSubmitted: (
    operatingCompanyStatus,
    operatingCompanyName,
    topic,
    comment
  ) =>
    dispatch(
      segmentActions.trackFormSubmitted(
        operatingCompanyStatus,
        operatingCompanyName,
        topic,
        comment
      )
    )
});

const mapStateToProps = state => {
  const notes = formValueSelector("ContactUs")(state, "notes");
  const topicId = formValueSelector("ContactUs")(state, "topicId");

  return {
    topics: state.topicsReducer.topics,
    topicId,
    notes,
    selectedOperatingCompany:
      state.operatingCompaniesReducer.selectedOperatingCompany
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsForm);
