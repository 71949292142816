import axios from "axios";

class HttpService {
  constructor(props) {
    this.httpCient = axios.create();
  }

  onError = error => {
    return Promise.reject(error.response);
  };

  onSuccess = response => {
    if (response.data.status !== "error") {
      return Promise.resolve(response.data);
    } else {
      return Promise.reject(response.data.error);
    }
  };

  sendGet = (path, params, baseUrl, token) => {
    const apiPath = process.env.REACT_APP_API_URL;
    const absoluteUrl = baseUrl ? baseUrl + path : apiPath + path;

    let options = {
      url: absoluteUrl,
      method: "get",
      responseType: "json"
    };

    if (params) {
      options.params = params;
    }

    return this.httpCient(options)
      .then(this.onSuccess)
      .catch(this.onError);
  };

  sendPut = (path, payload, baseUrl, token) => {
    const apiPath = process.env.REACT_APP_API_URL;
    const absoluteUrl = baseUrl ? baseUrl + path : apiPath + path;

    let options = {
      url: absoluteUrl,
      method: "put",
      data: payload,
      responseType: "json"
    };

    return this.httpCient(options)
      .then(this.onSuccess)
      .catch(this.onError);
  };

  sendPost = (path, payload, baseUrl, token) => {
    const apiPath = process.env.REACT_APP_API_URL;
    const absoluteUrl = baseUrl ? baseUrl + path : apiPath + path;

    let options = {
      url: absoluteUrl,
      method: "post"
    };

    if (payload) {
      options.data = payload;
      options.responseType = "json";
    }

    if (token) {
      options.headers = { Authorization: "Bearer " + token };
    }

    return this.httpCient(options)
      .then(this.onSuccess)
      .catch(this.onError);
  };

  uploadFile = (path, file, emailAddress) => {
    const apiPath = process.env.REACT_APP_API_URL;
    const absoluteUrl = apiPath + path;

    const formData = new FormData();

    formData.append("file", file);
    formData.append("emailAddress", emailAddress);

    let options = {
      url: absoluteUrl,
      method: "post",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    return this.httpCient(options)
      .then(this.onSuccess)
      .catch(this.onError);
  };
}

export default HttpService;
