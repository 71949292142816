import "./styles.scss";

import * as React from "react";
import * as segmentActions from "./../../actions/segmentActions";

import AnimateHeight from "react-animate-height";
import { Content } from "../../components/Content/Content";
import HaveQuestions from "../../components/HaveQuestions/HaveQuestions";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";

class FAQ extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    this.state = props;
  }

  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed("faq");
    }
  }

  setFAQ(index) {
    const { trackQuestionViewed, selectedOperatingCompany, faq } = this.props;

    const operatingCompanyName = selectedOperatingCompany.name;
    const operatingCompanyStatus =
      selectedOperatingCompany &&
      selectedOperatingCompany.isActive &&
      selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : selectedOperatingCompany && !selectedOperatingCompany.isActive
        ? "Inactive"
        : selectedOperatingCompany &&
          selectedOperatingCompany.isActive &&
          !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    const question = faq[index].question;

    trackQuestionViewed(operatingCompanyStatus, operatingCompanyName, question);

    this.setState({
      chosenFAQIndex: this.state.chosenFAQIndex === index ? null : index
    });
  }

  render() {
    const { faq, selectedOperatingCompany } = this.props;
    const helmet = (
      <Helmet>
        <title>We’re Here To Help With Your Solar FAQs | Solarity</title>
        <description>
          From the reservation process to the impact you will have on the
          environment, we are here to answer any of the questions you have.
          Browse our frequently asked questions or reach out to our team today!
        </description>
      </Helmet>
    );

    if (!selectedOperatingCompany || !faq) {
      return <div>{helmet}</div>;
    }

    return (
      <Content>
        {helmet}
        <div className="inner-header green">
          <div className="inner-header__content">
            <h1>Frequently asked questions</h1>
            <div className="descr">We’re here to help</div>
          </div>
        </div>

        <div className="faq">
          {faq.map((item, index) => {
            const active = index === this.state.chosenFAQIndex;
            let hasUrlLink = false;
            let link = "";
            const isCustomQuestion =
              item.question === "How does billing for SEPO work?";

            let answer = item.answer;

            if (answer.indexOf("<begin link>") > 0) {
              link = answer
                .substr(
                  answer.indexOf("<begin link>"),
                  answer.indexOf("<end link>")
                )
                .replace("<begin link>", "")
                .replace("<end link>", "");

              hasUrlLink = true;

              answer = answer
                .substr(0, answer.indexOf("<begin link>"))
                .replace("<begin link>", "");
            }

            return (
              <div
                key={index}
                className={`faq__item ${active ? "active" : ""}`}
              >
                <div
                  className="faq__heading"
                  onClick={() => this.setFAQ(index)}
                >
                  {item.question}
                  <div className="ico">
                    <span />
                  </div>
                </div>
                <AnimateHeight duration={300} height={active ? "auto" : 0}>
                  {hasUrlLink && hasUrlLink === true && (
                    <div className="answer">
                      {answer} <a href={link}>here</a>
                    </div>
                  )}

                  {!hasUrlLink && !isCustomQuestion && (
                    <div className="answer">{answer}</div>
                  )}
                  {isCustomQuestion && (
                    <div className="answer">
                      <div>
                        You will continue to receive a bill as usual with all
                        applicable rates and riders based on your usage. All
                        other provisions, prices and regulations of Entergy
                        Arkansas’ standard rate schedules at the time of service
                        will apply and are not modified by participation in the
                        solar option.
                      </div>
                      <br />
                      <ul className="sepo-style">
                        <li>
                          A separate line item will appear on your bill if you
                          have signed up to participate.
                        </li>
                        <li>
                          Renewable Energy Credits associated with the block of
                          energy for which you subscribe will be retired on your
                          behalf. For more information on RECs, click{" "}
                          <a href="https://apx.com/"> here</a>.
                        </li>
                        <li>
                          The monthly solar credit or charge is dependent on the
                          calculations from the monthly market price changes
                          through <a href="https://www.misoenergy.org/">MISO</a>{" "}
                          and will be calculated as{" "}
                          <b>
                            Solar Energy x (Solar Energy Rate-MISO Settlement
                            Rate)
                          </b>
                        </li>

                        <li className="sepo-sub-style">
                          Solar Energy = kW block to which you have subscribed.
                        </li>
                        <li className="sepo-sub-style">
                          The Solar Energy Rate = $0.05345 per kWh.
                        </li>
                        <li className="sepo-sub-style">
                          The{" "}
                          <a href="https://www.misoenergy.org/markets-and-operations/settlements/market-settlements/#t=10&p=0&s=&sd=">
                            MISO Market Settlement Rate
                          </a>{" "}
                          = varies monthly depending on the market.
                        </li>
                        <li>
                          The question “How does Solarity impact my electricity
                          bill?” should not appear for SEPO/EAL, just the
                          question of how billing works for SEPO.
                        </li>
                        <li>
                          SEPO questions should be listed at the top of the FAQs
                          page.
                        </li>
                      </ul>
                    </div>
                  )}
                </AnimateHeight>
              </div>
            );
          })}
        </div>

        <HaveQuestions />
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  trackQuestionViewed: (
    operatingCompanyStatus,
    operatingCompanyName,
    question
  ) =>
    dispatch(
      segmentActions.trackQuestionViewed(
        operatingCompanyStatus,
        operatingCompanyName,
        question
      )
    ),
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  faq: state.faqReducer.faq
});

export default connect(mapStateToProps, mapDispatchToProps)(FAQ);
