import "./styles.scss";

import * as React from "react";
import * as sepoBActions from "./../../../actions/sepoBActions";

import { Field, reduxForm } from "redux-form";
import {
  REGISTER_SEPOB_CUSTOMER,
  REGISTER_SEPOB_CUSTOMER_FAILURE,
  REGISTER_SEPOB_CUSTOMER_SUCCESS
} from "./../../../actions/types";
import {
  addressHasError,
  emailHasError,
  maxLength,
  minLength,
  nameHasError,
  phoneNumberHasError,
  required,
  zipcodeHasError
} from "../../../utils/formValidation";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MakeAsyncFunction from "react-redux-promise-listener";
import connect from "react-redux/es/connect/connect";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { isNumeric } from "../../../utils/regex";
import { promiseListener } from "./../../../store";
import { renderTextField } from "./../../../services/form.service";

const maxLength25 = maxLength(25);
const minLength1 = minLength(1);
const minLength7 = minLength(7);
const maxLength100 = maxLength(100);

const taxIdNumberHasError = value => {
  if (value !== undefined) {
    if (isNumeric(value) === false) {
      return "Please enter numbers only";
    } else if (value.length > 9) {
      return "9 digit max";
    } else if (value.length < 9) {
      return "9 digit min";
    }
  }
};

class AccountInformationForm extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    this.state = {
      isAgreementChecked: false
    };
  }

  handleCheckboxChange(event) {
    const checked = event.target.checked;

    this.setState({
      isAgreementChecked: checked
    });
  }

  handleAddTaxIdNumber() {
    const { addAdditionalTaxIdNumber, taxIdNumbers } = this.props;

    const updatedTaxIdNumbers = [...taxIdNumbers, ""];

    addAdditionalTaxIdNumber(updatedTaxIdNumbers);
  }

  render() {
    const {
      handleSubmit,
      invalid,
      // eslint-disable-next-line no-unused-vars
      submitError,
      submitSucceeded,
      onSuccess
    } = this.props;

    const { selectedOperatingCompany } = this.props;
    const { isAgreementChecked } = this.state;
    const { taxIdNumbers } = this.props;
    const selectedOperatingCompanyName = selectedOperatingCompany
      ? selectedOperatingCompany.name
      : "";

    const selectedOperatingCompanyTermsConditionsUrl = selectedOperatingCompany
      ? selectedOperatingCompany.termsConditionsUrl
      : "";

    const additionalTaxIdNumbersContainer = [];

    if (taxIdNumbers && taxIdNumbers.length > 0) {
      for (let i = 0; i < taxIdNumbers.length; i++) {
        additionalTaxIdNumbersContainer.push(
          <div className="gs-form-double-row">
            <div className="div-50-percent" />
            <div className="div-50-percent">
              <div className="additional-tax-id-container">
                <Field
                  name={`taxIdNumbers${i}`}
                  type="text"
                  component={renderTextField}
                  validate={[required, taxIdNumberHasError]}
                  maxLength="9"
                  label="Tax ID"
                  placeholder="9 digits"
                />
              </div>
            </div>
          </div>
        );
      }
    }

    return (
      <MakeAsyncFunction
        listener={promiseListener}
        start={REGISTER_SEPOB_CUSTOMER}
        resolve={REGISTER_SEPOB_CUSTOMER_SUCCESS}
        reject={REGISTER_SEPOB_CUSTOMER_FAILURE}
      >
        {onSubmit => (
          <form className="form-layout" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content gs-form">
              <div className="gs-form-double-row">
                <Field
                  name="firstName"
                  type="text"
                  validate={[nameHasError, maxLength25, minLength1]}
                  component={renderTextField}
                  maxLength="25"
                  label="First Name*"
                  placeholder="First Name"
                />

                <Field
                  name="lastName"
                  label="Last Name*"
                  validate={[nameHasError, maxLength25, minLength1]}
                  maxLength="25"
                  component={renderTextField}
                  placeholder="Last Name"
                />
              </div>

              <div>
                <Field
                  name="companyName"
                  type="text"
                  validate={[nameHasError, minLength1]}
                  component={renderTextField}
                  maxLength="100"
                  label="Business Name*"
                  placeholder="Business Name"
                />
              </div>

              <div className="gs-form-double-row address-row">
                <Field
                  name="address"
                  type="text"
                  validate={[required, addressHasError]}
                  component={renderTextField}
                  maxLength="100"
                  label="Business Address*"
                  placeholder="Street Address, Unit #"
                />

                <Field
                  name="zipcode"
                  type="text"
                  component={renderTextField}
                  validate={[required, zipcodeHasError]}
                  label="Zip Code*"
                  placeholder="5 digits"
                  maxLength="5"
                />
              </div>

              <div>
                <Field
                  name="emailAddress"
                  type="text"
                  validate={[required, emailHasError, minLength7, maxLength100]}
                  component={renderTextField}
                  maxLength="100"
                  label="Email Address*"
                  placeholder="example@example.com"
                />
              </div>

              <div className="gs-form-double-row">
                <Field
                  name="phoneNumber"
                  type="text"
                  component={renderTextField}
                  validate={[required, phoneNumberHasError]}
                  label="Phone Number*"
                  maxLength="10"
                  placeholder="(555) 555-5555"
                />

                <Field
                  name="taxIdNumber"
                  type="text"
                  component={renderTextField}
                  validate={[required, taxIdNumberHasError]}
                  maxLength="9"
                  label="Tax ID"
                  placeholder="9 digits"
                />
              </div>

              {taxIdNumbers && taxIdNumbers.length > 0 && (
                <div>{additionalTaxIdNumbersContainer}</div>
              )}

              <div className="gs-form-double-row">
                <div className="div-50-percent" />
                <div className="div-50-percent">
                  <div className="additional-tax-id-button-container">
                    <button
                      onClick={() => this.handleAddTaxIdNumber()}
                      type="button"
                      className="add-additional-tax-id-button"
                    >
                      <FontAwesomeIcon
                        className="font-awesome-fill font-awesome-icon"
                        icon={faPlus}
                      />
                    </button>
                    <div className="additional-tax-id-button-label">
                      Add more tax IDs
                    </div>
                  </div>
                </div>
              </div>

              <div className="gs-form-checkbox-wrap">
                <input
                  id="agree"
                  name="reviewSubmissionAgreement"
                  className="gs-form-checkbox"
                  type="checkbox"
                  onChange={e => this.handleCheckboxChange(e)}
                />

                <label htmlFor="agree">
                  I have reviewed and understand the terms of the{" "}
                  <a
                    href={selectedOperatingCompanyTermsConditionsUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {selectedOperatingCompanyName}
                  </a>{" "}
                  solar program. I hereby request service and agree to be bound
                  by these terms and conditions. I recognize that subscriptions
                  for SEPO B are on a first-come, first-served basis and that my
                  sign up only represents my place in line for the available
                  capacity.
                </label>
              </div>

              <button
                className="gs-form-btn block next-step-btn"
                type="submit"
                disabled={invalid || !isAgreementChecked}
              >
                Submit
              </button>

              {submitSucceeded && onSuccess()}
            </div>
          </form>
        )}
      </MakeAsyncFunction>
    );
  }
}

// eslint-disable-next-line no-class-assign
AccountInformationForm = reduxForm({
  form: "AccountInformation",
  enableReinitialize: true
})(AccountInformationForm);

const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  taxIdNumbers: state.sepoBReducer.taxIdNumbers
});

const mapDispatchToProps = dispatch => ({
  addAdditionalTaxIdNumber: taxIdNumbers =>
    dispatch(sepoBActions.addAdditionalTaxIdNumber(taxIdNumbers))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountInformationForm);
