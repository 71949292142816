import {
  isEmailValid,
  isPhoneNumberValid,
  isValidName,
  isValidZip
} from "./regex";

import { SubmissionError } from "redux-form";

const required = value => (value ? undefined : "Field Required");

const getValidationErrors = (error = {}, message) => {
  const errors =
    error.data && Array.isArray(error.data.validationErrors)
      ? error.data.validationErrors.reduce((acc, e) => {
          acc[e.field] = e.error;

          return acc;
        }, {})
      : {};

  errors._error = message;

  return new SubmissionError(errors);
};

const nameHasError = value => {
  if (!value) {
    return "Field Required";
  } else if (!isValidName(value)) {
    return "Invalid characters";
  }
};

const emailHasError = value => {
  if (value && isEmailValid(value) === false) {
    return "Please enter a valid email address";
  } else if (!value) {
    return "Field Required";
  }
};

const phoneNumberHasError = value => {
  if (!value) {
    return "Required Field";
  } else if (value && isPhoneNumberValid(value) === false) {
    return "Please enter a valid 10 digit phone number";
  }
};

const zipcodeHasError = value => {
  if (!value) {
    return "Required Field";
  } else if (isValidZip(value) === false) {
    return "Please enter a valid 5 digit zip code";
  }
};

const maxLength = max => value =>
  value && value.length > max ? `${max} character max` : undefined;

const minLength = min => value =>
  value && value.length < min ? `Character min not reached` : undefined;

const addressHasError = value => {
  if (!value) {
    return "Required Field";
  } else if (value.length > 100) {
    return "100 character max";
  } else if (value.length < 1) {
    return "Please enter a valid address";
  }
};

export {
  emailHasError,
  nameHasError,
  phoneNumberHasError,
  zipcodeHasError,
  required,
  getValidationErrors,
  minLength,
  maxLength,
  addressHasError
};
