import "./styles.scss";

import * as React from "react";
import * as sepoBActions from "./../../actions/sepoBActions";

import AccountInformationForm from "./components/AccountInformationForm";
import { Content } from "./../../components/Content/Content";
import SepoBSuccess from "./components/Success";
import { connect } from "react-redux";

class SepoBGetStarted extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    this.state = {
      isSuccess: false
    };
  }

  handleSuccess() {
    this.setState({ isSuccess: true });
  }

  render() {
    const { customer } = this.props;
    const { isSuccess } = this.state;

    if (isSuccess === false) {
      return (
        <Content>
          <div id="SepoBGetStarted" />
          <div className="container">
            <div className="centered-content">
              <h2 className="content-header">SEPO Option B Sign Up</h2>
              <div className="sub-heading">
                Please complete the account information, including Tax IDs, and
                submit your sign up
              </div>
            </div>

            <div className="farms-wrapper">
              <AccountInformationForm
                initialValues={customer}
                onSuccess={() => this.handleSuccess()}
              />

              <div className="test-home-no-farms-card-image">
                <img
                  src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/Texas_Illustration.jpg"
                  alt="Solar Farm Illustration"
                />
              </div>
            </div>
          </div>
        </Content>
      );
    } else {
      return (
        <Content>
          <SepoBSuccess />
        </Content>
      );
    }
  }
}

const mapDispatchToProps = dispatch => ({
  updateActiveStep: activeStep =>
    dispatch(sepoBActions.updateActiveStep(activeStep)),
  registerSepoBCustomer: () => dispatch(sepoBActions.registerSepoBCustomer())
});

const mapStateToProps = state => ({
  activeStepIndex: state.sepoBReducer.activeStepIndex,
  customer: state.sepoBReducer.customer
});

export default connect(mapStateToProps, mapDispatchToProps)(SepoBGetStarted);
