import "./styles.scss";

import * as React from "react";
import * as segmentActions from "../../actions/segmentActions";

import { NavHashLink as Link } from "react-router-hash-link";
import Nav from "../Nav/Nav";
import { connect } from "react-redux";
import entergyLogo from "./../../img/logos/entergy-logo.png";
import operatingCompanyIds from "../../constants/operatingCompanyIds";
import { withRouter } from "react-router-dom";

const startPath = "Footer";

class Footer extends React.PureComponent<*> {
  renderCTAButton = () => {
    const link = {};
    const {
      selectedOperatingCompany,
      location,
      updateStartPath,
      trackJoinNow,
      trackWaitlist
    } = this.props;
    const isSepoB =
      selectedOperatingCompany &&
      selectedOperatingCompany.abbreviation === "EAL-B";
    const isOtherChosen = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Other
    );

    if (!selectedOperatingCompany) {
      return null;
    } else if (isOtherChosen || isSepoB) {
      return (
        <Link className="btn" to="/contact-us">
          Contact Us
        </Link>
      );
    }

    const pathName = location ? location.pathname : "";
    const operatingCompanyName = selectedOperatingCompany.name;
    const operatingCompanyStatus = !selectedOperatingCompany.isActive
      ? "Inactive"
      : selectedOperatingCompany.hasCapacity
      ? "Capacity Available"
      : !selectedOperatingCompany.hasCapacity
      ? "Sold Out"
      : "";

    const trackHandler =
      !selectedOperatingCompany.isWaitlisted &&
      selectedOperatingCompany.hasCapacity
        ? trackJoinNow
        : trackWaitlist;
    const clickHandler = () => {
      trackHandler(operatingCompanyName, operatingCompanyStatus, startPath);
      updateStartPath(startPath);
    };

    if (
      pathName !== "/plans" &&
      pathName !== "/signup" &&
      pathName !== "/join-waitlist"
    ) {
      if (isSepoB === true) {
        link.to = "get-started";
        link.text = "Get Started";
      } else if (selectedOperatingCompany.hasCapacity) {
        link.to = "plans";
        link.text = selectedOperatingCompany.isWaitlisted
          ? "Join Waitlist"
          : selectedOperatingCompany.isEnrollmentEnabled
          ? "Join Now"
          : "Reserve Now";
      } else {
        link.to = "join-waitlist";
        link.text = "Join Waitlist";
      }
    }

    if (link.to) {
      return (
        <Link className="btn" to={link.to} onClick={clickHandler}>
          {link.text}
        </Link>
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      selectedOperatingCompany = {},
      selectedOperatingCompany: { visibility } = {
        visibility: {}
      }
    } = this.props;

    const isOther = !!(
      selectedOperatingCompany.id === operatingCompanyIds.Other
    );

    return (
      <div className="footer">
        <div className="footer__centered">
          <div className="footer__row">
            <div className="footer__col footer__info">
              <h3>Solarity by Entergy</h3>

              <p>We’re on a mission to help you run with solar power.</p>

              <img src={entergyLogo} alt="" className="entergyLogo" />
            </div>

            <div className="footer__col footer__nav">
              <div className="footer__col">
                <Nav visibility={visibility} />
              </div>
            </div>

            <div className="footer__col footer__social">
              {this.renderCTAButton()}
            </div>
          </div>

          <div className="copy">
            {!isOther && <h3>{selectedOperatingCompany.name}</h3>}
            <div className="links">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.entergy.com/privacy-policy/"
              >
                Privacy Policy
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.entergy.com/terms-of-use/"
              >
                Terms of Use
              </a>
            </div>
            <p>
              © 1998-2019 Entergy Corporation, All Rights Reserved. The Entergy
              name and logo are registered service marks of Entergy Corporation
              and may not be used without the express, written consent of
              Entergy Corporation.
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany
});

const mapDispatchToProps = dispatch => ({
  trackJoinNow: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackJoinNow(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  trackWaitlist: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackWaitlist(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  updateStartPath: startPath =>
    dispatch(segmentActions.updateStartPath(startPath))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Footer));
