/* eslint-disable react/prop-types */
import "./styles.scss";

import * as React from "react";
import * as alertActions from "../../actions/alertsActions";
import * as operatingCompaniesActions from "../../actions/operatingCompaniesActions";
import * as segmentActions from "../../actions/segmentActions";

import { NavLink, withRouter } from "react-router-dom";

import Alert from "../Alert/Alert";
import { NavHashLink as Link } from "react-router-hash-link";
import Nav from "../Nav/Nav";
import Select from "react-select";
import { connect } from "react-redux";
import operatingCompanyIds from "../../constants/operatingCompanyIds";

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      menuOpened: false,
      logoUrl: "",
      desktopResolution: false
    };
  }

  componentDidMount() {
    const {
      selectedOperatingCompany,
      operatingCompanies,
      getOperatingCompanies,
      getSelectedOperatingCompany
    } = this.props;

    if (!selectedOperatingCompany) {
      getSelectedOperatingCompany();
    }

    if (!operatingCompanies.length) {
      getOperatingCompanies();
    }

    this.checkWindowResolution();
    window.addEventListener("resize", this.checkWindowResolution);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.checkWindowResolution);
  }

  checkWindowResolution = () => {
    const windowSize = window.innerWidth;
    const desktopResolution = windowSize >= 720;

    this.setState({
      desktopResolution
    });
  };

  closeAlert() {
    const { dismissOtherRegionsAlert } = this.props;

    dismissOtherRegionsAlert();
  }

  toggleHamburgerMenu = () => {
    this.setState({ menuOpened: !this.state.menuOpened });
  };

  updateSelectedOperatingCompany = operatingCompanyId => {
    const {
      updateSelectedOperatingCompany,
      operatingCompanies,
      location,
      history
    } = this.props;
    const selectedOperatingCompany = operatingCompanies.find(item => {
      return item.id === operatingCompanyId;
    });

    updateSelectedOperatingCompany(selectedOperatingCompany);

    if (location && location.search && location.search !== "") {
      const currentPathName = location.pathname ? location.pathname : "";

      history.push({ pathname: currentPathName });
    }
  };

  handleTrackJoinNow = (operatingCompanyName, operatingCompanyStatus) => {
    const { updateStartPath, trackJoinNow } = this.props;

    trackJoinNow(operatingCompanyName, operatingCompanyStatus, "Header");
    updateStartPath("Header");
  };

  handleTrackWaitlist = (operatingCompanyName, operatingCompanyStatus) => {
    const { updateStartPath, trackWaitlist } = this.props;

    trackWaitlist(operatingCompanyName, operatingCompanyStatus, "Header");
    updateStartPath("Header");
  };

  renderCTAButton = () => {
    const { selectedOperatingCompany, location } = this.props;
    const pathName = location ? location.pathname : "";
    const isSepoB =
      selectedOperatingCompany &&
      selectedOperatingCompany.abbreviation === "EAL-B";

    const isTexas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Texas
    );

    const operatingCompanyName = selectedOperatingCompany
      ? selectedOperatingCompany.name
      : "";
    const operatingCompanyStatus =
      selectedOperatingCompany &&
      selectedOperatingCompany.isActive &&
      selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : selectedOperatingCompany && !selectedOperatingCompany.isActive
        ? "Inactive"
        : selectedOperatingCompany &&
          selectedOperatingCompany.isActive &&
          !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    if (isSepoB) {
      return (
        <NavLink
          className={`sign-up ${isSepoB === true ? "sepob-padding" : ""}`}
          to="get-started"
        >
          Get Started
        </NavLink>
      );
    } else if (
      pathName !== "/plans" &&
      pathName !== "/signup" &&
      pathName !== "/join-waitlist" &&
      selectedOperatingCompany &&
      selectedOperatingCompany.name !== "Other" &&
      selectedOperatingCompany.hasCapacity
    ) {
      return (
        <NavLink
          className="sign-up"
          to="plans"
          onClick={() =>
            this.handleTrackJoinNow(
              operatingCompanyName,
              operatingCompanyStatus,
              "Header"
            )
          }
        >
          {isTexas
            ? "Join Waitlist"
            : selectedOperatingCompany.isEnrollmentEnabled
            ? "Join Now"
            : "Reserve Now"}
        </NavLink>
      );
    } else if (
      pathName !== "/plans" &&
      pathName !== "/signup" &&
      pathName !== "/join-waitlist" &&
      selectedOperatingCompany &&
      selectedOperatingCompany.name !== "Other" &&
      !selectedOperatingCompany.hasCapacity
    ) {
      return (
        <NavLink
          className="sign-up"
          to="join-waitlist"
          onClick={() =>
            this.handleTrackWaitlist(
              operatingCompanyName,
              operatingCompanyStatus,
              "Header"
            )
          }
        >
          Join Waitlist
        </NavLink>
      );
    }
  };

  getOptions = (items = [], id, label) => {
    let customItems = [];

    items.forEach(item => {
      if (item.abbreviation !== "EAL-B") {
        customItems.push(item);
      }
    });

    return customItems.map(item => ({
      value: item[id],
      label: item[label] || item.name
    }));
  };

  renderDropdownField = options => {
    const { selectedOperatingCompany } = this.props;

    const val = options.find(
      option => option.value === selectedOperatingCompany.id
    );

    return (
      <div className="site-header__dropdown">
        <Select
          className="custom-select"
          classNamePrefix="select"
          value={val}
          options={options}
          autosize={true}
          isSearchable={false}
          onChange={value => this.updateSelectedOperatingCompany(value.value)}
        />
      </div>
    );
  };

  render() {
    const {
      selectedOperatingCompany,
      selectedOperatingCompany: { visibility } = {
        visibility: {}
      },
      operatingCompanies,
      otherRegionsAlertDismissed,
      history
    } = this.props;
    const { menuOpened, desktopResolution } = this.state;

    const isSepoB =
      selectedOperatingCompany &&
      selectedOperatingCompany.abbreviation === "EAL-B";

    const isOtherChosen = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Other
    );

    let headerClass = "site-header";
    if (menuOpened) {
      headerClass += " open";
    }

    if (history.location.pathname !== "/") {
      headerClass += " inner";
    }

    const operatingCompanyOptions = this.getOptions(
      operatingCompanies,
      "id",
      !desktopResolution ? "state" : "stateFullName"
    );

    const alertVisible = !!(isOtherChosen && !otherRegionsAlertDismissed);

    return (
      <div className={headerClass}>
        <Alert isVisible={alertVisible} onClose={() => this.closeAlert()}>
          <p className="text-5">
            Solarity is currently offered in Mississippi and Texas. For Entergy
            customers in our other service regions, we’re currently working on
            bringing to your community.
          </p>
        </Alert>
        <div className="centered-content">
          <Link
            className={`${
              history.location.pathname !== "/" ? "inner" : ""
            } site-header__logo`}
            to="/"
          >
            {history.location.pathname === "/" &&
            selectedOperatingCompany &&
            operatingCompanies.length ? (
              <div>
                <img
                  className="gs-logo"
                  src={selectedOperatingCompany.desktopLogoUrl}
                  alt=""
                />
                <img
                  className="gs-logo_mobile"
                  src={
                    selectedOperatingCompany &&
                    selectedOperatingCompany.desktopLogoSmallUrl
                  }
                  alt=""
                />
              </div>
            ) : (
              <img
                className="gs-logo"
                src={
                  selectedOperatingCompany &&
                  selectedOperatingCompany.desktopLogoSmallUrl
                }
                alt=""
              />
            )}
          </Link>

          <Nav visibility={visibility} />

          {history.location.pathname === "/" &&
          selectedOperatingCompany &&
          !isSepoB &&
          operatingCompanyOptions.length > 1
            ? this.renderDropdownField(operatingCompanyOptions)
            : null}

          {isOtherChosen || isSepoB ? (
            <NavLink className="sign-up" to="/contact-us">
              Contact Us
            </NavLink>
          ) : (
            this.renderCTAButton()
          )}

          {isSepoB &&
            history.location.pathname === "/" &&
            this.renderCTAButton()}

          <div onClick={this.toggleHamburgerMenu} className="hamburger">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  dismissOtherRegionsAlert: () =>
    dispatch(alertActions.dismissOtherRegionsAlert()),
  getSelectedOperatingCompany: () =>
    dispatch(operatingCompaniesActions.getSelectedOperatingCompany()),
  getOperatingCompanies: () =>
    dispatch(operatingCompaniesActions.getOperatingCompanies()),
  resetSelectedOperatingCompany: () =>
    dispatch(operatingCompaniesActions.resetSelectedOperatingCompany()),
  updateSelectedOperatingCompany: selectedOperatingCompany =>
    dispatch(
      operatingCompaniesActions.updateSelectedOperatingCompany(
        selectedOperatingCompany
      )
    ),
  trackJoinNow: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackJoinNow(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  trackWaitlist: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackWaitlist(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  updateStartPath: startPath =>
    dispatch(segmentActions.updateStartPath(startPath))
});

const mapStateToProps = state => ({
  otherRegionsAlertDismissed: state.alertsReducer.otherRegionsAlertDismissed,
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  operatingCompanies: state.operatingCompaniesReducer.operatingCompanies
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
