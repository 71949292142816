import { GET_FAQ, GET_FAQ_FAILURE, GET_FAQ_SUCCESS } from "./../actions/types";
import { call, put, select, takeEvery } from "redux-saga/effects";

import httpService from "./../services/http.service";

function getFaq(operatingCompanyId, params) {
  return new httpService().sendGet(
    "operating-companies/" + operatingCompanyId + "/faq",
    params
  );
}

function* getFaqAsync(action) {
  try {
    const state = yield select();
    const operatingCompanyId = action.payload
      ? action.payload
      : state.operatingCompaniesReducer.selectedOperatingCompany.id;

    const response = yield call(getFaq, operatingCompanyId);

    yield put({
      type: GET_FAQ_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    yield put({
      type: GET_FAQ_FAILURE,
      payload: error
    });
  }
}

export function* watchGetFaqAsync() {
  yield takeEvery(GET_FAQ, getFaqAsync);
}
