/* eslint-disable react/prop-types */
import * as React from "react";
import "./styles.scss";

export default function Alert(props) {
  const { children, isVisible = true, onClose } = props;
  const className = "tw-bs alert-panel overlay" + (isVisible ? "" : " fade");

  return (
    <div className={className}>
      <div className="close-button" onClick={onClose}>
        <svg height="26" width="26">
          <circle className="close-button-circle" />
          <line x1="8" y1="8" x2="18" y2="18" className="close-button-line" />
          <line x1="18" y1="8" x2="8" y2="18" className="close-button-line" />
        </svg>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-xs col-xl-10 offset-xl-1 message-wrapper">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
