import "./styles.scss";

import * as React from "react";

class HomeVideo extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      videoPlay: false
    };
    this.video = React.createRef();
  }

  playVideo = () => {
    this.setState({ videoPlay: true });
    this.video.current.play();
  };

  getPlayButton = () => {
    return !this.state.videoPlay ? (
      <div className="play-button" onClick={this.playVideo}>
        <span />
      </div>
    ) : null;
  };

  render() {
    return (
      <div className="video-block__wrap" id="video">
        <div className="video-block">
          <video
            ref={this.video}
            controls={this.state.videoPlay}
            poster="https://d1oeu1h51wn2cw.cloudfront.net/desktop/Solarity-Banner-Desktop-1x.png"
          >
            <source
              src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/1440x810_low-compression.mp4"
              type="video/mp4"
            />
          </video>
          {this.getPlayButton()}
        </div>
      </div>
    );
  }
}

export default HomeVideo;
