import "./styles.scss";

import * as React from "react";
import * as segmentActions from "./../../actions/segmentActions";

import { Content } from "../../components/Content/Content";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";
import RegistrationForm from "./components/RegistrationForm";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import { connect } from "react-redux";
import successIcon1 from "../../img/svg/success-form-1.svg";
import successIcon2 from "../../img/svg/success-form-2.svg";
import successIcon3 from "../../img/svg/success-form-3.svg";

class Registration extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    this.state = {
      isSignUpComplete: false
    };
  }

  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed("signup");
    }
  }

  handleSuccess = () => {
    const {
      selectedOperatingCompany,
      selectedPlanType,
      selectedPlan,
      trackSignUp,
      trackJoinWaitlist,
      customer,
      startPath
    } = this.props;

    if (window && window.hj) {
      window.hj("vpv", "signup/confirmation");
    }

    this.setState({ isSignUpComplete: true });

    const subscriptionSize =
      selectedPlan.capacityValue + " " + selectedPlan.capacityUnit;
    const operatingCompanyName = selectedOperatingCompany.name;
    const operatingCompanyStatus =
      selectedOperatingCompany &&
      selectedOperatingCompany.isActive &&
      selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : selectedOperatingCompany && !selectedOperatingCompany.isActive
        ? "Inactive"
        : selectedOperatingCompany &&
          selectedOperatingCompany.isActive &&
          !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    const customerType =
      selectedPlanType === "residential" ? "Residential" : "Commercial";

    if (customer.isWaitlisted !== undefined && customer.isWaitlisted === true) {
      trackJoinWaitlist(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        selectedPlan.name,
        customerType,
        customer.zipcode,
        startPath
      );
    } else {
      trackSignUp(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        selectedPlan.name,
        customerType,
        customer.zipcode,
        startPath
      );
    }

    //scroll to top
    window.scrollTo(0, 0);
  };

  onLowIncomeAgreementChanged = attached => {
    const {
      trackLowIncomeAgreementAttached,
      trackLowIncomeAgreementRemoved,
      selectedPlanType,
      selectedOperatingCompany,
      selectedPlan,
      startPath
    } = this.props;

    const subscriptionSize =
      selectedPlan.capacityValue + " " + selectedPlan.capacityUnit;
    const operatingCompanyName = selectedOperatingCompany.name;
    const operatingCompanyStatus =
      selectedOperatingCompany &&
      selectedOperatingCompany.isActive &&
      selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : selectedOperatingCompany && !selectedOperatingCompany.isActive
        ? "Inactive"
        : selectedOperatingCompany &&
          selectedOperatingCompany.isActive &&
          !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    const customerType =
      selectedPlanType === "residential" ? "Residential" : "Commercial";

    if (attached === true) {
      trackLowIncomeAgreementAttached(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        selectedPlan.name,
        customerType,
        startPath
      );
    } else if (attached === false) {
      trackLowIncomeAgreementRemoved(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        selectedPlan.name,
        customerType,
        startPath
      );
    }
  };

  render() {
    const { isSignUpComplete } = this.state;
    const {
      selectedPlan,
      selectedPlanType,
      selectedOperatingCompany
    } = this.props;

    if (selectedOperatingCompany && !selectedPlan) {
      return <Redirect to="/" />;
    }

    if (!selectedOperatingCompany || !selectedPlan) {
      return <div />;
    }

    const isLowIncomeCapacityAvailable =
      selectedOperatingCompany.hasLowIncomeCapacity &&
      selectedPlan &&
      selectedPlanType &&
      selectedPlanType === "residential"
        ? selectedOperatingCompany.availableLowIncomeCapacity >=
          selectedPlan.capacityValue
        : false;

    if (isSignUpComplete === true) {
      return (
        <Content>
          <SuccessMessage>
            <h1>
              {selectedOperatingCompany &&
              !selectedOperatingCompany.isEnrollmentEnabled
                ? "We’ve saved your spot"
                : "Welcome to Solarity!"}
            </h1>
            <p className="sampled-paragraph">
              Thank you for doing your part to ensure a sustainable future.
            </p>
            <div className="form-success__notes">
              <strong>What happens next:</strong>
              <ul>
                <li>
                  <div className="icon">
                    <img src={successIcon1} alt="" />
                  </div>
                  {selectedOperatingCompany &&
                  !selectedOperatingCompany.isEnrollmentEnabled
                    ? "We’ll send you an email as soon as our solar farms are live."
                    : "We’ll send you an email when you’re all set up."}
                </li>
                <li>
                  <div className="icon">
                    <img src={successIcon3} alt="" />
                  </div>
                  {selectedOperatingCompany &&
                  !selectedOperatingCompany.isEnrollmentEnabled
                    ? "After you enroll, check your monthly bill for the Community Solar subscription."
                    : "Check your next monthly bill — it will include your" +
                      " subscription cost and solar credit."}
                </li>
                <li>
                  <div className="icon">
                    <img src={successIcon2} alt="" />
                  </div>
                  Look out for our monthly emails, where we’ll keep you up to
                  date on your subscription’s impact.
                </li>
              </ul>
            </div>
          </SuccessMessage>
        </Content>
      );
    } else {
      return (
        <Content>
          <Helmet>
            <title>
              Reserve You Spot On Our Community Solar Farms | Solarity
            </title>
            <description>
              We’re on a mission to help you run with solar power. Reserve your
              spot on our solar farms today!
            </description>
          </Helmet>
          <RegistrationForm
            handleLowIncomeAgreementChanged={this.onLowIncomeAgreementChanged}
            isLowIncomeCapacityAvailable={isLowIncomeCapacityAvailable}
            onSuccess={() => this.handleSuccess()}
            onFailire={() => window.scrollTo(0, 0)}
          />
        </Content>
      );
    }
  }
}

const mapStateToProps = state => ({
  customer: state.registrationReducer.customer,
  plans: state.plansReducer.plans,
  selectedPlanType: state.plansReducer.selectedPlanType,
  selectedPlan: state.plansReducer.selectedPlan,
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  startPath: state.segmentReducer.startPath
});

const mapDispatchToProps = dispatch => ({
  trackSignUp: (
    subscriptionSize,
    operatingCompanyStatus,
    operatingCompanyName,
    planName,
    customerType,
    zipcode,
    startPath
  ) =>
    dispatch(
      segmentActions.trackSignUp(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        planName,
        customerType,
        zipcode,
        startPath
      )
    ),
  trackJoinWaitlist: (
    subscriptionSize,
    operatingCompanyStatus,
    operatingCompanyName,
    planName,
    customerType,
    zipcode,
    startPath
  ) =>
    dispatch(
      segmentActions.trackJoinWaitlist(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        planName,
        customerType,
        zipcode,
        startPath
      )
    ),
  trackLowIncomeAgreementAttached: (
    subscriptionSize,
    operatingCompanyStatus,
    operatingCompanyName,
    planName,
    customerType,
    startPath
  ) =>
    dispatch(
      segmentActions.trackLowIncomeAgreementAttached(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        planName,
        customerType,
        startPath
      )
    ),
  trackLowIncomeAgreementRemoved: (
    subscriptionSize,
    operatingCompanyStatus,
    operatingCompanyName,
    planName,
    customerType,
    startPath
  ) =>
    dispatch(
      segmentActions.trackLowIncomeAgreementRemoved(
        subscriptionSize,
        operatingCompanyStatus,
        operatingCompanyName,
        planName,
        customerType,
        startPath
      )
    ),
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
