import * as React from "react";
import * as plansActions from "./../../../actions/plansActions";
import * as registrationActions from "../../../actions/registrationActions";

import { NavHashLink as Link } from "react-router-hash-link";
import { connect } from "react-redux";

export class ChoosePlan extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    props.cancelLowIncome();
    this.state = { activeDropdown: false };
  }

  toggleDropdown = () => {
    this.setState({ activeDropdown: !this.state.activeDropdown });
  };

  handleChangePlan = plan => {
    const { updateSelectedPlan } = this.props;
    const availableCapacity = this.getAvailableCapacity();

    if (plan.capacityValue <= availableCapacity) {
      updateSelectedPlan(plan);
    }

    this.toggleDropdown();
  };

  getAvailableCapacity = () => {
    const {
      selectedOperatingCompany,
      selectedPlanType,
      isLowIncome
    } = this.props;

    if (!selectedOperatingCompany) {
      return 0;
    }

    if (isLowIncome) {
      return selectedOperatingCompany.availableLowIncomeCapacity;
    }

    if (selectedPlanType === "commercial") {
      return selectedOperatingCompany.availableCommercialCapacity;
    } else {
      return selectedOperatingCompany.availableResidentialCapacity;
    }
  };

  renderPlanDropdown = () => {
    const { plans, selectedPlan, selectedPlanType } = this.props;
    const currentPlans = plans
      ? plans.filter(plan => plan.type === selectedPlanType)
      : [];

    const { activeDropdown } = this.state;
    const availableCapacity = this.getAvailableCapacity();

    if (activeDropdown) {
      return (
        <ul className="signup-aside-plan-chooser__list">
          {currentPlans.map((item, index) => {
            const isActive = selectedPlan && selectedPlan.id === item.id;
            const isDisabled =
              item.capacityValue > availableCapacity || availableCapacity <= 0;

            return (
              <li
                key={item.id}
                className={`${isDisabled === true ? "disabled" : ""} ${
                  isActive === true ? "active" : ""
                }`}
                onClick={() => this.handleChangePlan(item)}
              >
                {item.name} {item.capacityValue} {item.capacityUnit}
              </li>
            );
          })}
        </ul>
      );
    }
  };

  handleRemoveLowIncome = () => {
    const { handleLowIncomeAgreementChanged, cancelLowIncome } = this.props;

    handleLowIncomeAgreementChanged(false);
    cancelLowIncome();
  };

  render() {
    const {
      selectedPlan,
      selectedPlanType,
      isLowIncome,
      selectedOperatingCompany
    } = this.props;

    const selectedPlanTypeTitle =
      selectedPlanType === "residential" ? "Residential" : "Business";

    const {
      name = "",
      averagePrice = 0,
      capacityValue = 0,
      capacityUnit = ""
    } = selectedPlan || {};

    return (
      <div className="signup-aside">
        <strong className="signup-aside__title">You’ve Selected</strong>

        <div className="signup-aside__details">
          <div className="signup-aside__business-type">
            {selectedPlanTypeTitle}
          </div>
          <div className="signup-aside__business-type">
            {name} {capacityValue} {capacityUnit}
          </div>
          {selectedOperatingCompany && (
            <strong className="signup-aside__savings">
              ${averagePrice} <span>Avg per month</span>
            </strong>
          )}

          {selectedOperatingCompany && (
            <div className="signup-aside__savings-terms">
              Over a 12-month period
            </div>
          )}

          {isLowIncome && (
            <div className="signup-aside__low-income-badge">
              Low Income Attachment
              <div onClick={this.handleRemoveLowIncome} className="remove">
                Remove
              </div>
            </div>
          )}

          <div className="signup-aside-plan-chooser">
            <div
              onClick={this.toggleDropdown}
              className="signup-aside-plan-chooser__toggler"
            />

            {this.renderPlanDropdown()}
          </div>
        </div>

        <div className="signup-aside__plans">
          <Link to={`${process.env.PUBLIC_URL}/plans`}>Compare options</Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  plans: state.plansReducer.plans,
  selectedPlanType: state.plansReducer.selectedPlanType,
  selectedPlan: state.plansReducer.selectedPlan,
  isLowIncome: state.registrationReducer.isLowIncome,
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany
});

const mapDispatchToProps = dispatch => ({
  updateSelectedPlan: plan => dispatch(plansActions.updateSelectedPlan(plan)),
  cancelLowIncome: () => dispatch(registrationActions.updateLowIncome(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePlan);
