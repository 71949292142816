import {
  watchGetAllPlansAsync,
  watchGetPlansAsync,
  watchUpdateSelectedPlanTypeAsync
} from "./plansSaga";
import {
  watchGetOperatingCompaniesAsync,
  watchGetOperatingCompanyByZipcodeAsync,
  watchGetSelectedOperatingCompany,
  watchUpdateSelectedOperatingCompany
} from "./operatingCompaniesSaga";
import {
  watchJoinWaitingListAsync,
  watchQuickRegisterAsync,
  watchRegisterCustomerAsync
} from "./registrationSaga";
import {
  watchRegisterSepoBCustomerAsync,
  watchUploadFileAsync
} from "./sepoBSaga";

import { fork } from "redux-saga/effects";
import { watchContactUsAsync } from "./contactUsSaga.js";
import { watchGetFaqAsync } from "./faqSaga";
import { watchGetTopicsAsync } from "./topicsSaga.js";

export default function* rootSaga() {
  yield fork(watchRegisterCustomerAsync);
  yield fork(watchGetPlansAsync);
  yield fork(watchGetOperatingCompaniesAsync);
  yield fork(watchUpdateSelectedOperatingCompany);
  yield fork(watchUpdateSelectedPlanTypeAsync);
  yield fork(watchJoinWaitingListAsync);
  yield fork(watchContactUsAsync);
  yield fork(watchGetSelectedOperatingCompany);
  yield fork(watchGetTopicsAsync);
  yield fork(watchQuickRegisterAsync);
  yield fork(watchGetAllPlansAsync);
  yield fork(watchGetFaqAsync);
  yield fork(watchGetOperatingCompanyByZipcodeAsync);
  yield fork(watchUploadFileAsync);
  yield fork(watchRegisterSepoBCustomerAsync);
}
