import {
  GET_ALL_PLANS,
  GET_ALL_PLANS_FAILURE,
  GET_ALL_PLANS_SUCCESS,
  GET_PLANS,
  GET_PLANS_FAILURE,
  GET_PLANS_SUCCESS,
  UPDATE_SELECTED_PLAN,
  UPDATE_SELECTED_PLAN_TYPE,
  UPDATE_SELECTED_PLAN_TYPE_SUCCESS
} from "./../actions/types";
import { call, put, select, takeEvery } from "redux-saga/effects";

import httpService from "./../services/http.service";

function getPlans(operatingCompanyId, params) {
  return new httpService().sendGet(
    "operating-companies/" + operatingCompanyId + "/plans",
    params
  );
}

function* getPlansAsync(action) {
  try {
    const state = yield select();
    const operatingCompanyId = action.payload
      ? action.payload
      : state.operatingCompaniesReducer.selectedOperatingCompany.id;

    const response = yield call(getPlans, operatingCompanyId);

    yield put({
      type: GET_PLANS_SUCCESS,
      payload: response.data
    });

    yield put({
      type: UPDATE_SELECTED_PLAN_TYPE,
      payload: "residential"
    });
  } catch (error) {
    yield put({
      type: GET_PLANS_FAILURE,
      payload: error
    });
  }
}

function* getAllPlansAsync(action) {
  try {
    const state = yield select();
    const operatingCompanyId = action.payload
      ? action.payload
      : state.operatingCompaniesReducer.selectedOperatingCompany.id;

    const response = yield call(getPlans, operatingCompanyId, {
      showAll: true
    });

    yield put({
      type: GET_ALL_PLANS_SUCCESS,
      payload: response.data
    });

    yield put({
      type: UPDATE_SELECTED_PLAN_TYPE,
      payload: "residential"
    });
  } catch (error) {
    yield put({
      type: GET_ALL_PLANS_FAILURE,
      payload: error
    });
  }
}

function* updateSelectedPlanTypeAsync(action) {
  try {
    const selectedPlanType = action.payload;
    const state = yield select();
    const plans = state.plansReducer.plans || [];
    const selectedPlan = plans.filter(
      plan => plan.type === selectedPlanType
    )[0];

    yield put({
      type: UPDATE_SELECTED_PLAN,
      payload: selectedPlan
    });

    yield put({
      type: UPDATE_SELECTED_PLAN_TYPE_SUCCESS,
      payload: selectedPlanType
    });
  } catch (error) {
    yield put({
      type: GET_PLANS_FAILURE,
      payload: error
    });
  }
}

export function* watchGetPlansAsync() {
  yield takeEvery(GET_PLANS, getPlansAsync);
}

export function* watchUpdateSelectedPlanTypeAsync() {
  yield takeEvery(UPDATE_SELECTED_PLAN_TYPE, updateSelectedPlanTypeAsync);
}

export function* watchGetAllPlansAsync() {
  yield takeEvery(GET_ALL_PLANS, getAllPlansAsync);
}
