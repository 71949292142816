import { EventTypes } from "redux-segment";
import { UPDATE_START_PATH } from "./types";

export const updateStartPath = startPath => ({
  type: UPDATE_START_PATH,
  payload: startPath
});

export function trackJoinNow(
  operatingCompanyName,
  operatingCompanyStatus,
  startPath
) {
  return {
    type: "TRACK_JOIN_NOW",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Get Started - Sign Up",
          properties: {
            OpCo: operatingCompanyName,
            "OpCo Status": operatingCompanyStatus,
            StartPath: startPath
          }
        }
      }
    }
  };
}

export function trackWaitlist(
  operatingCompanyName,
  operatingCompanyStatus,
  startPath
) {
  return {
    type: "TRACK_JOIN_WAIT_LIST",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Get Started - Waitlist",
          properties: {
            OpCo: operatingCompanyName,
            "OpCo Status": operatingCompanyStatus,
            StartPath: startPath
          }
        }
      }
    }
  };
}

export function toggleCustomerType(customerType) {
  return {
    type: "TOGGLE_CUSTOMER_TYPE",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Toggle Customer Type",
          properties: {
            "Customer Type": customerType
          }
        }
      }
    }
  };
}

export function selectPlan(
  subscriptionSize,
  operatingCompanyStatus,
  operatingCompanyName,
  planName,
  customerType,
  startPath
) {
  return {
    type: "SELECT_PLAN",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Select Plan",
          properties: {
            OpCo: operatingCompanyName,
            "Subscription Size": subscriptionSize,
            "OpCo Status": operatingCompanyStatus,
            "Selected Plan": planName,
            "Customer Type": customerType,
            StartPath: startPath
          }
        }
      }
    }
  };
}

export function trackSignUp(
  subscriptionSize,
  operatingCompanyStatus,
  operatingCompanyName,
  planName,
  customerType,
  zipcode,
  startPath
) {
  return {
    type: "TRACK_SIGN_UP",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Sign Up",
          properties: {
            OpCo: operatingCompanyName,
            "Subscription Size": subscriptionSize,
            "OpCo Status": operatingCompanyStatus,
            "Selected Plan": planName,
            "Customer Type": customerType,
            Zipcode: zipcode,
            StartPath: startPath
          }
        }
      }
    }
  };
}

export function trackJoinWaitlist(
  operatingCompanyStatus,
  operatingCompanyName,
  customerType,
  zipcode,
  startPath
) {
  return {
    type: "TRACK_WAITLIST",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Join Waitlist",
          properties: {
            OpCo: operatingCompanyName,
            "OpCo Status": operatingCompanyStatus,
            "Customer Type": customerType,
            Zipcode: zipcode,
            StartPath: startPath
          }
        }
      }
    }
  };
}

export function trackQuestionViewed(
  operatingCompanyStatus,
  operatingCompanyName,
  question
) {
  return {
    type: "TRACK_QUESTION_VIEWED",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Question Viewed",
          properties: {
            OpCo: operatingCompanyName,
            "OpCo Status": operatingCompanyStatus,
            Question: question
          }
        }
      }
    }
  };
}

export function trackFormSubmitted(
  operatingCompanyStatus,
  operatingCompanyName,
  topic,
  comment
) {
  return {
    type: "TRACK_FORM_SUBMITTED",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Form Submitted",
          properties: {
            OpCo: operatingCompanyName,
            "OpCo Status": operatingCompanyStatus,
            Comment: comment,
            Topic: topic
          }
        }
      }
    }
  };
}

export function trackLowIncomeAgreementAttached(
  subscriptionSize,
  operatingCompanyStatus,
  operatingCompanyName,
  planName,
  customerType,
  startPath
) {
  return {
    type: "TRACK_LOW_INCOME_AGREEMENT_ATTACHED",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Low Income Attached",
          properties: {
            OpCo: operatingCompanyName,
            "Subscription Size": subscriptionSize,
            "OpCo Status": operatingCompanyStatus,
            "Selected Plan": planName,
            "Customer Type": customerType,
            StartPath: startPath
          }
        }
      }
    }
  };
}

export function trackLowIncomeAgreementRemoved(
  subscriptionSize,
  operatingCompanyStatus,
  operatingCompanyName,
  planName,
  customerType,
  startPath
) {
  return {
    type: "TRACK_LOW_INCOME_AGREEMENT_REMOVED",
    meta: {
      analytics: {
        eventType: EventTypes.track,
        eventPayload: {
          event: "Low Income Removed",
          properties: {
            OpCo: operatingCompanyName,
            "Subscription Size": subscriptionSize,
            "OpCo Status": operatingCompanyStatus,
            "Selected Plan": planName,
            "Customer Type": customerType,
            StartPath: startPath
          }
        }
      }
    }
  };
}

export function trackPageViewed(pageName) {
  return {
    type: "TRACK_PAGE_VIEWED",
    meta: {
      analytics: {
        eventType: EventTypes.page,
        eventPayload: {
          name: pageName
        }
      }
    }
  };
}
