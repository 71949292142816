import {
  CONTACT_US,
  CONTACT_US_FAILURE,
  CONTACT_US_SUCCESS
} from "./../actions/types";
import { call, put, select, takeEvery } from "redux-saga/effects";
import { getValidationErrors } from "./../utils/formValidation";
import httpService from "./../services/http.service";

function contactUs(payload) {
  return new httpService().sendPost("contact-us", payload);
}

function* watchContactUsFormAsync(action) {
  try {
    const state = yield select();
    const selectedOperatingCompany =
      state.operatingCompaniesReducer.selectedOperatingCompany;
    const payload = {
      ...action.payload,
      operatingCompanyId: selectedOperatingCompany
        ? selectedOperatingCompany.id
        : undefined
    };

    const response = yield call(contactUs, payload);

    yield put({
      type: CONTACT_US_SUCCESS,
      payload: response.data
    });
  } catch (error) {
    const errors = error.data.code === 422 ? getValidationErrors(error) : error;

    yield put({
      type: CONTACT_US_FAILURE,
      payload: errors
    });
  }
}

export function* watchContactUsAsync() {
  yield takeEvery(CONTACT_US, watchContactUsFormAsync);
}
