import {
  GET_PLANS,
  UPDATE_SELECTED_PLAN,
  UPDATE_SELECTED_PLAN_TYPE
} from "./types";

export const getPlans = operatingCompanyId => ({
  type: GET_PLANS,
  payload: operatingCompanyId
});

export const updateSelectedPlanType = planType => ({
  type: UPDATE_SELECTED_PLAN_TYPE,
  payload: planType
});

export const updateSelectedPlan = plan => ({
  type: UPDATE_SELECTED_PLAN,
  payload: plan
});
