import {
  ADD_ADDITIONAL_TAX_ID,
  REGISTER_SEPOB_CUSTOMER,
  UPDATE_ACTIVE_STEP,
  UPDATE_FILE1_STATE,
  UPDATE_FILE2_STATE,
  UPLOAD_FILE
} from "./types";

export const updateActiveStep = activeStep => ({
  type: UPDATE_ACTIVE_STEP,
  payload: activeStep
});

export const uploadFile = payload => ({
  type: UPLOAD_FILE,
  payload
});

export const updateFile1State = payload => ({
  type: UPDATE_FILE1_STATE,
  payload
});

export const updateFile2State = payload => ({
  type: UPDATE_FILE2_STATE,
  payload
});

export const registerSepoBCustomer = () => ({
  type: REGISTER_SEPOB_CUSTOMER
});

export const addAdditionalTaxIdNumber = taxIdNumbers => ({
  type: ADD_ADDITIONAL_TAX_ID,
  payload: taxIdNumbers
});
