import * as React from "react";

import { Field, reduxForm } from "redux-form";
import {
  REGISTER_CUSTOMER,
  REGISTER_CUSTOMER_FAILURE,
  REGISTER_CUSTOMER_SUCCESS
} from "./../../../actions/types";
import {
  addressHasError,
  emailHasError,
  maxLength,
  minLength,
  nameHasError,
  phoneNumberHasError,
  required,
  zipcodeHasError
} from "../../../utils/formValidation";

import ChoosePlan from "./ChoosePlan";
import LowIncomeForm from "./LowIncomeForm";
import MakeAsyncFunction from "react-redux-promise-listener";
import ModalDialog from "./../../../components/ModalDialog/ModalDialog";
import connect from "react-redux/es/connect/connect";
import flatMap from "lodash/flatMap";
import { isNumeric } from "../../../utils/regex";
import operatingCompanyIds from "../../../constants/operatingCompanyIds";
import { promiseListener } from "./../../../store";
import { renderTextField } from "./../../../services/form.service";

const maxLength25 = maxLength(25);
const minLength1 = minLength(1);
const minLength7 = minLength(7);
const maxLength100 = maxLength(100);

const accountNumberHasError = value => {
  if (value !== undefined) {
    if (isNumeric(value) === false) {
      return "Please enter numbers only";
    } else if (value.length > 12) {
      return "12 digit max";
    } else if (value.length < 8) {
      return "8 digit min";
    }
  }
};

const handleFailure = (errors = {}) => {
  const fieldIds = Object.keys(errors);
  const fields = flatMap(fieldIds, id => document.getElementById(id) || []);

  if (fields.length) {
    fields.sort((a, b) => b.offsetTop - a.offsetTop);
    const firstField = fields[0];

    firstField.scrollIntoView({
      behavior: "smooth"
    });
  } else {
    window.scrollTo(0, 0);
  }
};

class RegistrationForm extends React.PureComponent<*> {
  constructor(props) {
    super(props);

    this.state = {
      activeDropdown: false,
      isLowIncomeModalVisible: false
    };
  }

  renderAgreementCheckbox = field => {
    const { selectedOperatingCompany } = this.props;

    return (
      <div className="gs-form-checkbox-wrap">
        {field.meta.touched && field.meta.error && (
          <span className="gs-form-error">{field.meta.error}</span>
        )}
        <input
          id="agree"
          className="gs-form-checkbox"
          {...field.input}
          placeholder={field.label}
          type={field.type}
        />
        <label htmlFor="agree">
          I have reviewed and understand the terms of the{" "}
          <a
            href={selectedOperatingCompany.termsConditionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {selectedOperatingCompany.name}
          </a>{" "}
          solar program. I hereby request service and agree to be bound by these
          terms and conditions.
        </label>
      </div>
    );
  };

  toggleDropdown = () => {
    this.setState({ activeDropdown: !this.state.activeDropdown });
  };

  hideLowIncomeModal = () => {
    this.setState({ isLowIncomeModalVisible: false });
  };

  showLowIncomeModal = () => {
    this.setState({ isLowIncomeModalVisible: true });
  };

  getModalContent = () => {
    const { handleLowIncomeAgreementChanged } = this.props;

    return (
      <LowIncomeForm
        handleLowIncomeAgreementChanged={handleLowIncomeAgreementChanged}
        handleClose={() => this.hideLowIncomeModal()}
      />
    );
  };

  renderHeadline() {
    const { selectedOperatingCompany } = this.props;
    const isTexas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Texas
    );

    if (isTexas) {
      return (
        <p className="sampled-paragraph">
          Please fill out the information below to join the waitlist.
          <span className="small">
            We'll reach out to you with ongoing solar farm updates.
          </span>
        </p>
      );
    } else {
      if (
        selectedOperatingCompany &&
        !selectedOperatingCompany.isEnrollmentEnabled
      ) {
        return (
          <p className="sampled-paragraph">
            Please fill out the account information below to reserve your spot.
            <span className="small">
              We'll reach out to you when Solarity is active, to confirm your
              subscription.
            </span>
          </p>
        );
      } else {
        return (
          <p className="sampled-paragraph">
            Please fill out the account information below to be a part of
            Solarity.
          </p>
        );
      }
    }
  }

  render() {
    const {
      handleSubmit,
      invalid,
      // eslint-disable-next-line no-unused-vars
      submitError,
      submitSucceeded,
      pristine,
      submitting,
      onSuccess,
      selectedPlanType,
      isLowIncome,
      isLowIncomeCapacityAvailable,
      selectedOperatingCompany,
      handleLowIncomeAgreementChanged
    } = this.props;

    const { isLowIncomeModalVisible } = this.state;
    const modalContent =
      isLowIncomeModalVisible && isLowIncomeModalVisible === true
        ? this.getModalContent()
        : [];

    const isTexas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Texas
    );

    const isLowIncomeVisible =
      !isTexas &&
      selectedPlanType === "residential" &&
      isLowIncomeCapacityAvailable === true;

    return (
      <MakeAsyncFunction
        listener={promiseListener}
        start={REGISTER_CUSTOMER}
        resolve={REGISTER_CUSTOMER_SUCCESS}
        reject={REGISTER_CUSTOMER_FAILURE}
      >
        {onSubmit => (
          <form className="form-layout" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-content gs-form">
              <h1>You’re almost there!</h1>
              {this.renderHeadline()}
              <div className="gs-form-double-row">
                <Field
                  name="firstName"
                  type="text"
                  validate={[nameHasError, maxLength25, minLength1]}
                  component={renderTextField}
                  maxLength="25"
                  label="First Name"
                  placeholder="First Name"
                />

                <Field
                  name="lastName"
                  label="Last Name"
                  validate={[nameHasError, maxLength25, minLength1]}
                  maxLength="25"
                  component={renderTextField}
                  placeholder="Last Name"
                />
              </div>

              {selectedPlanType === "commercial" && (
                <div>
                  <Field
                    name="companyName"
                    type="text"
                    validate={[required, maxLength100, minLength1]}
                    component={renderTextField}
                    maxLength="100"
                    label="Business Name"
                    placeholder="Business Name"
                  />
                </div>
              )}

              <div className="gs-form-double-row address-row">
                <Field
                  name="address"
                  type="text"
                  component={renderTextField}
                  maxLength="100"
                  validate={[required, addressHasError]}
                  label={
                    selectedPlanType === "commercial"
                      ? "Address of Business"
                      : "Residence"
                  }
                  placeholder="Street Address, Unit #"
                />

                <Field
                  name="zipcode"
                  type="text"
                  validate={[required, zipcodeHasError]}
                  component={renderTextField}
                  label="Zip Code"
                  placeholder="5 digits"
                  maxLength="5"
                />
              </div>

              <Field
                name="emailAddress"
                type="text"
                validate={[required, emailHasError, minLength7, maxLength100]}
                component={renderTextField}
                label="Email Address"
                maxLength="100"
                placeholder="example@example.com"
              />

              <div className="gs-form-double-row">
                <Field
                  name="phoneNumber"
                  type="text"
                  validate={[required, phoneNumberHasError]}
                  component={renderTextField}
                  label="Phone Number"
                  maxLength="10"
                  placeholder="10 digits"
                />

                <Field
                  name="entergyAccountNumber"
                  type="text"
                  component={renderTextField}
                  validate={[accountNumberHasError]}
                  maxLength="12"
                  label="Entergy Account Number (Optional)"
                  placeholder="8-12 digits"
                />
              </div>

              {selectedOperatingCompany !== undefined &&
                selectedOperatingCompany.termsConditionsUrl && (
                  <Field
                    name="agree"
                    type="checkbox"
                    validate={required}
                    component={this.renderAgreementCheckbox}
                  />
                )}

              {isLowIncomeVisible && (
                <div className="low-income-container">
                  <button
                    type="button"
                    disabled={invalid || submitting || pristine || isLowIncome}
                    className="low-income-button"
                    onClick={() => this.showLowIncomeModal()}
                  >
                    {isLowIncome ? "Applied" : "Apply Here"}
                  </button>
                  <div className="low-income-label-container">
                    {isLowIncome ? (
                      <span>
                        We will contact you with more details about your low
                        income benefits.
                      </span>
                    ) : (
                      <span>
                        Think you might qualify for low-income benefits?
                      </span>
                    )}
                  </div>
                </div>
              )}

              {submitSucceeded && onSuccess()}
            </div>

            <div className="form-aside">
              <div>
                <ChoosePlan
                  handleLowIncomeAgreementChanged={
                    handleLowIncomeAgreementChanged
                  }
                />

                <button
                  className="gs-form-btn block"
                  type="submit"
                  disabled={invalid || submitting || pristine}
                >
                  {isTexas
                    ? "Join Waitlist"
                    : selectedOperatingCompany &&
                      !selectedOperatingCompany.isEnrollmentEnabled
                    ? "Reserve Now"
                    : "Join Now"}
                </button>
                <ModalDialog
                  key="modal-dialog"
                  isOpen={isLowIncomeModalVisible}
                  closeHandler={() => this.hideLowIncomeModal()}
                  contentElement={modalContent}
                />
              </div>
            </div>
          </form>
        )}
      </MakeAsyncFunction>
    );
  }
}

// eslint-disable-next-line no-class-assign
RegistrationForm = reduxForm({
  form: "Registration",
  enableReinitialize: true,
  onSubmitFail: handleFailure
})(RegistrationForm);

const mapStateToProps = state => ({
  selectedPlanType: state.plansReducer.selectedPlanType,
  isLowIncome: state.registrationReducer.isLowIncome,
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany
});

export default connect(mapStateToProps)(RegistrationForm);
