import * as React from "react";

class SuccessMessage extends React.PureComponent<*> {
  shareSocial = network => {
    const shareLocation = window.location.origin;
    let shareURL;

    if (network === "facebook") {
      shareURL =
        "https://www.facebook.com/sharer.php?t=I%20just%20subscribed%20to%20solar%20power%20from%20Solarity!%20%23solarity%20%23entergy&u=" +
        shareLocation;
    } else if (network === "twitter") {
      shareURL =
        "https://twitter.com/intent/tweet?text=I%20just%20subscribed%20to%20solar%20power%20from%20Solarity!%20%23solarity%20%23entergy&url=" +
        shareLocation;
    }

    window.open(
      shareURL,
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  render() {
    return (
      <div className="form-success">
        <form className="form-layout">
          <div className="form-content gs-form">{this.props.children}</div>

          <div className="form-aside">
            <div>
              <div className="form-success__share">
                <h3>Share the sun with your community.</h3>
                <p>
                  Tell your community about how you’re making an impact with
                  Solarity.
                </p>
                <div className="links">
                  <button
                    type="button"
                    onClick={() => this.shareSocial("twitter")}
                    className="gs-green-btn blocked twitter"
                  >
                    Share on Twitter
                  </button>
                  <button
                    type="button"
                    onClick={() => this.shareSocial("facebook")}
                    className="gs-green-btn blocked facebook"
                  >
                    Share on Facebook
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default SuccessMessage;
