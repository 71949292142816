import "./styles.scss";

import * as React from "react";
import { NavHashLink as Link } from "react-router-hash-link";

class HaveQuestions extends React.PureComponent<*> {
  render() {
    return (
      <div className="have-questions">
        <div className="have-questions__text">
          <h3>Still have questions?</h3>
          <p>Submit your question or comment directly to our team.</p>
        </div>
        <Link className="btn" to={`${process.env.PUBLIC_URL}/contact-us`}>
          Contact Us
        </Link>
      </div>
    );
  }
}

export default HaveQuestions;
