import "./styles.scss";

import * as React from "react";
import * as segmentActions from "./../../actions/segmentActions";

import { Content } from "../../components/Content/Content";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";

class WhyGreenSky extends React.PureComponent<*> {
  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed("why-solarity");
    }
  }

  render() {
    return (
      <Content className="why-greensky">
        <Helmet>
          <title>Choose Solarity For Community Solar | Solarity</title>
          <description>
            Whether you own or rent, live in a condo or a house, want to power
            your home or your business, we set out to make solar power available
            to anyone who wants to use it.
          </description>
        </Helmet>
        <header className="inner-header dark">
          <div className="inner-header__content">
            <h1>
              Building a clean, renewable future — one community at a time.
            </h1>
          </div>
        </header>

        <div className="why-greensky__content">
          <div className="text-block centered-content">
            <h2>Our story</h2>

            <p>
              At Entergy, we’ve always been committed to investing in a more
              sustainable future, and our customers have too. But renewable
              energy can be intimidating, and most solar power solutions come
              with a laundry list of requirements, like home ownership, roof
              access, and expensive installations.
            </p>

            <p>
              That’s why we created Solarity. We set out to make solar power
              available to anyone who wants to use it — whether you own or rent,
              live in a condo or a house, want to power your home or your
              business. By simply subscribing to a plan, you are doing your part
              for the environment, adding sustainable solar power to the grid.
            </p>

            <p> Together, we’ll lead the way to a greener, cleaner future.</p>
          </div>

          <div className="why-greensky__promise">
            <div className="text-block centered-content">
              <h2>Our promise</h2>

              <p>
                For nearly two decades, Entergy has advocated for national
                action on issues impacting our climate, and we know we play an
                important role in driving change. By 2030, Entergy will emit 50
                percent less carbon dioxide for every unit of electricity than
                we did in 2000. We’re committed to evolving our services to
                support our environment, and community solar is our next step
                towards that goal.
              </p>
            </div>
          </div>

          <div className="why-greensky__table">
            <div className="why-greensky__table-centered">
              <h2>Making it simple</h2>
              <p className="paragraph">
                We want to break the traditional barriers that come with rooftop
                solar.
              </p>
              <div className="why-greensky__table-bordered">
                <div className="why-greensky__table-wrap title">
                  <div className="why-greensky__table-grid-1" />
                  <div className="why-greensky__table-grid-2">
                    <div>Rooftop Solar</div>
                  </div>
                  <div className="why-greensky__table-grid-3">
                    <div>Community Solar</div>
                  </div>
                </div>
                <div className="why-greensky__table-wrap">
                  <div className="why-greensky__table-grid-1">
                    <div>Installation and maintenance</div>
                  </div>
                  <div className="why-greensky__table-grid-2">
                    <div>Rooftop installation, maintenance fees</div>
                  </div>
                  <div className="why-greensky__table-grid-3">
                    <div>No installation, no maintenance fees</div>
                  </div>
                </div>
                <div className="why-greensky__table-wrap">
                  <div className="why-greensky__table-grid-1">
                    <div>Flexibility</div>
                  </div>
                  <div className="why-greensky__table-grid-2 ">
                    <div>Moving means reinstalling your solar panels</div>
                  </div>
                  <div className="why-greensky__table-grid-3 ">
                    <div>
                      No matter where you move, your solar access remains
                    </div>
                  </div>
                </div>
                <div className="why-greensky__table-wrap">
                  <div className="why-greensky__table-grid-1">
                    <div>Expertise and access</div>
                  </div>
                  <div className="why-greensky__table-grid-2">
                    <div>Many third party companies, new bills</div>
                  </div>
                  <div className="why-greensky__table-grid-3">
                    <div>Entergy is an industry leader in energy</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

export default connect(undefined, mapDispatchToProps)(WhyGreenSky);
