import * as React from "react";
import * as registrationActions from "./../../../actions/registrationActions";

import { connect } from "react-redux";
import LowIncomeCheckboxes from "../../../components/LowIncomeCheckboxes/LowIncomeCheckboxes";

class LowIncomeForm extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    this.state = {
      isIncomeLessThanMinimum: false,
      isPrimaryAccountHolder: false,
      lowIncomeBenefitsAddrForPrimaryResidenceOnly: false,
      confirm: false
    };
  }

  handleOnClose = () => {
    const {
      handleClose,
      updateLowIncome,
      isLowIncome,
      handleLowIncomeAgreementChanged
    } = this.props;
    const {
      isIncomeLessThanMinimum,
      isPrimaryAccountHolder,
      lowIncomeBenefitsAddrForPrimaryResidenceOnly,
      confirm
    } = this.state;

    const lowIncome =
      isIncomeLessThanMinimum &&
      isPrimaryAccountHolder &&
      lowIncomeBenefitsAddrForPrimaryResidenceOnly &&
      confirm;

    if (lowIncome !== isLowIncome) {
      handleLowIncomeAgreementChanged(lowIncome);
    }

    updateLowIncome(lowIncome);

    handleClose();
  };

  confirmLowIncomeCondition = (e, type) => {
    this.setState({
      [type]: e.nativeEvent.target.checked
    });
  };

  render() {
    const {
      isIncomeLessThanMinimum,
      isPrimaryAccountHolder,
      lowIncomeBenefitsAddrForPrimaryResidenceOnly,
      confirm
    } = this.state;

    const buttonEnabled =
      isIncomeLessThanMinimum &&
      isPrimaryAccountHolder &&
      lowIncomeBenefitsAddrForPrimaryResidenceOnly &&
      confirm;

    return (
      <div className="low-income-chooser">
        <LowIncomeCheckboxes
          changeCheckboxValue={this.confirmLowIncomeCondition}
        />

        <div>
          <button
            className="gs-green-btn blocked"
            disabled={!buttonEnabled}
            onClick={() => this.handleOnClose()}
          >
            Confirm
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isLowIncome: state.registrationReducer.isLowIncome
});

const mapDispatchToProps = dispatch => ({
  updateLowIncome: isLowIncome =>
    dispatch(registrationActions.updateLowIncome(isLowIncome))
});

export default connect(mapStateToProps, mapDispatchToProps)(LowIncomeForm);
