import "./styles.scss";

import * as React from "react";

import Footer from "./../../components/Footer/Footer";
import Head from "./../../components/Head/Head";
import Header from "./../../components/Header/Header";
import { withRouter } from "react-router-dom";

class Content extends React.PureComponent<*> {
  render() {
    const wrapperClassName = this.props.wrapperClassName || "content-wrapper";
    const isHeaderVisible = this.props.isHeaderVisible !== false;
    const isFooterVisible = this.props.isFooterVisible !== false;

    return (
      <div className={this.props.className}>
        <Head />
        {isHeaderVisible && <Header />}
        <div className={wrapperClassName}>{this.props.children}</div>
        {isFooterVisible && <Footer />}
      </div>
    );
  }
}

const ContentWithRouter = withRouter(Content);

export { ContentWithRouter as Content };
