import * as operatingCompaniesActions from "./../../actions/operatingCompaniesActions";

import React, { Component } from "react";

import { connect } from "react-redux";

export default ChildComponent => {
  class PrivateRoute extends Component<*> {
    constructor(props) {
      super(props);

      this.state = {};
    }

    static getDerivedStateFromProps(nextProps, state) {
      const {
        selectedOperatingCompany,
        getSelectedOperatingCompany,
        checkedForSelectedOperatingCompany,
        updateSelectedOperatingCompany,
        resetSelectedOperatingCompany,
        operatingCompanies,
        location,
        getOperatingCompanies
      } = nextProps;

      if (operatingCompanies !== undefined && operatingCompanies.length > 0) {
        if (nextProps.location && nextProps.location.pathname === "/sepo-b") {
          const operatingCompany = operatingCompanies.find(
            x => x.abbreviation === "EAL-B"
          );

          if (operatingCompany) {
            if (!selectedOperatingCompany) {
              updateSelectedOperatingCompany(operatingCompany, true);

              nextProps.history.push({ pathname: "/" });
            } else if (selectedOperatingCompany.id !== operatingCompany.id) {
              updateSelectedOperatingCompany(operatingCompany, true);

              nextProps.history.push({ pathname: "/" });
            }
          } else {
            resetSelectedOperatingCompany(true);
          }
        } else if (
          nextProps.match &&
          nextProps.match.params &&
          nextProps.match.params.opCo
        ) {
          const operatingCompanyStateName = nextProps.match.params.opCo;

          const operatingCompany = operatingCompanies.find(
            x =>
              x.stateFullName &&
              x.stateFullName.toLowerCase() === operatingCompanyStateName
          );

          if (operatingCompany) {
            if (
              !selectedOperatingCompany ||
              selectedOperatingCompany.id !== operatingCompany.id
            ) {
              updateSelectedOperatingCompany(operatingCompany, true);
            }
          }
        } else if (
          location.search !== undefined &&
          location.search !== null &&
          location.search !== ""
        ) {
          const search = location.search.toLowerCase().replace("?", "");

          const operatingCompany =
            search === "other"
              ? operatingCompanies.find(x => x.name === "Other")
              : operatingCompanies.find(
                  x =>
                    x.stateFullName &&
                    search.indexOf(x.stateFullName.toLowerCase()) >= 0
                );

          if (operatingCompany) {
            if (!selectedOperatingCompany) {
              updateSelectedOperatingCompany(operatingCompany, true);
            } else if (selectedOperatingCompany.id !== operatingCompany.id) {
              updateSelectedOperatingCompany(operatingCompany, true);
            }
          } else {
            resetSelectedOperatingCompany(true);
          }
        } else {
          if (
            !checkedForSelectedOperatingCompany &&
            !selectedOperatingCompany
          ) {
            getSelectedOperatingCompany();
          } else if (
            checkedForSelectedOperatingCompany &&
            !selectedOperatingCompany
          ) {
            if (nextProps.location && nextProps.location.pathname !== "/") {
              nextProps.history.push({ pathname: "/" });
            }
          } else if (
            checkedForSelectedOperatingCompany &&
            selectedOperatingCompany &&
            selectedOperatingCompany.name === "Other"
          ) {
            if (
              nextProps.location &&
              !(
                nextProps.location.pathname === "/" ||
                nextProps.location.pathname === "/about-us" ||
                nextProps.location.pathname === "/contact-us" ||
                nextProps.location.pathname === "/why-solarity"
              )
            ) {
              nextProps.history.push({ pathname: "/" });
            }
          }
        }
      } else {
        getOperatingCompanies();
      }

      return state;
    }

    render() {
      return <ChildComponent {...this.props} />;
    }
  }

  const mapStateToProps = state => {
    return {
      selectedOperatingCompany:
        state.operatingCompaniesReducer.selectedOperatingCompany,
      operatingCompanies: state.operatingCompaniesReducer.operatingCompanies,
      checkedForSelectedOperatingCompany:
        state.operatingCompaniesReducer.checkedForSelectedOperatingCompany
    };
  };

  const mapDispatchToProps = dispatch => ({
    getSelectedOperatingCompany: () =>
      dispatch(operatingCompaniesActions.getSelectedOperatingCompany()),
    getOperatingCompanies: () =>
      dispatch(operatingCompaniesActions.getOperatingCompanies()),
    updateSelectedOperatingCompany: selectedOperatingCompany =>
      dispatch(
        operatingCompaniesActions.updateSelectedOperatingCompany(
          selectedOperatingCompany
        )
      ),
    resetSelectedOperatingCompany: persistSelection =>
      dispatch(
        operatingCompaniesActions.resetSelectedOperatingCompany(
          persistSelection
        )
      )
  });

  return connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
};
