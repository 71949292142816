/* eslint-disable react/prop-types */
import * as React from "react";
import { NavHashLink as Link } from "react-router-hash-link";

export default function Nav({ visibility }) {
  return (
    <nav>
      {visibility.aboutUs && <Link to="about-us">About Community Solar</Link>}
      {visibility.whySolarity && <Link to="why-solarity">Why Solarity</Link>}
      {visibility.plans && <Link to="plans">Choose Your Impact</Link>}
      {visibility.solarFarms && <Link to="our-farms">Our Solar Farms</Link>}
      {visibility.faq && <Link to="faq">FAQ</Link>}
      {visibility.contactUs && <Link to="contact-us">Contact Us</Link>}
    </nav>
  );
}
