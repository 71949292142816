import "./styles.scss";

import * as React from "react";

import { NavHashLink as Link } from "react-router-hash-link";

class FarmsHomeCTA extends React.PureComponent {
  render() {
    return (
      <div className="farms-images-wrap">
        <div className="centered-content">
          <div className="farms-images">
            <div className="farms-images-description">
              <h3>Our solar farms</h3>
              <p>
                When you subscribe, you invest in a solar farm within your
                state. See our locations to find a farm near you.
              </p>
              <Link
                to={`${process.env.PUBLIC_URL}/our-farms`}
                className="btn black"
              >
                Learn More
              </Link>
            </div>

            <div className="farms-images-image">
              <img
                src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/HomePage-Farms-Desktop-1x.jpg"
                srcSet="https://d1oeu1h51wn2cw.cloudfront.net/desktop/HomePage-Farms-Desktop-2x.jpg 2x"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FarmsHomeCTA;
