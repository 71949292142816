import { DISMISS_OTHER_REGIONS_ALERT } from "./../actions/types";

const initialState = {
  otherRegionsAlertDismissed: false
};

const alertsReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISMISS_OTHER_REGIONS_ALERT: {
      return {
        ...state,
        otherRegionsAlertDismissed: true
      };
    }
    default: {
      return state;
    }
  }
};

export default alertsReducer;
