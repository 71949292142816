import {
  JOIN_WAITING_LIST_SUCCESS,
  REGISTER_CUSTOMER_SUCCESS,
  UPDATE_LOW_INCOME
} from "./../actions/types";

const initialState = {
  customer: undefined,
  isLowIncome: false
};

const registrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case JOIN_WAITING_LIST_SUCCESS:
    case REGISTER_CUSTOMER_SUCCESS: {
      const customer = action.payload;

      return {
        ...state,
        customer
      };
    }
    case UPDATE_LOW_INCOME: {
      return {
        ...state,
        isLowIncome: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export default registrationReducer;
