import "./styles.scss";

import * as React from "react";
import * as segmentActions from "./../../actions/segmentActions";

import { Content } from "../../components/Content/Content";
import { connect } from "react-redux";

class TermsAndPolicy extends React.PureComponent<*> {
  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed("terms-and-privacy");
    }
  }

  render() {
    return (
      <Content className="terms-and-privacy">
        <div className="inner-header dark">
          <div className="inner-header__content">
            <h1 className="no-m-b">General Terms, Conditions, and Policies </h1>
          </div>
        </div>

        <div className="content centered-content">
          <p>
            General Terms, Conditions, and Policies for the Use of Website and
            Web Services
          </p>

          <p>
            Welcome. You are viewing the Website of Entergy Corporation and its
            affiliated companies. These terms, conditions, and policies, and
            those referred to below, govern your use of this Website and the Web
            Services (hereinafter defined) provided through this Website. Please
            read them carefully. Your use of this Website and any of the Web
            Services constitutes your agreement to abide and be bound by these
            terms, conditions, and policies (herein collectively referred to as
            the "Agreement"). The Agreement also extends to any email sent by a
            user to Entergy at any time arising out of transactions involving
            this Website or the Web Services. You must comply with the Agreement
            and any applicable user information provided by or to you in
            connection with each use of this Website and any Web Services
            provided through this Website. Any use of this Website or the Web
            Services contrary to the Agreement, as in effect at the time of such
            use, is unauthorized and prohibited. Entergy reserves the right, in
            its sole discretion, at any time, for any reason, and without prior
            notice to any user, to revise the terms, conditions, and policies
            applicable to the use of this Website. Accordingly, users are
            advised to review this Agreement each time they use this Website or
            the Web Services.
          </p>

          <p>
            Throughout the Agreement, the words "user," "you," "your," and
            "yours" refer to any person or entity using this Website or the Web
            Services, and the words "Entergy," "we," "us," and "ours" refer to
            Entergy Corporation and/or one or more of its affiliated companies,
            as well as any vendor that Entergy, in our sole discretion, may use
            to support this Website or provide Web Services. The term "Website"
            refers to this Entergy website, and the term "Web Services" refers
            to any and all services of any kind available through this Website,
            including but not limited to account access services, paperless bill
            delivery services, and one-time and repetitive bill payment
            services.
          </p>

          <h3>1. ENTERGY AND AFFILIATES</h3>

          <ol>
            <li>
              Entergy Corporation is a holding company for individual public
              utility companies, among other affiliates. Each of these public
              utility companies and other affiliates is a separate and distinct
              legal entity. Each public utility subsidiary of Entergy
              Corporation has its own service territory, federal and/or state
              utility regulatory regime(s), rate structure(s), and customers. No
              such public utility subsidiary or other affiliate shall be liable
              for the obligations or entitled to the benefits of any other
              public utility subsidiary or affiliate, except as may be expressly
              provided by duly authorized and executed, enforceable written
              agreement. The public utility subsidiaries of Entergy Corporation
              that provide retail electric and/or gas utility services are the
              following:
              <ul>
                <li>Entergy Arkansas, LLC</li>

                <li>Entergy Louisiana, LLC</li>

                <li>Entergy Mississippi, LLC</li>

                <li>Entergy New Orleans, LLC</li>
                <li>Entergy Texas, Inc.</li>
              </ul>
            </li>

            <li>
              Solely for reasons of convenience, the term "Entergy" is used to
              refer individually and collectively to Entergy Corporation and its
              affiliates, including but not limited to the public utility
              companies named above.
            </li>
          </ol>

          <h3>2. OTHER TERMS, CONDITIONS, AND POLICIES</h3>

          <p>
            In addition to this Agreement, there are other terms, conditions,
            and policies applicable to the utilization of particular Web
            Services that are now provided or will in the future be provided,
            such as paper-free billing; online account access; one-time online
            bill payment; recurring bill payments; the establishment, change or
            termination of utility services; and changes of customer account
            profile information. In the event of a conflict between this
            Agreement and any such other terms, conditions, and policies, this
            Agreement shall govern. Access to such other terms, conditions, and
            policies is provided in connection with accessing particular Web
            Services. A user accessing this Website or using such other Web
            Services shall be deemed to have agreed to and be bound by such
            other terms, conditions, and policies (including the Privacy Policy,
            available by clicking here) in addition to this Agreement.
          </p>

          <h3>3. PROPRIETARY RIGHTS; SUBMISSIONS; LAWFUL USE</h3>

          <ol>
            <li>
              This Website and the Web Services are proprietary to Entergy.
              Except as otherwise indicated, all information, text, images,
              logos, designs, graphics, photos, sounds, presentation, layouts,
              icons, documentation, forms, and other materials (herein referred
              to as "Content") on this Website or provided by Entergy for use in
              connection with the Web Services accessed through this Website are
              the subject of copyrights, trademarks, service marks, and/or
              intellectual property or proprietary rights held by or licensed to
              Entergy, including but not limited to the "Entergy" name and its
              associated logo. Content of this Website may not be published,
              copied, distributed, transmitted, modified, exploited, or used in
              the creation of derivative works, in any form or by or to any
              entity (other than Entergy) for any purpose without the prior
              written consent of Entergy; provided, however, that users of this
              Website may download Content in connection with the ordinary
              utilization of the Website and/or the Web Services for use by a
              single individual using a standard Web browser, so long as such
              Content is not modified, altered, deleted, retransmitted,
              published, or otherwise changed and copyright, trademark and/or
              service mark, and other proprietary notices are not removed.
            </li>
            <li>
              Any information, suggestion, idea, graphic, remark, or other
              submission made by a user to Entergy through this Website or in
              connection with the accessing of the Web Services, whether
              submitted by email or otherwise, shall become the exclusive
              property of Entergy and shall not be deemed to be confidential
              except as provided by Entergy's Privacy Policy. Entergy shall be
              entitled to use any such submission and any derivative works
              created therefrom, and any related concepts, ideas, techniques, or
              know-how for any lawful purpose without any obligation to any user
              submitting the same and without any other restriction, permission,
              or compensation. Each user making any such submission thereby
              acknowledges the originality of such submission and accepts
              responsibility for its accuracy, completeness, appropriateness,
              and legality.
            </li>
            <li>
              This Website and the Web Services are provided only for lawful
              use. Any use of this Website or the Web Services for any purpose
              contrary to applicable law is strictly prohibited.
            </li>
          </ol>

          <h3>4. SECURITY AND PRIVACY</h3>

          <ol>
            <li>
              Information transmitted over the Internet and stored on computers
              connected to the Internet can never be perfectly private or
              secure. Entergy shall endeavor to maintain the security and
              privacy of the information it receives in accordance with
              Entergy's Privacy Policy, which you can access by clicking here.
            </li>
            <li>
              Each user shall be responsible for the use, security, and
              safeguarding of such user’s identification, password, Entergy
              account information, and other confidential information. Entergy
              shall have no responsibility or liability for damages resulting
              from the theft or unauthorized use of a user’s identification,
              password, Entergy account information or other confidential
              information, including but not limited to the user’s tax
              identification number, address, telephone number, credit card
              number, or bank account number.
            </li>
          </ol>

          <h3>5. EQUIPMENT AND NETWORK SERVICES</h3>

          <p>
            Each user is responsible for supplying and maintaining all hardware,
            software, access to networks, and communications and other equipment
            necessary for connectivity to gain access to this Website and the
            Web Services. Entergy shall have no responsibility whatsoever with
            respect to the foregoing.
          </p>

          <h3>6. MONITORING</h3>

          <p>
            Entergy retains the right, exercisable in its sole discretion, at
            any time, and from time to time, to monitor, intercept, search, and
            record any and all activities on this system, including the Website
            or in connection with the Web Services, and undertaken through any
            PC or other device connected to the Entergy network. Entergy may
            disclose information, as it deems appropriate, to others such as the
            U.S. Government, to help ensure information security and protect its
            network. Entergy further reserves the right, without the consent of
            or liability to any user or third party, to record, screen, edit,
            curtail, or remove any content on this system, including the Website
            or the Web Services, at any time and for any reason, including
            because it believes such content to be harmful, harassing, abusive,
            offensive, or in violation of this Agreement or any other terms,
            conditions, or policies applicable to this system, the Website, or
            the Web Services. Entergy shall have no liability to any user or
            third party for the performance or non-performance of monitoring or
            other actions taken to protect the Entergy network, or pursuant to
            this Agreement.
          </p>

          <h3>
            In connection with activities conducted on this Website and with
            respect to Web Services Entergy will attempt to send you courtesy
            email confirmations or other email with important information
            relating to your account. Delivery of email may be affected by
            circumstances beyond the control of Entergy, including but not
            limited to incorrect email addresses provided by users, changed
            email addresses as to which Entergy has not been notified, personal
            and Internet spam filters, size filters, timing of the delivery of
            emails, unavailability of emails, or other network problems. Entergy
            assumes no responsibility for the timeliness, deletion, missed
            delivery, or failure of emails due to the foregoing or the failure
            to store data, failure of personalized settings by users or email
            service providers, or any other cause. Users have the responsibility
            to make every effort to allow delivery of email sent by Entergy.
            Entergy has no responsibility for damages or loss due to emails that
            are not received by users for any reason. Each user is responsible
            for reading and responding in a timely and appropriate manner to
            emails sent by Entergy.
          </h3>

          <ol>
            <li>
              THIS WEBSITE, THE WEB SERVICES, AND ANY RELATED DOCUMENTATION ARE
              PROVIDED "AS IS," WITHOUT ANY WARRANTY OF ANY KIND, EITHER EXPRESS
              OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY WARRANTY OF
              MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.
            </li>
            <li>
              The Content of this Website may contain technical, typographical,
              or other errors, inaccuracies, or omissions. Entergy reserves the
              right, in its sole discretion, at any time, and for any reason, to
              make corrections, revisions, and changes to this Website or the
              Web Services, and/or to suspend or terminate the availability of
              this Website or the Web Services to any or all users, without
              prior notice. Entergy makes no warranty and shall have no
              responsibility to any user with respect to any such error,
              inaccuracy, omission, correction, revision, change, suspension, or
              termination.
            </li>
            <li>
              Entergy makes no warranty that this Website or websites accessible
              through this Website are free of destructive computer code such as
              viruses, worms, trojan horses, and the like. Users should take
              their own precautions with respect to such code.
            </li>
            <li>
              Entergy shall have no responsibility and makes no representation,
              warranty, or endorsement with respect to any other website that a
              user may access from this Website or in connection with any Web
              Service. Access to any such other website(s) is provided solely as
              a convenience to users. Any such other website is independent of
              this Website, and Entergy has no control over the content of any
              such other website. Users accessing other websites linked to this
              Website do so entirely at their own risk, and are subject to such
              terms and conditions as are imposed by such other linked websites.
            </li>
          </ol>

          <h3>9. WEBSITE UNAVAILABILITY; FAILURE OF ONLINE TRANSACTIONS</h3>

          <p>
            The unavailability of this Website or any Web Services, or the
            failure of any Web Service or online transaction for any reason,
            shall not relieve any utility customer or user from, or excuse the
            delay in the payment or performance of, any obligation owed to
            Entergy for or in connection with utility services that have been
            rendered or otherwise.
          </p>

          <h3>10. LIMITATIONS OF LIABILITY</h3>

          <p>
            IN NO EVENT WILL ENTERGY BE LIABLE TO ANY PARTY FOR ANY DIRECT,
            INDIRECT, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR OTHER DAMAGES FOR ANY
            USE OF THIS WEBSITE, THE WEB SERVICES, OR ANY LINKED WEBSITE,
            INCLUDING WITHOUT LIMITATION DAMAGES FOR LOST PROFITS, BUSINESS
            INTERRUPTION, LOSS OF PROGRAMS OR DATA ON ENTERGY’S OR ANY USER’S
            COMPUTER HARDWARE OR INFORMATION HANDLING SYSTEMS, OR OTHERWISE,
            REGARDLESS OF WHETHER ENTERGY HAS BEEN ADVISED OR IS AWARE OF THE
            POSSIBILITY OF SUCH DAMAGES. ENTERGY IS NOT RESPONSIBLE FOR
            TECHNICAL, HARDWARE, OR SOFTWARE FAILURES OR LOSSES OF ANY KIND, OR
            FOR FAILED, INCOMPLETE, DISTORTED, OR DELAYED DATA TRANSMISSIONS, OR
            EQUIPMENT INCOMPATIBILITIES, IN RELATION TO ANY USE OF THIS WEBSITE
            OR THE WEB SERVICES. ENTERGY IS NOT RESPONSIBLE FOR DAMAGE RESULTING
            FROM DESTRUCTIVE COMPUTER CODE SUCH AS VIRUSES, WORMS, TROJAN
            HORSES, AND THE LIKE. UNDER NO CIRCUMSTANCES WILL ENTERGY BE LIABLE
            FOR DAMAGES OF ANY KIND RESULTING FROM THE USE OF INFORMATION,
            INTERACTIVE FORMS, CONTENT, OR COMPUTER CODE ON THIS WEBSITE OR ANY
            WEBSITE ACCESSIBLE THROUGH THIS WEBSITE, OR RELATED TO THE WEB
            SERVICES.
          </p>

          <h3>11. WAIVERS</h3>

          <p>
            Entergy will not be deemed to have waived any of the terms of this
            Agreement or any of its other terms, conditions, or policies with
            respect to this Website or the Web Services, or any of its rights
            with respect to the foregoing, unless the waiver is in a written
            document expressly setting forth the nature and extent of the
            waiver, executed by a representative of Entergy duly authorized to
            execute and deliver the same. No delay or omission on the part of
            Entergy in enforcing this Agreement or any such other terms,
            conditions, or policies shall impair its current or future exercise
            of its rights and remedies thereunder or be construed as a waiver of
            any provision of this Agreement or any such other terms, conditions,
            or policies. No partial or single exercise of any right or remedy
            available to Entergy under this Agreement or any such other terms,
            conditions, or policies shall preclude the full availability to
            Entergy of such right or remedy.
          </p>

          <h3>12. ASSIGNMENT</h3>

          <p>
            No user may assign to any other person or entity its rights or
            obligations under this Agreement or under any other terms,
            conditions, or policies with respect to this Website or the Web
            Services without the express written consent of Entergy. Entergy, in
            its sole discretion, may freely assign this Agreement or its rights
            and obligations hereunder or under any other terms, conditions, or
            policies with respect to this Website or the Web Services, in whole
            or in part, to any third party or parties without the consent of or
            notice to any user.
          </p>

          <h3>13. APPLICABLE LAWS</h3>

          <p>
            This Website and the Agreement are governed by the laws of the State
            of Arkansas, excluding its conflicts of laws principles, and, where
            and to the extent applicable, by federal law. Terms, conditions, and
            policies applicable to the provision of particular Web Services by
            specific Entergy utility subsidiaries are governed by the laws of
            the state in which the relevant Entergy public utility affiliate
            provides utility services, regardless of where a particular user is
            located or resides at the time the user obtains utility services,
            accesses the Website, or makes use of the Web Services. Entergy does
            not represent that the materials on its Website or in the Web
            Services may be used outside of the United States. Access to the
            Website and use of the Web Services may not be legal by certain
            persons or in certain countries. Users who access this Website or
            use the Web Services from outside the United States do so at their
            own risk, and are responsible for compliance with the laws of the
            jurisdiction from which the Website is accessed or the Web Services
            are used.
          </p>

          <h3>14. CAPTIONS</h3>

          <p>
            Captions and titles in this Agreement and in any other terms,
            conditions, or policies with respect to this Website or the Web
            Services are for convenience only and are not to be construed to
            affect the meaning or affect this Agreement or any such other terms,
            conditions, or policies.
          </p>

          <h3>15. SEVERABILITY</h3>

          <p>
            If any provision of this Agreement or of any other terms,
            conditions, or policies with respect to this Website or the Web
            Services is determined to be void or invalid as a matter of law, the
            remaining provisions of this Agreement and of any such other terms,
            conditions, and policies shall not be affected thereby and shall
            remain in full force and effect.
          </p>

          <p>Last updated: December 1, 2018</p>
        </div>
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

export default connect(undefined, mapDispatchToProps)(TermsAndPolicy);
