import {
  ADD_ADDITIONAL_TAX_ID,
  UPDATE_ACCOUNT_INFORMATION_SUCCESS,
  UPDATE_ACTIVE_STEP,
  UPDATE_FILE1_STATE,
  UPDATE_FILE2_STATE
} from "./../actions/types";

const initialState = {
  activeStepIndex: 0,
  isFile1Uploaded: false,
  isFile2Uploaded: false,
  file1: undefined,
  file2: undefined,
  taxIdNumbers: [],
  customer: {
    firstName: "",
    lastName: "",
    companyName: "",
    address: "",
    zipcode: "",
    emailAddress: "",
    taxIdNumber: "",
    phoneNumber: ""
  }
};

const sepoBReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ACTIVE_STEP: {
      return {
        ...state,
        activeStepIndex: action.payload
      };
    }
    case UPDATE_FILE1_STATE: {
      return {
        ...state,
        isFile1Uploaded: action.payload
      };
    }
    case UPDATE_FILE2_STATE: {
      return {
        ...state,
        isFile2Uploaded: action.payload
      };
    }
    case ADD_ADDITIONAL_TAX_ID: {
      return {
        ...state,
        taxIdNumbers: [].concat.apply([], action.payload)
      };
    }
    case UPDATE_ACCOUNT_INFORMATION_SUCCESS: {
      return {
        ...state,
        customer: action.payload
      };
    }

    default: {
      return state;
    }
  }
};

export default sepoBReducer;
