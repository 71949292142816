import * as React from "react";
import * as segmentActions from "./../../actions/segmentActions";

import { Content } from "../../components/Content/Content";
import JoinWaitingListForm from "./components/JoinWaitingListForm";
import SuccessMessage from "../../components/SuccessMessage/SuccessMessage";
import { connect } from "react-redux";

class JoinWaitingList extends React.PureComponent<*> {
  constructor(props) {
    super(props);
    this.state = {
      isJoinWaitlistComplete: false
    };
  }

  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed("join-waitlist");
    }
  }

  handleSuccess = () => {
    const {
      selectedOperatingCompany,
      trackJoinWaitlist,
      customer,
      startPath
    } = this.props;

    this.setState({ isJoinWaitlistComplete: true });
    const operatingCompanyName = selectedOperatingCompany.name;
    const operatingCompanyStatus =
      selectedOperatingCompany.isActive === false ? "Inactive" : "Sold Out";
    const customerType =
      customer.isCommercial === false ? "Residential" : "Commercial";

    trackJoinWaitlist(
      operatingCompanyStatus,
      operatingCompanyName,
      customerType,
      customer.zipcode,
      startPath
    );

    //scroll to top
    window.scrollTo(0, 0);
  };

  render() {
    const { isJoinWaitlistComplete } = this.state;
    const { selectedOperatingCompany } = this.props;

    if (!selectedOperatingCompany) {
      return <div />;
    }

    return (
      <Content>
        {isJoinWaitlistComplete ? (
          <SuccessMessage>
            <h1>Thank you for your interest in Solarity!</h1>
            <p className="sampled-paragraph">
              We will let you know as soon as we are available in your area.
            </p>
          </SuccessMessage>
        ) : (
          <JoinWaitingListForm
            selectedOperatingCompany={selectedOperatingCompany}
            onSuccess={() => this.handleSuccess()}
          />
        )}
      </Content>
    );
  }
}

const mapStateToProps = state => ({
  customer: state.registrationReducer.customer,
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  startPath: state.segmentReducer.startPath
});

const mapDispatchToProps = dispatch => ({
  trackJoinWaitlist: (
    operatingCompanyStatus,
    operatingCompanyName,
    customerType,
    zipcode,
    startPath
  ) =>
    dispatch(
      segmentActions.trackJoinWaitlist(
        operatingCompanyStatus,
        operatingCompanyName,
        customerType,
        zipcode,
        startPath
      )
    ),
  trackPageViewed: pageName =>
    dispatch(segmentActions.trackPageViewed(pageName))
});

export default connect(mapStateToProps, mapDispatchToProps)(JoinWaitingList);
