import {
  GET_TOPICS,
  GET_TOPICS_FAILURE,
  GET_TOPICS_SUCCESS
} from "./../actions/types";
import { call, put, takeEvery, select } from "redux-saga/effects";

import httpService from "./../services/http.service";

function getTopics(payload) {
  return new httpService().sendGet("contact-us/topics", payload);
}

function* getTopicsAsync() {
  try {
    const state = yield select();
    const selectedOperatingCompany =
      state.operatingCompaniesReducer.selectedOperatingCompany;

    const response = yield call(getTopics);

    if (
      selectedOperatingCompany &&
      selectedOperatingCompany.id === "b842d7c8-b59c-4ef4-9765-a6187d575ed0"
    ) {
      let topics = response.data;

      const index = topics.findIndex(
        topic =>
          topic.display === "I would like to learn more about plan options"
      );

      topics.splice(index, 1);

      yield put({
        type: GET_TOPICS_SUCCESS,
        payload: topics
      });
    } else {
      yield put({
        type: GET_TOPICS_SUCCESS,
        payload: response.data
      });
    }
  } catch (error) {
    yield put({
      type: GET_TOPICS_FAILURE,
      payload: error
    });
  }
}

export function* watchGetTopicsAsync() {
  yield takeEvery(GET_TOPICS, getTopicsAsync);
}
