import * as React from "react";

import { Field, formValueSelector, reduxForm } from "redux-form";
import {
  JOIN_WAITING_LIST,
  JOIN_WAITING_LIST_FAILURE,
  JOIN_WAITING_LIST_SUCCESS
} from "./../../../actions/types";
import {
  emailHasError,
  maxLength,
  minLength,
  nameHasError,
  phoneNumberHasError,
  required,
  zipcodeHasError
} from "../../../utils/formValidation";
import {
  renderRadioButton,
  renderTextField
} from "./../../../services/form.service";

import MakeAsyncFunction from "react-redux-promise-listener";
import { connect } from "react-redux";
import { promiseListener } from "./../../../store";
import operatingCompanyIds from "../../../constants/operatingCompanyIds";

const initialValues = {
  customerType: "residential"
};

const maxLength25 = maxLength(25);
const minLength1 = minLength(1);
const minLength7 = minLength(7);
const maxLength100 = maxLength(100);

const renderAgreementCheckbox = field => (
  <div className="gs-form-checkbox-wrap">
    {field.meta.touched && field.meta.error && (
      <span className="gs-form-error">{field.meta.error}</span>
    )}
    <input
      id="agree"
      className="gs-form-checkbox"
      {...field.input}
      placeholder={field.label}
      type={field.type}
    />
    <label htmlFor="agree">
      I agree to be contacted by Solarity in the future.
    </label>
  </div>
);

class JoinWaitingListForm extends React.PureComponent<*> {
  static getDerivedStateFromProps(props) {
    if (props.customerType !== "commercial") {
      props.resetSection("companyName", null);
    }
  }

  render() {
    const {
      handleSubmit,
      invalid,
      // eslint-disable-next-line no-unused-vars
      submitError,
      submitSucceeded,
      pristine,
      submitting,
      onSuccess,
      customerType,
      selectedOperatingCompany
    } = this.props;

    const isLouisiana = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Louisiana
    );

    return (
      <MakeAsyncFunction
        listener={promiseListener}
        start={JOIN_WAITING_LIST}
        resolve={JOIN_WAITING_LIST_SUCCESS}
        reject={JOIN_WAITING_LIST_FAILURE}
      >
        {onSubmit => (
          <div className="form-layout">
            <form
              className="form-content gs-form centered"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h1>Join our waitlist!</h1>
              {isLouisiana ? (
                <div>
                  <p className="text-5">
                    At Entergy Louisiana, we’re investing in sustainable energy
                    solutions that will power the future of Louisiana. In order
                    to pave the the way for solar power, we need your help. your
                    your support for community solar in Louisiana by joining our
                    waitlist.
                  </p>
                  <p className="text-6">
                    Our waitlist does not include Entergy New Orleans customers
                    at this time.
                  </p>
                </div>
              ) : (
                <p className="text-6">
                  Be the first to find out when Solarity is available.
                </p>
              )}
              <div className="plan-type-chooser">
                <p className="text-6">
                  What type of plan are you interested in?
                </p>
                <div className="lined-radios">
                  <Field
                    name="customerType"
                    type="radio"
                    label="Residential"
                    value="residential"
                    component={renderRadioButton}
                  />
                  <Field
                    name="customerType"
                    type="radio"
                    label="Business"
                    value="commercial"
                    component={renderRadioButton}
                  />
                </div>
              </div>

              <p className="text-6">Customer Information</p>

              <div className="gs-form-double-row">
                <Field
                  name="firstName"
                  type="text"
                  component={renderTextField}
                  validate={[nameHasError, maxLength25, minLength1]}
                  maxLength="25"
                  label="First Name"
                  placeholder="First Name"
                />

                <Field
                  name="lastName"
                  label="Last Name"
                  maxLength="25"
                  validate={[nameHasError, maxLength25, minLength1]}
                  component={renderTextField}
                  placeholder="Last Name"
                />
              </div>

              {customerType === "commercial" && (
                <div>
                  <Field
                    name="companyName"
                    type="text"
                    component={renderTextField}
                    validate={[required, maxLength100, minLength1]}
                    maxLength="100"
                    label="Business Name"
                    placeholder="Business Name"
                  />
                </div>
              )}

              <Field
                name="emailAddress"
                type="text"
                validate={[required, emailHasError, minLength7, maxLength100]}
                component={renderTextField}
                maxLength="100"
                label="Email Address"
                placeholder="example@example.com"
              />
              <div className="gs-form-double-row address-row">
                <Field
                  name="phoneNumber"
                  type="text"
                  validate={[required, phoneNumberHasError]}
                  component={renderTextField}
                  label="Phone Number"
                  maxLength="10"
                  placeholder="10 digits"
                />

                <Field
                  name="zipcode"
                  type="text"
                  validate={[required, zipcodeHasError]}
                  component={renderTextField}
                  label="Zip Code"
                  placeholder="5 digits"
                  maxLength="5"
                />
              </div>

              <hr />

              <Field
                name="agree"
                validate={required}
                type="checkbox"
                component={renderAgreementCheckbox}
              />

              <button
                className="gs-form-btn block"
                type="submit"
                disabled={invalid || submitting || pristine}
              >
                Join Waitlist
              </button>
              {submitSucceeded && onSuccess()}
            </form>
          </div>
        )}
      </MakeAsyncFunction>
    );
  }
}

// eslint-disable-next-line no-class-assign
JoinWaitingListForm = reduxForm({
  form: "JoinWaitingList",
  enableReinitialize: true,
  initialValues
})(JoinWaitingListForm);

const selector = formValueSelector("JoinWaitingList");
// eslint-disable-next-line no-class-assign
JoinWaitingListForm = connect(state => {
  const customerType = selector(state, "customerType");

  return {
    customerType
  };
})(JoinWaitingListForm);

export default JoinWaitingListForm;
