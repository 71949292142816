import "./styles.scss";
import * as React from "react";
import { trackPageViewed } from "./../../actions/segmentActions";
import { Content } from "../../components/Content/Content";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import operatingCompanyIds from "../../constants/operatingCompanyIds";
import { NavLink } from "react-router-dom";
import * as segmentActions from "../../actions/segmentActions";

const pageName = "about-us";

function getPlansCtaText(operatingCompany) {
  if (operatingCompany.isEnrollmentEnabled) {
    return "Sign up today";
  } else if (!operatingCompany.isWaitlisted && operatingCompany.hasCapacity) {
    return "Reserve now";
  } else {
    return "Join the waitlist";
  }
}

class AboutUs extends React.PureComponent<*> {
  componentDidMount() {
    const { trackPageViewed } = this.props;

    if (trackPageViewed) {
      trackPageViewed(pageName);
    }
  }

  renderPlansButton(classNames, text) {
    const {
      selectedOperatingCompany,
      updateStartPath,
      trackJoinNow,
      trackWaitlist
    } = this.props;

    if (
      !selectedOperatingCompany ||
      selectedOperatingCompany.id === operatingCompanyIds.Other
    ) {
      return null;
    }

    const trackHandler =
      !selectedOperatingCompany.isWaitlisted &&
      selectedOperatingCompany.hasCapacity
        ? trackJoinNow
        : trackWaitlist;

    const operatingCompanyStatus =
      selectedOperatingCompany.isActive && selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : !selectedOperatingCompany.isActive
        ? "Inactive"
        : selectedOperatingCompany.isActive &&
          !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    const url =
      selectedOperatingCompany.isWaitlisted ||
      !selectedOperatingCompany.hasCapacity
        ? "/join-waitlist"
        : "/plans";

    const clickHandler = () => {
      trackHandler(
        selectedOperatingCompany.name,
        operatingCompanyStatus,
        pageName
      );
      updateStartPath(pageName);
    };

    const linkText = text || getPlansCtaText(selectedOperatingCompany);

    return (
      <NavLink className={classNames} to={url} onClick={clickHandler}>
        {linkText}
      </NavLink>
    );
  }

  render() {
    const { selectedOperatingCompany } = this.props;
    const isOther =
      !selectedOperatingCompany ||
      selectedOperatingCompany.id === operatingCompanyIds.Other;

    return (
      <Content wrapperClassName="tw-bs">
        <Helmet>
          <title>About Community Solar | Solarity</title>
        </Helmet>
        <div id="community-solar-what-card" className="container">
          <div className="row">
            <div className="col-xs col-lg-7">
              <h1 className="heading-1">What is community solar?</h1>
              <p className="text-6">
                Community solar uses renewable energy produced from solar
                panels, usually in the form of a solar farm or solar garden.
                Community solar can be organized in a variety of ways, depending
                on the specific business and regulatory conditions. In some
                community solar formats, members of a community may elect to
                contribute to a share of the cost of the solar farm/garden. In
                return, the community receives the benefits of having a clean
                energy source nearby.
              </p>
              <p className="text-6">
                In most cases, participation in community solar can require a
                subscription cost to the participating members. Once subscribed,
                participants can receive a credit on their electricity bills for
                their share of the power produced by the solar farm/garden. One
                of the biggest advantages of community solar is that it allows
                any member of the community to invest in solar power, even if
                they cannot or prefer not to install solar panels on their
                property.
              </p>
              <p className="text-6">
                Our approach to community solar is building locally sourced,
                utility-owned solar within Entergy’s service area which allows
                our customers to contribute to a clean, renewable energy source.
                Community solar enables us to shape the future of renewable
                energy.
              </p>
              {!isOther && (
                <p className="text-6">
                  Interested in joining?&nbsp;
                  {this.renderPlansButton()}
                </p>
              )}
            </div>
            <div id="communitySolarImageColumn">
              <img
                src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/CommunitySolar-Hero.svg"
                alt="Solar Farm Illustration"
              />
            </div>
          </div>
        </div>
        <div className="card light">
          <div id="community-solar-how-card" className="container">
            <h1 className="heading-1 hero">How does community solar work?</h1>
            <div className="step-blocks-container">
              <div className="step-block">
                <div className="step-img-wrapper">
                  <img
                    alt="Community Solar Step 1 Graphic"
                    src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/CommunitySolar-How-Step1.svg"
                  />
                </div>
                <p className="solar-step-number">STEP 1</p>
                <p className="text-6">
                  The farms will be busy turning sunlight into clean, renewable
                  electricity.
                </p>
              </div>
              <div className="step-block">
                <div className="step-img-wrapper">
                  <img
                    alt="Community Solar Step 2 Graphic"
                    src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/CommunitySolar-How-Step2.svg"
                  />
                </div>
                <p className="solar-step-number">STEP 2</p>
                <p className="text-6">
                  The energy produced is contributed back to the grid.
                </p>
              </div>
              <div className="step-block">
                <div className="step-img-wrapper">
                  <img
                    alt="Community Solar Step 3 Graphic"
                    src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/CommunitySolar-How-Step3.svg"
                  />
                </div>
                <p className="solar-step-number">STEP 3</p>
                <p className="text-6">
                  Electricity is sent from the grid back to your home.
                </p>
              </div>
              <div className="step-block">
                <div className="step-img-wrapper">
                  <img
                    alt="Community Solar Step 4 Graphic"
                    src="https://d1oeu1h51wn2cw.cloudfront.net/desktop/CommunitySolar-How-Step4.svg"
                  />
                </div>
                <p className="solar-step-number">STEP 4</p>
                <p className="text-6">
                  Every month, you'll see how much electricity your contribution
                  has generated through solar power.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div id="community-solar-why-card" className="container">
          <div className="row">
            <div className="col-xs col-xl-10 offset-xl-1">
              <h1 className="heading-1 hero">
                Why community solar might be better fit for you than solar
                panels
              </h1>
              <p className="text-6">
                Community and rooftop solar are two common approaches to going
                solar. While parameters vary between programs, community solar
                can be a good alternative to rooftop solar, even for homeowners
                with suitable roofs for installing solar panels.
              </p>
              <p className="text-6">
                A few positive things to keep in mind when considering community
                solar:
              </p>
              <ul className="disc text-6">
                <li>
                  Community solar doesn't have the upfront costs of rooftop
                  solar (e.g., installation fees)
                </li>
                <li>
                  Community solar doesn't require on-going maintenance fees
                </li>
                <li>
                  Participation in community solar is immediate, the panels are
                  already installed and generating energy.
                </li>
                <li>
                  Whether you rent or own your home, you can contribute to a
                  renewable energy source without service interruption
                </li>
                <li>
                  If you move within the Entergy service area, your community
                  solar access stays with you
                </li>
                <li>You can cancel anytime</li>
              </ul>
              <div>{this.renderPlansButton("btn outlined", "Start Today")}</div>
              <h1 className="heading-1 hero">
                Environment benefits of community solar
              </h1>
              <p className="text-6">
                Solar energy is referred to as “clean energy” because it does
                not emit carbon dioxide into the environment. This means
                community solar helps reduce pollution, greenhouse gas
                emissions, and the use of fossil fuels. In addition to solar
                being a clean source of energy, it is also a renewable resource,
                with the sun being a continuous, reliable source of energy. Even
                on the cloudiest days, energy can still be produced from solar
                power.
              </p>
              <h1 className="heading-1 hero">Community solar participation</h1>
              <p className="text-6">
                Solarity provides a subscription model for participation. This
                means Entergy facilitates the solar program for Solarity
                subscribers. Any investments associated with the solar farms are
                facilitated by Entergy.
              </p>
              <p className="text-6">
                To participate, Solarity members are required to live within
                Entergy service areas with available capacity. If capacity is
                full, Entergy customers can sign up to the waitlist to be
                contacted when space is available.
              </p>
              <div>{this.renderPlansButton("btn outlined", "Sign Me Up")}</div>
            </div>
          </div>
        </div>
      </Content>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  trackPageViewed: pageName => dispatch(trackPageViewed(pageName)),
  trackJoinNow: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackJoinNow(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  trackWaitlist: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackWaitlist(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  updateStartPath: startPath =>
    dispatch(segmentActions.updateStartPath(startPath))
});

const mapStateToProps = state => ({
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany
});

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
