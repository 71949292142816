import "./styles.scss";

import * as React from "react";
import * as plansActions from "./../../../../actions/plansActions";
import * as segmentActions from "./../../../../actions/segmentActions";

import { NavHashLink as Link } from "react-router-hash-link";
import { connect } from "react-redux";
import impactSaveBags from "./../../../../img/svg/impact-save-bags.svg";
import impactSaveGas from "./../../../../img/svg/impact-save-gas.svg";
import impactSaveTrees from "./../../../../img/svg/impact-save-trees.svg";
import operatingCompanyIds from "../../../../constants/operatingCompanyIds";
import segmentReducer from "../../../../reducers/segmentReducer";

class HowMakeImpact extends React.PureComponent<*> {
  handleTrackJoinNow = (operatingCompanyName, operatingCompanyStatus) => {
    const { trackJoinNow, updateStartPath } = this.props;

    trackJoinNow(operatingCompanyName, operatingCompanyStatus, "Plans");

    updateStartPath("Plans");
  };

  handleTrackWaitlist = (operatingCompanyName, operatingCompanyStatus) => {
    const { trackWaitlist, updateStartPath } = this.props;

    trackWaitlist(operatingCompanyName, operatingCompanyStatus, "Plans");

    updateStartPath("Plans");
  };

  renderPlansButton = () => {
    const { selectedOperatingCompany } = this.props;

    const operatingCompanyName = selectedOperatingCompany
      ? selectedOperatingCompany.name
      : "";
    const operatingCompanyStatus =
      selectedOperatingCompany &&
      selectedOperatingCompany.isActive &&
      selectedOperatingCompany.hasCapacity
        ? "Capacity Available"
        : selectedOperatingCompany && !selectedOperatingCompany.isActive
        ? "Inactive"
        : selectedOperatingCompany &&
          selectedOperatingCompany.isActive &&
          !selectedOperatingCompany.hasCapacity
        ? "Sold Out"
        : "";

    if (
      selectedOperatingCompany !== undefined &&
      selectedOperatingCompany.name !== "Other" &&
      selectedOperatingCompany.hasCapacity
    ) {
      return (
        <Link
          className="btn centered"
          to={`${process.env.PUBLIC_URL}/plans`}
          onClick={() =>
            this.handleTrackJoinNow(
              operatingCompanyName,
              operatingCompanyStatus
            )
          }
        >
          See Plans
        </Link>
      );
    } else if (
      selectedOperatingCompany !== undefined &&
      selectedOperatingCompany.name !== "Other" &&
      !selectedOperatingCompany.hasCapacity
    ) {
      return (
        <div className="centered-container">
          <Link
            className="btn gs-waitlist-btn block"
            to={`${process.env.PUBLIC_URL}/join-waitlist`}
            onClick={() =>
              this.handleTrackWaitlist(
                operatingCompanyName,
                operatingCompanyStatus,
                "Plans"
              )
            }
          >
            Join Waitlist
          </Link>
        </div>
      );
    }
  };

  componentDidMount() {
    const { plans, getPlans, selectedOperatingCompany } = this.props;

    if (selectedOperatingCompany !== undefined) {
      if (plans === undefined || plans.length === 0) {
        getPlans();
      }
    }
  }

  handlePlanSelected = plan => {
    const { updateSelectedPlan } = this.props;

    updateSelectedPlan(plan);
  };

  handlePlanTypeSelected = planType => {
    const { updateSelectedPlanType } = this.props;

    updateSelectedPlanType(planType);
  };

  render() {
    const {
      selectedPlanType,
      selectedPlan,
      selectedOperatingCompany
    } = this.props;
    const isTexas = !!(
      selectedOperatingCompany &&
      selectedOperatingCompany.id === operatingCompanyIds.Texas
    );
    const plans = this.props.plans
      ? this.props.plans.filter(plan => plan.type === selectedPlanType)
      : [];
    const gallonsOfGasUsed = selectedPlan ? selectedPlan.gallonsOfGasUsed : "";
    const equivalentTreesPlanted = selectedPlan
      ? selectedPlan.equivalentTreesPlanted
      : "";
    const bagsOfTrashRecycled = selectedPlan
      ? selectedPlan.bagsOfTrashRecycled
      : "";

    return (
      <div>
        <div id="MakeImpact" />
        <div className="type-switcher">
          <div className="centered-content">
            <h2 className="content-heading">Your impact in one year</h2>
            <div className="type-switcher-inputs">
              <div className="type-switcher-inputs__item custom-radio">
                <input
                  type="radio"
                  id="investment-type-residential"
                  name="investment-type"
                  value="residential"
                  checked={selectedPlanType === "residential"}
                  onChange={() => this.handlePlanTypeSelected("residential")}
                />
                <label htmlFor="investment-type-residential">Residential</label>
              </div>

              <div className="type-switcher-inputs__item custom-radio">
                <input
                  type="radio"
                  id="investment-type-commercial"
                  name="investment-type"
                  value="commercial"
                  checked={selectedPlanType === "commercial"}
                  disabled={isTexas}
                  onChange={() => this.handlePlanTypeSelected("commercial")}
                />
                <label htmlFor="investment-type-commercial">Business</label>
              </div>
            </div>
          </div>
        </div>

        <div className="investment-switcher">
          <div className="centered-content">
            {plans.map((plan, index) => {
              return (
                <div
                  key={plan.id}
                  className="custom-radio"
                  onClick={() => this.handlePlanSelected(plan)}
                >
                  <input
                    type="radio"
                    name="investment"
                    value={plan.id}
                    checked={
                      selectedPlan !== undefined && selectedPlan.id === plan.id
                    }
                  />
                  <label htmlFor="investment-seed">{plan.name}</label>
                </div>
              );
            })}
          </div>
        </div>

        <div className="centered-content investment">
          <div className="investment-details">
            <div className="investment-details__descr">
              Your subscription plan would have the same impact as one of the
              following:
            </div>

            <div className="investment-details-savings">
              <div className="investment-details-savings__item">
                <div className="img">
                  <img src={impactSaveGas} alt="" />
                </div>
                <strong className="ammount">{gallonsOfGasUsed}</strong>
                Gallons of gas unused
              </div>

              <div className="investment-details-savings__item">
                <div className="img">
                  <img src={impactSaveTrees} alt="" />
                </div>
                <strong className="ammount">{equivalentTreesPlanted}</strong>
                Trees planted
              </div>

              <div className="investment-details-savings__item">
                <div className="img">
                  <img src={impactSaveBags} alt="" />
                </div>
                <strong className="ammount">{bagsOfTrashRecycled}</strong>
                Bags of trash recycled
              </div>
            </div>
          </div>

          {this.renderPlansButton()}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getPlans: () => dispatch(plansActions.getPlans()),
  updateSelectedPlanType: planType =>
    dispatch(plansActions.updateSelectedPlanType(planType)),
  updateSelectedPlan: plan => dispatch(plansActions.updateSelectedPlan(plan)),
  trackJoinNow: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackJoinNow(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  trackWaitlist: (operatingCompanyName, operatingCompanyStatus, startPath) =>
    dispatch(
      segmentActions.trackWaitlist(
        operatingCompanyName,
        operatingCompanyStatus,
        startPath
      )
    ),
  updateStartPath: startPath =>
    dispatch(segmentActions.updateStartPath(startPath))
});

const mapStateToProps = state => ({
  plans: state.plansReducer.plans,
  selectedOperatingCompany:
    state.operatingCompaniesReducer.selectedOperatingCompany,
  selectedPlanType: state.plansReducer.selectedPlanType,
  selectedPlan: state.plansReducer.selectedPlan,
  startPath: segmentReducer.startPath
});

export default connect(mapStateToProps, mapDispatchToProps)(HowMakeImpact);
