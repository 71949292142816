import { applyMiddleware, compose, createStore } from "redux";
import {
  getInitialState,
  createLocalStorageListener
} from "./listeners/localStorage";
import createReduxPromiseListener from "redux-promise-listener";
import createSagaMiddleware from "redux-saga";
import { createTracker } from "redux-segment";
import rootReducer from "./reducers";
import rootSaga from "./sagas";

const reduxPromiseListener = createReduxPromiseListener();
const monitor = window["__SAGA_MONITOR_EXTENSION__"];
const sagaMiddleware = createSagaMiddleware({ sagaMonitor: monitor });
const tracker = createTracker();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  getInitialState(),
  composeEnhancers(
    applyMiddleware(sagaMiddleware, reduxPromiseListener.middleware, tracker)
  )
);

store.subscribe(createLocalStorageListener(store));

sagaMiddleware.run(rootSaga);

export const promiseListener = reduxPromiseListener;

export default store;
