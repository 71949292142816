import { GET_FAQ_SUCCESS } from "./../actions/types";

const initialState = {
  faq: undefined
};

const faqReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAQ_SUCCESS: {
      return {
        ...state,
        faq: [].concat.apply([], action.payload)
      };
    }
    default: {
      return state;
    }
  }
};

export default faqReducer;
